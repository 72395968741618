import { checkProvider as checkProviderXtz, connectBeacon, disconnectBeacon } from "./tezos"
import { checkProvider as checkProviderEth, connectMetamask, disconnectMetamask } from "./ethereum"
import { tezosStore } from "../../state/crypto/tezosStore"
import { ethereumStore } from "../../state/crypto/ethereumStore"

export const waitSignerTezos = async () => {
    const tezosState = tezosStore.getState()
    
    checkProviderXtz()

    if (!tezosState?.signer) {
        await connectBeacon()
    }
}

export const waitSignerEthereum = async (window: any) => {
    const ethereumState = ethereumStore.getState()

    checkProviderEth(window)

    if (!ethereumState?.signer) {
        await connectMetamask(window)
    }
}

export const disconnectAll = async () => {
    await disconnectBeacon()
    disconnectMetamask()
}