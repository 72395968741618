import { GRAPHCMS_NETWORK as NETWORK } from "../../constants"

const query: any = {}
const mutation: any = {}

mutation.createCollection = `
    mutation createCollection($name: String!, $artistAddress: String!, $contractAddress: String!) {
        createCollection(
            data: {name: $name, artistAddress: $artistAddress, contractAddress: $contractAddress}
        ) {
            id
        }
    }  
` 

mutation.publishCollection = `
    mutation publishCollection($id: ID!) {
        publishCollection(where: {id: $id}) {
            id
        }
    }  
`


export const collections = {
    query,
    mutation,
}
