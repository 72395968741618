import { ethers, providers } from "ethers";
import { ethereumStore, setProvider, setSigner } from "../../state/crypto/ethereumStore";
import { ETHEREUM_NETWORK, PROVIDER_NETWORK } from "../../constants";

//function to detect if user is on mobile device
export const isMobileDevice = () => {
    return 'ontouchstart' in window || 'onmsgesturechange' in window;
}

export const mobileDeepLink = (existingLink: string) => {
    const dappUrl = `gallery.verticalcrypto.art${existingLink}`;
    window.location.href = "dapp://" + dappUrl;
}

export const checkProvider = async (window: any) => {
    const ethereumState = ethereumStore.getState()

    if (!ethereumState.provider || !ethereumState.signer) {
        await connectMetamask(window)
    }
}

export const initProvider = async (window: any) => {
    const provider = new ethers.providers.InfuraProvider(PROVIDER_NETWORK, process.env.REACT_APP_INFURA_API_KEY);
    const signer = provider.getSigner()

    return { provider, signer }
}

const storeAccount = async (provider: any) => {
    const ethereumState = ethereumStore.getState()

    const prov = provider
    const signer = provider.getSigner()
    const address = await signer.getAddress()
    const balance = parseFloat(ethers.utils.formatEther(await signer.getBalance()))

    setProvider(prov)
    setSigner(signer)

    ethereumState.setAddress(address)
    ethereumState.setBalance(balance)
    
    ethereumState.setConnected(true)
}

export const connectMetamask = async (window: any) => {
    if (window.ethereum?.isMetaMask) {
        const provider = new ethers.providers.Web3Provider(window.ethereum)


        try {
            await provider.send("eth_requestAccounts", []);

        } catch (error) {
            console.error(error)
            return
        }

        await storeAccount(provider)
    } else if (isMobileDevice()){
        mobileDeepLink(window.location.pathname)
    } else {}
}

export const connectMetamaskBackground = async (window: any) => {
    const ethereumState = ethereumStore.getState()
    
    // if (ethereumState.connected) {
        if (!ethereumState.provider) {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            return await storeAccount(provider)
        }

        return await storeAccount(ethereumState.provider)
    // }
}

export const disconnectMetamask = () => {
    const ethereumState = ethereumStore.getState()

    ethereumState.clear()
    // ethereumState.setConnected(false)
}

export const changeMetamaskAccount = async (window: any) => {
    disconnectMetamask()
    await connectMetamask(window)

    const ethereumState = ethereumStore.getState()
}