import { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router'
import { Button, Container, Label, Loader, NumberInput } from '../../components/design-system'
import { waitSignerEthereum } from '../../libs/crypto/crypto'
import { ethereumStore, getSigner } from '../../state/crypto/ethereumStore'
import { notificationStore } from '../../state/global/notificationStore'
import { getBundleDetails, getVCAMetadata } from '../../utils/crypto'
import { TokenExternalLinks } from './saleinfo'
import { getDateTime } from '../../utils/time'
import { Link } from 'react-router-dom'
import { Media, MediaTypeEnum } from '../../components/token/media'
import { cleanHash } from '../../utils/crypto'


import styles from './styles.module.scss'
import { ethers } from 'ethers'
import { isMobileDevice, mobileDeepLink } from '../../libs/crypto/ethereum'

import { findEthArtistName } from '../../components/token/token'
import { FALSE } from 'sass'

const VCACustomFixedPrice = require('../../constants/vcacustomfp-abi.json')
const signatures = require('../../constants/signatures.json')

export interface IBundle {
    bundleId: number;
    name: string;
    description: string;
    royalties: number;
    artist: string;
    balance: number;
    price: number;
    alPrice?: number;
    dropIds: number[];
    saleStart: string;
    contentType: any;
    contentURI: string;
}

export function getSignature(key: string) {
    if (signatures[key] !== null) {
        return signatures[key]
    } else {
        return null
    }
}

export const BundlePage = ({
    allowlist
}: {
    allowlist:boolean
}) => {
    const notifications = notificationStore()
    const ethereumState = ethereumStore()

    const navigate = useNavigate()
    const [bundleInfo, setBundleInfo] = useState<IBundle | undefined>(undefined)

    const [broken, setBroken] = useState(false)
    const [loading, setLoading] = useState(true)

    let timeout: any = useRef()

    const network: "ethereum" | "tezos" = allowlist ? (window.location.pathname.split("/")[3] || "ethereum" as any):
                                        (window.location.pathname.split("/")[2] || "ethereum" as any)
    const contract = allowlist ? window.location.pathname.split("/")[4] : window.location.pathname.split("/")[3]
    const bundleId = allowlist? parseInt(window.location.pathname.split("/")[5]) : parseInt(window.location.pathname.split("/")[4])

    useEffect(() => {
        if (!broken) return

        const timeout = setTimeout(() => {
            navigate("/", { replace: true })
        }, 1500)

        return () => clearTimeout(timeout)
    }, [broken])

    // Grabs token info from DB on mount
    useEffect(() => {
        const getBundleFromDb = async () => {
            try {
                const bundleDetails:any = await getBundleDetails(contract, network, bundleId)

                if (bundleDetails) {
                    setBundleInfo(bundleDetails)
                    setLoading(false)
                }
                
                else {
                    setBroken(true)
                }
        
            } catch (error) {
                setBroken(true)
            }
        }

        getBundleFromDb()

    }, [])

    
    const handleSubmitMint = async (contract: string, bundleId: number, price: number) => {
        const notifId = notifications.addNotification({
            message: "Minting...",
            status: "pending",
        })

        if (window.ethereum?.isMetaMask) {

            const provider = new ethers.providers.InfuraProvider("homestead", process.env.REACT_APP_INFURA_API_KEY);

            try {
                const signer = getSigner()

                if (!signer) {
                    await waitSignerEthereum(window)
                }

                const VCAFixedPriceContract = new ethers.Contract(contract, VCACustomFixedPrice.abi, signer)
                
                let overrides = {
                    value: price,
                }

                const tx = await VCAFixedPriceContract.purchaseBundle(bundleId, overrides)

                await provider.waitForTransaction(tx.hash);

                // Get the transaction receipt
                const receipt = await provider.getTransactionReceipt(tx.hash);

                // Check the status of the transaction
                if (receipt.status === 1) {
                    notifications.setNotificationMessage({
                    id: notifId,
                    message: "Token succesfully minted"
                    })
            
                    notifications.resolve(notifId)                   

                } else {
                    // Catch any errors
                    notifications.setNotificationMessage({
                        id: notifId,
                        message: "Error minting token"
                    })

                    notifications.reject(notifId)
                }  
            } catch (error) {
            
                console.log(error)

                notifications.setNotificationMessage({
                    id: notifId,
                    message: "Error minting token"
                })

                notifications.reject(notifId)
            }
        } 

        else if (isMobileDevice()){
            mobileDeepLink(window.location.pathname)
        } 

        else {
            console.log("No Metamask wallet detected")

            notifications.setNotificationMessage({
                id: notifId,
                message: "Error minting token"
            })

            notifications.reject(notifId)
        }
    }

    const handleSubmitAlMint = async (contract: string, bundleId: number, price: number) => {
        const notifId = notifications.addNotification({
            message: "Minting...",
            status: "pending",
        })

        let signature = getSignature(ethereumState.address+'_'+contract) || null

        // Get signature
        if (signature == null) {
            
            notifications.setNotificationMessage({
                id: notifId,
                message: "Sorry, you're not on the allow list"
            })

            notifications.reject(notifId)
        }

        if (window.ethereum?.isMetaMask) {

            try {
                const signer = getSigner()

                if (!signer) {
                    await waitSignerEthereum(window)
                }

                const VCAFixedPriceContract = new ethers.Contract(contract, VCACustomFixedPrice.abi, signer)
                
                let overrides = {
                    value: price,
                }

                const tx = await VCAFixedPriceContract.alPurchaseBundle(bundleId, signature, overrides)

                tx.wait()

                notifications.setNotificationMessage({
                    id: notifId,
                    message: "Token succesfully minted"
                })
                
                notifications.resolve(notifId)    
            } catch (error) {
            
                console.log(error)

                notifications.setNotificationMessage({
                    id: notifId,
                    message: "Error minting token"
                })

                notifications.reject(notifId)
            }
        } 

        else if (isMobileDevice()){
            mobileDeepLink(window.location.pathname)
        } 

        else {
            console.log("No Metamask wallet detected")

            notifications.setNotificationMessage({
                id: notifId,
                message: "Error minting token"
            })

            notifications.reject(notifId)
        }
    }

    if (broken) {
        return (
            <Container>
                <div className={styles.container}>
                    <div>
                        This token doesn't seem to exist.
                    </div>
                    <Label>Bringing you home</Label>
                </div>
            </Container>
        )
    }

    if (loading) {
        return <Loader />
    }

    return (
        bundleInfo && Object.keys(bundleInfo).length == 0 ? (
            <Loader />
        ) : (
        <Container>
            <div className={styles.token__container}>
                <div className={styles.media__container}>
                    <Media
                        mediaType={ bundleInfo?.contentType.split('/')[0] || 'image' }
                        cid={cleanHash(bundleInfo!.contentURI) || "" }
                        placeholderCid={cleanHash(bundleInfo!.contentURI) || ""}
                        displayCid={cleanHash(bundleInfo!.contentURI) || ""}
                        className={styles.event__cover}
                        alt="Token Cover"

                        fill
                    />
                </div>



                <div className={styles.info__container}>
                    <h1 className={styles.info__title}>
                        { bundleInfo?.name || "No Title Found" }
                    </h1>

                    <Label className={styles.creator}>
                        { "Created by "  + findEthArtistName(bundleInfo?.artist || "") }
                    </Label>

                    <div className={styles.info__description}>
                        { bundleInfo?.description || "No Description Found" }
                    </div>

                    <br />
                    <br />
                    
                    <div className={styles.bundle_wrapper}>
                        <p className={styles.bundle_footnote}>This bundle includes the following {bundleInfo?.dropIds.length} artworks</p>
                        <div className={styles.bundle_image_wrapper}>
                        { bundleInfo?.dropIds.map(drop =>
                            (
                                <Link to={`/token/ethereum/${contract}/${drop}`}>
                                <img
                                    alt="token"
                                    className={styles.event__cover}
                                    src={"/token_covers/" + contract + "_" + drop + ".png"}
                                />
                                </Link>
                            )
                        )}
                        </div>
                    </div>
                
                {/* Display available on date if event has not started */}
                { (!allowlist && bundleInfo?.saleStart && (bundleInfo.saleStart >= new Date().toISOString())) ?        
                (
                <p className={styles.sold_out}>Available on { getDateTime(bundleInfo.saleStart) }</p>
                ):
                (
                <>
                { bundleInfo && bundleInfo?.balance > 0 ? (
                    <div className={styles.auction__container}>
                    <div className={styles.editions__info}>
                        <div className={styles.editions__info__box}>
                            <Label>Edition Price</Label>
                            <h2 style={{ paddingLeft: "0.5rem"}}>{ allowlist && bundleInfo.alPrice ? bundleInfo.alPrice/1e18 + " ETH" : 
                                                                   bundleInfo!.price/1e18 + " ETH" }</h2>                                
                        </div>
                        <div className={styles.editions__info__box}>
                            <Label>Availability</Label>
                            <h2 style={{ paddingLeft: "0.5rem"}}>{ bundleInfo?.balance }</h2>
                        </div>
                    </div>
                    <div className={styles.royalties__info__box} style={{ paddingTop: "0.5rem"}}>   
                        <Label>
                            Royalties { bundleInfo?.royalties/100 } %
                        </Label>                          
                    </div>
                    <div>
                        <div className={styles.bid__container}>                           
                        { allowlist && bundleInfo.alPrice ? (
                        <Button 
                            style={{ width: "100%" }}
                            className={styles.swap__submit}
                            onClick={() => handleSubmitAlMint(contract, bundleId, bundleInfo.alPrice!)}
                        >
                        Mint for {bundleInfo?.alPrice/1e18}&nbsp;ETH
                        </Button>
                        ) : 
                        (
                        <Button 
                            style={{ width: "100%" }}
                            className={styles.swap__submit}
                            onClick={() => handleSubmitMint(contract, bundleId, bundleInfo!.price)}
                        >
                        Mint for {bundleInfo!.price/1e18}&nbsp;ETH
                        </Button>
                        )
                        }
                        </div>
                    </div>
                    <br />
                    <br />
                    </div>
                ):(
                 <></>
                )}
                </>)}
              </div>
           </div>
        </Container>
        )
    )
}