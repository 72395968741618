import { GRAPHCMS_NETWORK as NETWORK } from "../../constants"

const query: any = {}
const mutation: any = {}

query.queryProjects = `
    query queryProjects {
        artblocksProjects {
            title
            artistAddress
            mintPrice
            projectId
          }
        
    }
`

query.queryProjectsByAddress = `
    query queryProjects($ethereum: String) {
        artblocksProjects(where: {artistAddress: $ethereum})
        {
            projectId
            saleDuration
        }
    }  
`

query.queryProjectsById = `
    query queryProjects($id: Int!) {
        artblocksProjects(where: {projectId: $id})
        {
            renderDelay
            saleDuration
            flexEngine
            additionalInfos
            openSeaCollectionLink
            sansaCollectionLink
        }
    }  
`

mutation.createProject = `
    mutation createProject($title: String!, $mintPrice: Float!, $artistAddress: String!, $projectId: Int!) {
        createArtblocksProject(
            data: {title: $title, mintPrice: $mintPrice, artistAddress: $artistAddress, projectId: $projectId}
        ) {
            id
        }
    }  
` 

mutation.editProject = `
    mutation editProject($id: Int!, $saleDuration: Int!) {
        updateArtblocksProject(
            data: {saleDuration: $saleDuration}
            where: {projectId: $id}
        ) {
            id
        }
    }
`

mutation.publishProject = `
    mutation publishArtblocksProject($id: ID!) {
        publishArtblocksProject(where: {id: $id}) {
            id
        }
    }  
`


export const projects = {
    query,
    mutation,
}
