import { useEffect, useState, useRef } from 'react'

import { shorten, getMetadata, cleanHash, getHistory, getSwaps, getVCAFixedSaleDrop, getVCAMetadata } from '../../../utils/crypto'
import styles from '../styles.module.scss'
import { ButtonLink, Container, Countdown, DropdownInput, Input, Label, Modal, Button, NumberInput, Loader } from "../../design-system"

import { getDateTime } from '../../../utils/time'
import { Link } from 'react-router-dom'
import { findXTZArtistName, findEthArtistName } from '../token'
import { getToken } from "../../../pages/admin/manage-tokens/tokens"

import { SaleInfoPreview } from '../previewinfo'

export const GetArtistInfos = ({
    network,
    contract,
    id,
    externalUrl,
    saleType = "",
    saleStart,
    saleEnd,
    to = "",
    ...props
}: {
    network: "ethereum" | "tezos";
    contract: string;
    id: number;
    to?: string;
    externalUrl: string;
    saleType?: string;
    saleStart?: string;
    saleEnd?: string;
    [key: string]: any;
}) => {
    const [loading, setLoading] = useState(true)
    const [metadata, setMetadata] = useState<any>({})

    const [altMetadata, setAltMetadata] = useState<any>({})
    const [altEnd, setAltEnd] = useState<Date>()

    const [history, setHistory] = useState<any>({})
    const [swapData, setSwapData] = useState<any>({})

    useEffect(() => {
        const func = async () => {
            let metadata: any = {};
            let swapdata: any = {};
            let historyData: any = {};

            // actual tokens
            if (!externalUrl) {
                try {

                    // VCA Fixed sale tokens
                    if(saleType.toLowerCase().includes('vca-fixed') && network == "ethereum") {
                            metadata = await getVCAMetadata({
                            contract: contract,
                            id: id
                        })

                      //  availability = metadata.balance.toNumber() || 0
                    }

                    else {
                            metadata = await getMetadata({
                            network: network,
                            contract: contract,
                            id: id,
                        })
                        

                        const res: any = await getToken(contract, network, id)

                        if (saleType !== "auction") {
                            swapdata = await getSwaps({
                            network: network,
                            contract: contract,
                            id: id,
                            minterAddress: metadata.creators[0]?.creator_address,
                            vcaAddress: res.tokens[0].event.account.tezos

                        })
                        } else {
                            // Retrieves auction history if saleType == 'auction'
                            historyData = await getHistory({
                                network: network,
                                contract: contract,
                                id: id,
                        })

                            if (historyData.status == "IN_PROGRESS" || historyData.status == "active" || historyData.status == "APPROVED") {
                            //    tokens[i].ongoingAuction = true;
                            }
                        }

                       // tokens[i].availability = swapdata ? (swapdata[0]?.amount_left || 0) : 0 

                    }
                    metadata = metadata || {}
                    swapdata = swapdata || {}
                    historyData = historyData || null
                    setHistory(historyData)
                    setSwapData(swapdata)
                    setMetadata(metadata)
                    setLoading(false)
                } catch (error) {
                    console.log(error)
                }
            }
        };
        func();

    }, [])


    function checkForMultipleCreators (creators: any) {
        if(creators.length > 1){
            for (let i = 0; i < creators.length; i++) {
                const artistFound = findXTZArtistName(creators?.[i].creator_address)
                if(artistFound.slice(0, 2) != "tz"){
                    return artistFound;
                }
            }
        }
        return findXTZArtistName(creators?.[0].creator_address);
    }

    if (loading) {
        return (
            <div className={`${styles.token} ${styles.placeholder}`}>
                <div className={styles.edit__container}>

                    <div className={styles.broken}>
                        Metadata Loading
                        <Label center>If this persists, check<br /> token info</Label>
                    </div>
                </div>

            </div>
        )
    }

    return (
        <div className={styles.token} {...props} onClick={() => null}>
           <div className={styles.artist__name_event_view}>                
                {network === "tezos" && (
                    metadata?.creators?.[0] ? checkForMultipleCreators(metadata?.creators) : "No Creator Found"
                )}

                {network === "ethereum" && (
                    metadata?.artist ? findEthArtistName(metadata?.artist) : metadata?.nft?.minted?.address ? findEthArtistName(metadata?.nft?.minted?.address) : "No Creator Found"
                )}
            </div>
            
            <Label>
                {network === "tezos" && (
                   <div className={styles.label__truncated}>{metadata?.name || 'No Name Found'}</div> 
                )} 
                
                {network === "ethereum" && (
                    <>
                    {contract == '0x4926c8057b3d9f8638fe7b2573757f429648ebfc' ? (
                        <div className={styles.label__truncated}>{metadata?.metadata?.name + " #" + id || 'No Name Found'}</div> 
                    ):(
                        <div className={styles.label__truncated}>{metadata?.metadata?.name || altMetadata.name || 'No Name Found'}</div>
                    )}
                    </>
                )}
            </Label>

            {/** Event has not started */}
            {saleStart && saleStart > new Date().toISOString() ? (
                <div className={styles.auction__history}>
                    <div className={styles.info__container}>
                    <div className={styles.event__title} style={{ paddingTop: 0 }}>Available on {getDateTime(saleStart)}</div>
                    </div>
                </div>
            ):
            (
            <>
            {/** Display auction information **/}
            {saleType == "auction" && history.bids && (
                <div className={styles.auction__history}>
                    <div className={styles.info__container}>
                        <div>
                            <Label>
                                {network === "ethereum" ? (
                                    history.status === "ENDED" ? "Sold For" :
                                    history.bids.length === 0 ? "Reserve Price" :
                                    history.bids.length > 0 && "Current Bid"
                                ) : (
                                    history.status === "concluded" ? 
                                    (history.highest_bid === null ? "" : "Sold For") :
                                    history.bids.length === 0 ? "Reserve Price" :
                                    history.bids.length > 0 && "Current Bid"
                                )}
                            </Label>

                            <div className={styles.event__title} style={{ paddingTop: 0 }}>
                                {network === "ethereum" ? (
                                    (history.status === "ENDED" ? history.bids[0].amount :
                                    history.bids.length === 0 ? history.reservePrice :
                                    history.bids.length > 0 && history.bids[0].amount) / 1e18 + " ETH"
                                ) : (
                                    history.highest_bid === null && history.status === "concluded" ? "In the auction house" :
                                    (history.status === "concluded" ? history.highest_bid :
                                    history.bids.length === 0 ? history.reserve :
                                    history.bids.length > 0 && history.highest_bid) / 1e6 + " XTZ"
                                )}
                            </div>
                        </div>

                        {network === "ethereum" ? (
                            history.status === "IN_PROGRESS" || history.status === "APPROVED" && (
                                to ? (
                                    <ButtonLink to={to} className={styles.bid__button}>
                                        Bid
                                    </ButtonLink>                                    
                                ) : (
                                    <Button className={styles.bid__button}>
                                        Bid
                                    </Button>   
                                )
                            )
                        ) : (
                            history.status === "active" && (
                                to ? (
                                    <ButtonLink to={to} className={styles.bid__button}>
                                        Bid
                                    </ButtonLink> 
                                ) : (
                                    <Button className={styles.bid__button}>
                                        Bid
                                    </Button>
                                )
                            )
                        )}
                    </div>

                    <div style={{ width: '100%' }}>
                        {network === "ethereum" ? (
                            history?.status === "IN_PROGRESS" || history?.status === "APPROVED" && (
                                history.expiresAt &&
                                (
                                <Countdown target={
                                    new Date(history?.expiresAt)
                                } className={styles.countdown}/>   
                                ) 
                                /*: 
                                ( altEnd && (<Countdown target={ altEnd } className={styles.countdown}/>)) */ 
                            )
                        ) : (
                            history.status === "active" && (
                                <Countdown target={
                                    new Date(history?.end_time)
                                } className={styles.countdown}/>    
                            )
                        )}
                    </div>
                </div>                
            )}

            {/** Event is ongoing - display swap info */}
            {(saleStart && saleStart < new Date().toISOString() && saleType == "swap" || 
            saleStart && saleStart < new Date().toISOString() && saleType == "vca-fixed") &&
            <SaleInfoPreview
                to={to}
                network={network}
                saleType={saleType}
                supply={saleType == "swap" ? swapData[0]?.token.supply : metadata?.nft?.drop?.supply }
                balance={saleType == "swap" ? (swapData.length > 0 ? swapData[0].amount_left : 0) : metadata?.nft?.drop?.balance}
                price={saleType == "swap" ? swapData[0]?.price: metadata?.nft?.drop?.price}
                saleEnd={saleEnd}
                />
            }
            </>
            )}
        </div>
    )
}