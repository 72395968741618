import { useEffect, useState } from "react"

import { shorten } from "../../../utils/crypto"
import { notificationStore } from "../../../state/global/notificationStore"
import { fetchGraphcms, gql } from "../../../libs/graphcms"

import { PageWrapper, Input, Label, Button, Modal } from "../../../components/design-system"

import styles from './styles.module.scss'
import { upload } from "../../../libs/pinata"
import { IPFS_GATEWAY } from "../../../constants"

const fs = require('fs')

const getAccounts = async (set: any) => {
    await fetchGraphcms({
        key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
        query: gql.query.queryAccounts
    }).then((response) => {
        const accounts = response.accounts

        set(accounts)
    }).catch((error) => {
        console.log(error)
    })
}

export interface IAccount {
    id?: string;
    username?: string;
    tezos?: string;
    ethereum?: string;
    roles?: string[];
    cover?: string;
}

export const addAccount = async ({ account, type }: { account: IAccount, type: "add" | "edit" }) => {
    return new Promise<string>(async (resolve, reject) => {
        try {
            const data = await fetchGraphcms({
                key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
                query: type === "add" ? gql.mutation.addAccount : gql.mutation.editAccount,
                variables: {
                    id: account.id,
                    username: account.username || null,
                    tezos: account.tezos || null,
                    ethereum: account.ethereum,
                    roles: account.roles,
                    profilePicture: "ipfs://" + account.cover
                }
            })

            const id: string = type === "add" ? data.createAccount.id : account.id
            resolve(id)
        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

export const publishAccount = async (id: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await fetchGraphcms({
                key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
                query: gql.mutation.publishAccount,
                variables: {
                    id
                }
            })
    
            resolve(res)    
        } catch (error) {
            reject(error)
        }
    })
}

const deleteAccount = async (id: string) => {
    await fetchGraphcms({
        key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
        query: gql.mutation.deleteAccount,
        variables: {
            id
        }
    })
}

export const ManageUsers = () => {    
    const notifications = notificationStore()

    const [ethereumAddress, setEthereumAddress] = useState("")
    const [tezosAddress, setTezosAddress] = useState("")
    const [username, setUsername] = useState("")
    const [cover, setCover] = useState("")

    const [accounts, setAccounts] = useState([])

    const [openAddUser, setOpenAddUser] = useState(false)
    const [openEditUser, setOpenEditUser] = useState("")

    const [selectedRoles, setSelectedRoles] = useState<string[]>([])

    useEffect(() => {
        getAccounts(setAccounts)
    }, [])

    useEffect(() => {
        if (!openAddUser) {
            setEthereumAddress("")
            setTezosAddress("")

            setSelectedRoles([])
        }
    }, [openAddUser])

    const handleSelectRole = (role: any) => {
        if (selectedRoles.includes(role)) {
            setSelectedRoles(selectedRoles.filter(r => r !== role))
        } else {
            setSelectedRoles([...selectedRoles, role])
        }
    }
    
    const handleClickAddUser = async ({
        type
    }: {
        type: "add" | "edit"
    }) => {
        let notifID = notifications.addNotification({
            message: type === "add" ? "Building User" : "Rebuilding User",
            status: "pending"
        })

        try {
            const userID: string = await addAccount({
                account: {
                    id: openEditUser,
                    username: username,
                    ethereum: ethereumAddress || undefined,
                    tezos: tezosAddress || undefined,
                    roles: selectedRoles,
                    cover: cover
                },
            
                type: type
            })

            notifications.setNotificationMessage({
                id: notifID,
                message: type === "add" ? "Adding User" : "Editing User"
            })
            
            await publishAccount(userID).then(() => {
                notifications.setNotificationMessage({
                    id: notifID,
                    message: "Publishing User"
                })

                notifications.resolve(notifID)
            })
        } catch (error) {
            console.error(error)
            notifications.reject(notifID)
        }
            
        getAccounts(setAccounts).then(() => {
            setOpenAddUser(false)
            setOpenEditUser("")
        })
    }
    
    const handleAddUser = () => {
        setEthereumAddress("")
        setTezosAddress("")
        setUsername("")
        setSelectedRoles([])
        setCover("")

        setOpenAddUser(true)
    }

    const handleEditUser = (account: any) => {
        setEthereumAddress(account.ethereum)
        setTezosAddress(account.tezos)
        setUsername(account.username)
        setSelectedRoles(account.roles)
        setCover(account.profilePicture)

        setOpenEditUser(account.id)
    }

    const handleFileUpload = async (
        files: FileList | null,
    ) => {
        if (!files || files.length === 0) return

        const file = files[0]

        const notifID = notifications.addNotification({
            message: "Uploading File",
            status: "pending"
        })
            
        try {
            const res: any = await upload({ file })

            notifications.setNotificationMessage({
                id: notifID,
                message: "Uploaded File"
            })

            notifications.resolve(notifID)

            setCover(res.IpfsHash)
        } catch (error) {
            console.error(error)
            notifications.reject(notifID)
        }
    }

    return (
        <PageWrapper>
            <div className={styles.card__container}>
                <div className={styles.user__table__container}>
                    <table className={styles.user__table}>
                        <thead>
                            <tr>
                                <th style={{ padding: '0 2rem' }}>Nickname</th>
                                <th>Ethereum Address</th>
                                <th>Tezos Address</th>
                                <th>Roles</th>
                            </tr>
                        </thead>
                        <tbody>
                            {accounts.map((account: any) => (
                                <tr key={account.id}>
                                    <td style={{ padding: '0 2rem' }}>{account.username}</td>
                                    <td><a href={"https://etherscan.io/address/" + account.ethereum} target="_blank" rel="noreferrer">{shorten(account.ethereum, 4)}</a></td>
                                    <td><a href={"https://tzkt.io/" + account.tezos} target="_blank" rel="noreferrer">{shorten(account.tezos, 4)}</a></td>

                                    <td style={{ textTransform: 'capitalize' }}>{account.roles.join(', ') || "No Roles"}</td>

                                    <td>
                                        <Button onClick={() => handleEditUser(account)}>Edit</Button>
                                        <Button onClick={async () => {
                                            const notifID = notifications.addNotification({
                                                message: "Deleting User",
                                                status: "pending"
                                            })

                                            try {
                                                await deleteAccount(account.id).then(() => {
                                                    notifications.setNotificationMessage({
                                                        id: notifID,
                                                        message: "Deleted User"
                                                    })

                                                    notifications.resolve(notifID)
                                                })
                                            } catch (error) {
                                                console.error(error)

                                                notifications.reject(notifID)
                                            }

                                            getAccounts(setAccounts)
                                        }}>Remove</Button>
                                    </td>   
                                </tr>                                     
                            ))}
                        </tbody>
                    </table>
                    <div style={{ width: '200px', border: '1px solid transparent', marginTop: '0.5rem' }}>
                        <Button className={styles.add__user__button} onClick={() => handleAddUser()}>
                            Add User
                        </Button>
                    </div>
                </div>

                <Modal
                    onClose={() => {
                        setEthereumAddress("")
                        setTezosAddress("")
                        setUsername("")
                        setSelectedRoles([])
                        setCover("")

                        setOpenAddUser(false)
                        setOpenEditUser("")
                    }}
                    open={openAddUser || openEditUser !== ""}
                    title={openEditUser === "" ? "Add A User" : "Edit User"}
                >
                    <div className={styles.add__user__modal}>
                        <div>
                            <div>
                                <Modal.Wrapper key="user-modal-ethereum">
                                    <Label>Ethereum Address</Label>
                                    <Input
                                        value={ethereumAddress}
                                        onChange={(e) => setEthereumAddress(e.target.value)}
                                    
                                        placeholder="Enter Ethereum Address"
                                    />
                                </Modal.Wrapper>
                                <Modal.Wrapper key="user-modal-tezos">
                                    <Label>Tezos Address</Label>
                                    <Input
                                        value={tezosAddress}
                                        onChange={(e) => setTezosAddress(e.target.value)}
                                    
                                        placeholder="Enter Tezos Address"
                                    />
                                </Modal.Wrapper>

                                <Modal.Spacer key={"user-modal-spacer-0"}/>

                                <Modal.Wrapper key="user-modal-username">
                                    <Label>Nickname (optional)</Label>
                                    <Input
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    
                                        placeholder="Enter a Username"
                                    />
                                </Modal.Wrapper>
                                <Modal.Wrapper key="user-modal-profile-image">
                                    <Label>Profile Image (optional)</Label>
                                    <label className={styles.file__input}>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => handleFileUpload(e.target.files)}
                                        />

                                        <span className={styles.upload__text}>{
                                            cover ? "Change Profile Image" : "Upload Profile Image"
                                        }</span>
                                    </label>

                                    <div className={styles.upload__container}>
                                        {cover && (
                                            <a style={{ width: 'fit-content', height: 'inherit' }} href={IPFS_GATEWAY + cover} target="_blank" rel="noreferrer">
                                                <Label className={styles.underline} left>View on IPFS</Label>
                                            </a>
                                        )}
                                        <Label right>.png .jpeg .jpg</Label>
                                    </div>
                                </Modal.Wrapper>

                                <Modal.Spacer key={"user-modal-spacer-1"}/>

                                <Modal.Wrapper key="user-modal-roles">
                                    <Label>Select Roles</Label>
                                    <div className={styles.multiselect__container}>
                                        <Button className={`${styles.multiselect__button} ${selectedRoles.includes("creator") ? styles.active : null}`} onClick={() => handleSelectRole("creator")}>
                                            Creator
                                        </Button>
                                        <Button className={`${styles.multiselect__button} ${selectedRoles.includes("curator") ? styles.active : null}`} onClick={() => handleSelectRole("curator")}>
                                            Curator
                                        </Button>
                                        <Button className={`${styles.multiselect__button} ${selectedRoles.includes("superAdmin") ? styles.active : null}`} onClick={() => handleSelectRole("superAdmin")}>
                                            Super Admin
                                        </Button>
                                        <Button className={`${styles.multiselect__button} ${selectedRoles.includes("admin") ? styles.active : null}`} onClick={() => handleSelectRole("admin")}>
                                            Admin
                                        </Button>
                                    </div>
                                </Modal.Wrapper>    
                            </div>

                            <div className={styles.add__user__modal__buttons} key="user-modal-submit">
                                <Button className={styles.add__modal__button} onClick={() => handleClickAddUser(openEditUser === "" ? { type: "add" } : { type: "edit" })}>Submit</Button>
                            </div>
                        </div>
                    </div>
                </Modal>

            </div>
        </PageWrapper>
    )
}