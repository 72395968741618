export const GRAPHCMS_ENDPOINT = 'https://api-eu-west-2.graphcms.com/v2/cl1icgddf5usg01xi4rht1l5p/master'

export const IPFS_GATEWAY = 'https://verticalcrypto.mypinata.cloud/ipfs/'
export const NETWORK: 'testnet' | 'mainnet' = 'mainnet'
export const PROVIDER_NETWORK = (NETWORK as 'mainnet' | 'testnet') === 'mainnet' ? 'homestead' : 'goerli'

export const GRAPHCMS_NETWORK = "testnet"

export const ETHEREUM_NETWORK = (NETWORK as 'mainnet' | 'testnet') === 'mainnet' ? 'mainnet' : 'goerli'
export const TEZOS_NETWORK = (NETWORK as 'mainnet' | 'testnet') === 'mainnet' ? 'mainnet' : 'mainnet' // NOTE: No Available Testnet contracts
export const DEFAULT_CURATOR_ID = 'cl7rx0pitgevt0bmmfx4gp0yw'
export const DEFAULT_CURATOR_TZ_ADDRESS = 'tz1UDXLxVUEPezazqXV9ZXMaK9zYD6DGfT2m'
export const DEFAULT_CURATOR_ETH_ADDRESS = '0xB55c75e1BdBd340E173abD44ecBb4e903e5274d8'
export const DEFAULT_DROP_MANAGER_ETH_ADDRESS =  (NETWORK as 'mainnet' | 'testnet') === 'mainnet' ? '0xd646894f8E6c501E294D95a56691c62b8AceCBC6' : '0x69A6875099915b608656eD9D2B92db0260D9035b'

export const DEFAULT_VCA_TZ_ADDRESS = 'tz1UDXLxVUEPezazqXV9ZXMaK9zYD6DGfT2m'

export const ETHERSCAN_GATEWAY = 'https://api.etherscan.io/api?'
export const ETHERSCAN_API = (NETWORK as 'mainnet' | 'testnet') === 'mainnet' ? 'https://api.etherscan.io/api?' : 'https://api-goerli.etherscan.io/api?'
export const ETHERSCAN_URL = (NETWORK as 'mainnet' | 'testnet') === 'mainnet' ? 'https://etherscan.io/' : 'https://goerli.etherscan.io/'

export const TEZOS_AUCTION_EXTENSION = 600 // TEST PARAMS: TO CHANGE
export const TEZOS_PRICE_INCREMENT = 1e6 // TEST PARAMS: TO CHANGE

export const FXHASH_CONTRACT_ADDRESS = 'KT1U6EHmNxJTkvaWJ4ThczG4FSDaHC21ssvi'

export const ARTBLOCKS_GENERATOR_ADDRESS = (NETWORK as 'mainnet' | 'testnet') === 'mainnet' ? 'https://generator.artblocks.io/0x32d4be5ee74376e08038d652d4dc26e62c67f436/' : 'https://generator-staging-goerli.artblocks.io/0x783d27cf1ed18bedf1360902ce80e44dc6f673b4/'
export const ARTBLOCKS_GENERATOR_ADDRESS_WITHOUT_SLASH = (NETWORK as 'mainnet' | 'testnet') === 'mainnet' ? 'https://generator.artblocks.io/0x32d4be5ee74376e08038d652d4dc26e62c67f436' : 'https://generator-staging-goerli.artblocks.io/0x783d27cf1ed18bedf1360902ce80e44dc6f673b4'

export const ARTBLOCKS_TOKEN_ADDRESS = (NETWORK as 'mainnet' | 'testnet') === 'mainnet' ? 'https://token.artblocks.io/0x32d4be5ee74376e08038d652d4dc26e62c67f436/' : 'https://token.staging.artblocks.io/0x783d27cf1ed18bedf1360902ce80e44dc6f673b4/'
export const ARTBLOCKS_IMAGE_PREVIEW = (NETWORK as 'mainnet' | 'testnet') === 'mainnet' ? 'https://verticalcrypto-gen-art-mainnet.s3.amazonaws.com/' : 'https://verticalcrypto-gen-art-goerli.s3.amazonaws.com/'
export const ARTBLOCKS_OPENSEA_LINK =  (NETWORK as 'mainnet' | 'testnet') === 'mainnet' ? 'https://opensea.io/assets/ethereum/0x32d4be5ee74376e08038d652d4dc26e62c67f436/' : 'https://testnets.opensea.io/assets/goerli/0x783d27cf1ed18bedf1360902ce80e44dc6f673b4/'
export const ARTBLOCKS_FLEX_CONTRACT_ADDRESS = (NETWORK as 'mainnet' | 'testnet') === 'mainnet' ? "0x32d4be5ee74376e08038d652d4dc26e62c67f436" : "0x783d27cf1ed18bedf1360902ce80e44dc6f673b4"
export const ARTBLOCKS_DA_MINTER_CONTRACT_ADDRESS = (NETWORK as 'mainnet' | 'testnet') === 'mainnet' ? "0x4d5264b1ddc39758cad1970d65dd658753e73a45" : "0xf7Ca5E38E8Cad36b226d480F5A4E491405Da852A"
export const ARTBLOCKS_ARTIST_PROJECT_SITE = (NETWORK as 'mainnet' | 'testnet') === 'mainnet' ? "https://artblocks.io/engine/flex/projects/0x32d4be5ee74376e08038d652d4dc26e62c67f436/" : "https://artist-staging.artblocks.io/engine/flex/projects/0x783d27cf1ed18bedf1360902ce80e44dc6f673b4/"
export const ARTBLOCKS_GRAPHQL = (NETWORK as 'mainnet' | 'testnet') === 'mainnet' ? "https://api.thegraph.com/subgraphs/name/artblocks/art-blocks" : "https://api.thegraph.com/subgraphs/name/artblocks/art-blocks-artist-staging-goerli"

export const VCA_ETH_MINT_ADDRESS = '0xF4398a4d8bf5e267abdA3D62C47F8eF5f43e8c34'
export const ZORA_AUCTION_CONTRACT = '0xe468ce99444174bd3bbbed09209577d25d1ad673'

export const MANIFOLD_MARKETPLACE = '0x3a3548e060be10c2614d0a4cb0c03cc9093fd799'
export const SEPOLIA_ETH_MARKETPLACE_IMPL = '0xAAB915cD4f4c0A63280C4e7c20D24875fC238Efc'
export const SEPOLIA_ETH_MARKETPLACE_PROXY = '0x92C3A718B8bA94866655b2d95b88Ab48A795dE68'

export const VCA_RANKEDAUCTION_CONTRACT = '0xC9774185f2F30Cce393d0f86337E2915Fc90BA9d'
export const VCA_RANKEDAUCTION_INDEXER_ENDPOINT = 'https://api.thegraph.com/subgraphs/name/0xkobe/ranked-auction'