import { Modal, CircularStatic } from "../design-system"
import { ARTBLOCKS_GENERATOR_ADDRESS, ARTBLOCKS_TOKEN_ADDRESS } from "../../constants"
import styles from './styles.module.scss'
import { TwitterShareButton } from "react-share"

export const MintConfirmation = ({ tokenId, projectId, aspectRatio, setClose, open, loading } : 
    { tokenId: number, projectId: number, aspectRatio: number, setClose: any, open: boolean, loading: boolean}) => {

    return (
        <Modal title="Thanks for minting!"
               onClose={setClose}
               open={open} >
            
            { loading ? (
                <div className={styles.loading__content}>
                    <CircularStatic seconds_per_increment={6000} />
                    <p>We're getting ready for a preview of your minted generative art. This may take up to 3 minutes... Please do not close this window. <br /><br />
                    You can also view the token directly 
                        <a href={ARTBLOCKS_GENERATOR_ADDRESS+projectId.toString()+(tokenId).toString().padStart(6, "0")} target="_blank" 
                            style={{ textDecoration: "underline",
                                     margin: "0 0.5ch"}}>here</a> 
                    when it's ready.</p>
                </div>
              ) : (
              <div className={styles.preview__content}>

                <iframe
                    src={ARTBLOCKS_GENERATOR_ADDRESS+projectId.toString()+(tokenId).toString().padStart(6, "0")}
                    style={{
                        height: `calc(0.5 * 30vw * ${1/aspectRatio})`,
                        width: 'calc(0.5 * 30vw)',
                    }}
                />

                <p>You have successfully minted Token #{tokenId}</p>


                <div className={styles.social__share}>
                    <div className={styles.social__share__box}>
                    <TwitterShareButton 
                        url={ARTBLOCKS_GENERATOR_ADDRESS+projectId.toString()+(tokenId).toString().padStart(6, "0")}
                        title={"Look at the artwork I have just minted!\n"}
                    >
                    <div className={styles.social__share__text}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" className="bi bi-twitter" viewBox="0 0 16 12">
                        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                        </svg>
                    </div> &nbsp;
                    <div className={styles.social__share__text}>
                        Share on Twitter
                    </div>
                    </TwitterShareButton>
                    </div>
                </div>
              </div>
            )}
        </Modal>
    )
}