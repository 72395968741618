const query: any = {}
const mutation: any = {}

// Retrives all cached addresses and their ENS
query.getAllAddresses = `
    query getAllAddresses {
        eNS {
            walletAddress
            ensName
        }
    }
`

// Retrieves a given ens record
query.getAddressENS = `
    query getAddressENS($wallet: String!) {
        ens(where: {walletAddress: $wallet}) {
            walletAddress
            ensName
        }
    }
`

mutation.addEns = `
    mutation createEns($wallet: String!, $ens: String) {
        createEns(
            data: {walletAddress: $wallet, ensName: $ens}
        ) {
            id
        }
    }  
` 

mutation.publishEns = `
    mutation publishEns($id: ID!) {
        publishEns(where: {id: $id}) {
            id
        }
    }  
`


export const addresses = {
    query,
    mutation,
}
