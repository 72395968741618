import { useRef, useState } from 'react'
import { cleanHash } from '../../utils/crypto'
import { IPFS_GATEWAY } from '../../constants'
import { LazyLoad, Label } from '../design-system'
import styles from './media.module.scss'

import classNames from 'classnames'

export enum MediaTypeEnum {
    IMAGE = "image",
    VIDEO = "video",
    AUDIO = "audio",
    HTML = "application",
    MODEL = "model"
}

export const Media = ({
    mediaType,
    cid,
    placeholderCid,
    className,
    fill = false,
    mock = false,
    ...props
}: {
    mediaType: MediaTypeEnum;
    cid: string;
    placeholderCid: string;
    className?: string;
    [key: string]: any;
    fill?: boolean;
    mock?: boolean;
    alt?: string;
}) => {
    const ref = useRef<any>()

    const [playing, setPlaying] = useState(true)
    const [muted, setMuted] = useState(true)

    const toggleFullscreen = () => {
        var el = document.getElementById("videoPlayer");

        if (!el) return

        if (el.requestFullscreen) {
          el.requestFullscreen();
        }
    }
    
    if (mediaType === MediaTypeEnum.IMAGE) {
        return (
            <LazyLoad
                className={classNames(className, fill && styles.fill)}

                src={mock ? placeholderCid : (IPFS_GATEWAY + cid)}
                placeholderSrc={mock ? placeholderCid : (IPFS_GATEWAY + placeholderCid)}
                {...props}

                fill={fill}
            />
        )
    }

    if (mediaType === MediaTypeEnum.VIDEO) {
        return (
            <div className={`${styles.container} ${fill ? styles.fill : ''}`}>
                <video
                    ref={ref}
                    className={styles.video__player}
                    playsInline
                    preload="auto"
                    src={mock ? cid : (IPFS_GATEWAY + cid)}
                    autoPlay
                    loop
                    {...props}

                    muted={muted}
                    id={"videoPlayer"}
                />

                {fill && (
                    <div className={styles.controls}>
                        <div className={styles.controls__fullscreen} onClick={() => {
                            toggleFullscreen()
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                                <path d="M5 19v-5h2v3h3v2Zm0-9V5h5v2H7v3Zm9 9v-2h3v-3h2v5Zm3-9V7h-3V5h5v5Z"/>
                            </svg>
                        </div>

                        <div className={styles.controls__play} onClick={() => {
                            playing ? ref.current.pause() : ref.current.play()

                            setPlaying(!playing)
                        }}>
                            {playing ? (                            
                                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                                    <path d="M18.5 32h3V16h-3Zm8 0h3V16h-3ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm0-3q7.1 0 12.05-4.975Q41 31.05 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24 41Zm0-17Z"/>
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                                    <path d="M19.15 32.5 32.5 24l-13.35-8.5ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm0-3q7.1 0 12.05-4.975Q41 31.05 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24 41Zm0-17Z"/>
                                </svg>
                            )}
                        </div>

                        <div className={styles.controls__volume} onClick={() => {
                            setMuted(!muted)
                        }}>
                            {muted ? (
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                                    <path d="m19.8 22.6-3.025-3.025q-.625.4-1.325.688-.7.287-1.45.462v-2.05q.35-.125.688-.25.337-.125.637-.3L12 14.8V20l-5-5H3V9h3.2L1.4 4.2l1.4-1.4 18.4 18.4Zm-.2-5.8-1.45-1.45q.425-.775.638-1.625.212-.85.212-1.75 0-2.35-1.375-4.2T14 5.275v-2.05q3.1.7 5.05 3.137Q21 8.8 21 11.975q0 1.325-.362 2.55-.363 1.225-1.038 2.275ZM9.1 11.9Zm7.15 1.55L14 11.2V7.95q1.175.55 1.838 1.65.662 1.1.662 2.4 0 .375-.062.738-.063.362-.188.712ZM12 9.2 9.4 6.6 12 4Zm-2 5.95V12.8L8.2 11H5v2h2.85Z"/>
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                                    <path d="M14 20.725v-2.05q2.25-.65 3.625-2.5t1.375-4.2q0-2.35-1.375-4.2T14 5.275v-2.05q3.1.7 5.05 3.137Q21 8.8 21 11.975q0 3.175-1.95 5.612-1.95 2.438-5.05 3.138ZM3 15V9h4l5-5v16l-5-5Zm11 1V7.95q1.125.525 1.812 1.625.688 1.1.688 2.425 0 1.325-.688 2.4Q15.125 15.475 14 16Zm-4-7.15L7.85 11H5v2h2.85L10 15.15ZM7.5 12Z"/>
                                </svg>
                            )}
                        </div>
                    </div>
                
                )}
            </div>
        )
    }

    if (mediaType === MediaTypeEnum.AUDIO) {
        return (
            <div className={`${styles.container} ${fill ? styles.fill : ''}`}>
                <audio
                    ref={ref}
                    className={styles.audio__player}
                    controls
                    playsInline
                    preload="auto"
                    src={mock ? cid : (IPFS_GATEWAY + cid)}
                    {...props}
                />

                <LazyLoad
                    className={styles.audio__placeholder}
                    src={mock ? placeholderCid : (IPFS_GATEWAY + placeholderCid)}
                    {...props}
                />

                {fill && (
                    <div className={styles.controls}>
                        <div className={styles.controls__play} onClick={() => {
                            playing ? ref.current.pause() : ref.current.play()

                            setPlaying(!playing)
                        }}>
                            {playing ? (                            
                                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                                    <path d="M18.5 32h3V16h-3Zm8 0h3V16h-3ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm0-3q7.1 0 12.05-4.975Q41 31.05 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24 41Zm0-17Z"/>
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                                    <path d="M19.15 32.5 32.5 24l-13.35-8.5ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm0-3q7.1 0 12.05-4.975Q41 31.05 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24 41Zm0-17Z"/>
                                </svg>
                            )}
                        </div>
                    </div>
                )}
            </div>
        )
    }

    if (mediaType === MediaTypeEnum.HTML) {
        return (
            <div className={`${styles.container} ${fill ? styles.fill : ''}`}>
                <iframe
                    ref={ref}
                    scrolling="no" 
                    src={mock ? cid : (IPFS_GATEWAY + cid)}
                    {...props}

                    style={fill ? {
                        height: 'calc(0.5 * 80vw)',
                        width: 'calc(0.5 * 80vw)'
                    } : {}}

                    title={cleanHash(cid)}
                />

            </div>
        )
    }

    if (mediaType === MediaTypeEnum.MODEL) {
        const props: any = {
            src: mock ? cid : (IPFS_GATEWAY + cid),
            autoplay: true,
            'auto-rotate': true,
            'data-js-focus-visible': true,
            'interaction-prompt': 'none',
        }

        props['ar'] = true
        props['ar-modes'] = 'webxr scene-viewer quick-look'
        props['camera-controls'] = true

        return (
            <div className={`${styles.container} ${fill ? styles.fill : ''}`}>
                {/* @ts-expect-error */}
                <model-viewer {...props} />
            </div>
        )
    }

    return null
}