import { GRAPHCMS_ENDPOINT } from "../../constants"
import { fetchAxios } from "../axios"

export const fetchGraphcms = async ({
    key,
    query,
    variables,
    headers,
}: {
    key: string | undefined,
    query: string,
    variables?: any,
    headers?: any,
}) => {
    const response = await fetchAxios({
        url: GRAPHCMS_ENDPOINT,
        options: {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${key}`,
                ...headers,
            },
            data: JSON.stringify({
                query,
                variables,
            }),
        },
    })
    
    return response.data
}