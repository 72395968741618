import { Link } from 'react-router-dom'
import { Masonry } from '@mui/lab'

import { Container, PageWrapper, Label } from '../../../components/design-system'
import { Card } from '../components/card'

import styles from './styles.module.scss'
import classNames from 'classnames'

const AdminCard = ({
    children,
    className,
    to = "",
    message = 'Manage',
    ...props
}: {
    children: React.ReactNode,
    className?: string,
    to?: string,
    message?: string,
    [key: string]: any,
}) => {
    return (
        <Link style={{ textDecoration: 'none' }} to={to} {...props}>
            <Card className={classNames(styles.card, className)}>
                <Label className={styles.label}>{message === 'Manage' ? 'Manage' : message}</Label>
                {children}

                <svg className={styles.card__icon} xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                    <path d="M24 40 21.9 37.85 34.25 25.5H8V22.5H34.25L21.9 10.15L24 8L40 24Z"/>
                </svg>
            </Card>
        </Link>
    )
}

export const AdminDashboard = () => {
    return (
        <PageWrapper>
            <Container>
                <Masonry sx={{ width: 'auto' }} columns={{ xs: 1, lg: 2 }}>
                    <AdminCard to="/admin/manage-users">
                        <h1>Users</h1>
                    </AdminCard>
                    <AdminCard to="/admin/manage-tokens">
                        <h1>Tokens</h1>
                    </AdminCard>
                    <AdminCard to="/admin/manage-swaps">
                        <h1>Swaps</h1>
                    </AdminCard>
                    <AdminCard to="/admin/manage-events">
                        <h1>Events</h1>
                    </AdminCard>
                    <AdminCard to="/admin/manage-event-categories">
                        <h1>Categories</h1>
                    </AdminCard>
                    <AdminCard to="/admin/create-project-shell" message="Create">
                        <h1>Project Shell</h1>
                    </AdminCard>
                    <AdminCard to="/creator/dashboard" message="Go to">
                        <h1>Creator Panel</h1>
                    </AdminCard>
                </Masonry>
            </Container>
        </PageWrapper>
    )
}