import { notificationStore } from "../../../state/global/notificationStore"
import { AnimatePresence, LayoutGroup, motion, MotionValue, useTime, useTransform } from "framer-motion"
import classNames from "classnames"

import { Label } from ".."

import styles from './styles.module.scss'
import { useEffect, useState } from "react"

const ExpiryTimer = ({
    expiryDate,
    expiryLength
}: {
    expiryDate: number,
    expiryLength: number
}) => {
    const time = useTime()
    const height = useTransform(
        time,
        [0, 150, expiryLength],
        ["0%", "100%", "0%"]
        )

    return (
        <motion.span
            animate={{ height: "100%" }}

            className={styles.expiry}
            style={{ height }}
        />
    )
}

export const Notifications = () => {
    const notifications = notificationStore()

    return (
        <div className={styles.notifications}>
            <LayoutGroup>
                <AnimatePresence>
                    {notifications.notifications.map((notification) => (
                        <Notification
                            key={`notification-${notification.id}`}

                            id={notification.id}
                            status={notification.status}
                            message={notification.message}
                            reference={notification?.reference}
                            
                            expiryLength={notification?.expiryLength}
                            expiryDate={notification?.expiryDate}
                        />
                    ))}
                </AnimatePresence>
            </LayoutGroup>
        </div>
    )
}

export const Notification = ({
    id,
    status,
    message,
    reference,
    expiryLength,
    expiryDate,
}: {
    id: number,
    status: string,
    message: string,
    reference?: string,
    expiryLength?: number,
    expiryDate?: number,
}) => {
    const classnames = classNames(
        styles.notification__container,
        styles[status]
    )

    return (
        <motion.div
            layout

            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, x: '100%' }}

            transition={{ duration: 0.5, ease: 'easeInOut' }}

            className={classnames}
        >

            {expiryLength && expiryDate && (
                <ExpiryTimer
                    expiryDate={expiryDate}
                    expiryLength={expiryLength}
                />
            )}

            <div className={styles.notification__inner__container}>
                <Label className={styles.label}>
                    {status}

                    {reference && (<>
                        : <a href={reference} target="_blank" rel="noopener noreferrer">More Info</a>
                    </>)}
                </Label>
                <div
                    key={id}
                    className={styles.notification}
                >
                    {message}
                </div>
            </div>

            {status === "pending" && (
                <div className={styles.icon}>
                    <motion.div
                        animate={{
                            rotate: [0, 360]
                        }}

                        transition={{
                            duration: 0.7,
                            ease: "easeInOut",
                            loop: Infinity
                        }}

                        className={styles.loader__semicircle}
                    />
                </div>
            )}

            {status === "success" && (
                <div className={styles.icon}>
                    <motion.svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                    
                        initial={{ scale: 1 }}
                        animate={{ scale: [0.9, 1.3, 1] }}
                        transition={{
                            duration: 0.4,
                            ease: [0.43, 0, 0.23, 1]
                        }}
                    >
                        <motion.path d="M9.55 18 3.85 12.3 5.275 10.875 9.55 15.15 18.725 5.975 20.15 7.4Z" />
                    </motion.svg>
                </div>
            )}

            {status === "error" && (
                <div className={styles.icon}>
                    <motion.svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        width="24"

                        initial={{ scale: 1 }}
                        animate={{ scale: [0.9, 1.3, 1] }}
                        transition={{
                            duration: 0.4,
                            ease: [0.43, 0, 0.23, 1]
                        }}
                    >
                        <path d="M11 13H13V7H11ZM12 17Q12.425 17 12.713 16.712Q13 16.425 13 16Q13 15.575 12.713 15.287Q12.425 15 12 15Q11.575 15 11.288 15.287Q11 15.575 11 16Q11 16.425 11.288 16.712Q11.575 17 12 17ZM12 22Q9.925 22 8.1 21.212Q6.275 20.425 4.925 19.075Q3.575 17.725 2.788 15.9Q2 14.075 2 12Q2 9.925 2.788 8.1Q3.575 6.275 4.925 4.925Q6.275 3.575 8.1 2.787Q9.925 2 12 2Q14.075 2 15.9 2.787Q17.725 3.575 19.075 4.925Q20.425 6.275 21.212 8.1Q22 9.925 22 12Q22 14.075 21.212 15.9Q20.425 17.725 19.075 19.075Q17.725 20.425 15.9 21.212Q14.075 22 12 22ZM12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12ZM12 20Q15.325 20 17.663 17.663Q20 15.325 20 12Q20 8.675 17.663 6.337Q15.325 4 12 4Q8.675 4 6.338 6.337Q4 8.675 4 12Q4 15.325 6.338 17.663Q8.675 20 12 20Z"/>
                    </motion.svg>
                </div>
            )}
        </motion.div>
    )
}