import { useRef } from 'react'

import classNames from "classnames"
import { AnimatePresence, motion } from 'framer-motion'

import styles from './styles.module.scss'
import { useDetectClickOutside } from '../../../utils/hooks/useDetectClickOutside'

const Modal = ({
    children,
    className,
    onClose,
    open,
    title,
}: {
    children: React.ReactNode,
    className?: string,
    onClose: () => void,
    open: boolean,
    title: string,
}) => {
    const classes = classNames(styles.modal, className)
    const modalRef = useRef<HTMLDivElement>(null)

    useDetectClickOutside(modalRef, () => onClose())
    
    return (
        <AnimatePresence>
            {open && (
                <div
                    className={styles.modal__container}
                >
                    <motion.div
                        initial={{ opacity: 0, y: -100 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 50 }}
                        transition={{ duration: 0.3, ease: [0.43, 0.13, 0.4, 1] }}
                        className={classes}
                        ref={modalRef}
                    >
                        <div className={styles.modal__header}>
                            <h2>{title}</h2>
                            <button onClick={onClose} className={styles.close__modal}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/>
                                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/>
                                    </svg>
                            </button>
                        </div>
                        <div className={styles.modal__content}>
                            {children}
                        </div>
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    )
}

Modal.Wrapper = ({
    children,
    ...props
}: {
    children: React.ReactNode,
    [key: string]: any
}) => {
    return (
        <div className={styles.modal__wrapper}>
            {children}
        </div>
    )
}

Modal.Spacer = ({
    ...props
}: {
    [key: string]: any
}) => {
    return (
        <div className={styles.modal__spacer} {...props}/>
    )
}

export { Modal }