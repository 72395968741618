import { useEffect, useState } from "react"
import styled from 'styled-components'
import { Container, DropdownInput, Label, Modal, NumberInput, Button, CustomPagination, TimeInput, DateInput } from "../../../components/design-system"
import { ethereumStore} from "../../../state/crypto/ethereumStore"
import { waitSignerTezos } from "../../../libs/crypto/crypto"
import { tezosStore } from "../../../state/crypto/tezosStore"
import {
        TEZOS_AUCTION_EXTENSION,
        TEZOS_PRICE_INCREMENT,
        DEFAULT_CURATOR_TZ_ADDRESS,
        DEFAULT_CURATOR_ETH_ADDRESS
       } from "../../../constants"
import { IEvent } from '../../admin/manage-events'
import { addToken, publishToken } from '../../admin/manage-tokens/tokens'
import { notificationStore } from "../../../state/global/notificationStore"
import classNames from "classnames"
import { motion } from "framer-motion"
import { useQuery, gql } from '@apollo/client';
import { fetchGraphcms, gql as gcmsQuery } from "../../../libs/graphcms"
import { CleanToken } from "../../../components/token/cleantoken"
import { Masonry } from "@mui/lab"
import { Search } from "../../../components/search"

import { TezosToolkit, OpKind } from '@taquito/taquito'
import { BeaconWallet, BeaconWalletNotInitialized } from '@taquito/beacon-wallet'
import { NetworkType } from '@airgap/beacon-sdk';

import styles from './styles.module.scss'

interface Tokens {
    fa2_address: string;
    artifact_uri: string;
    token_id: string;
    name: string;
  }
  
export interface TokensData {
  tokens: Tokens[];
}

interface TokensVar {
  tezosAddress: string;
}

const GeneratedText = styled.span`
    padding-top: 0.5em;
    padding-left: 0.5em;
`
const GET_USER_TEZOS_NFTS = gql`
query QueryTokens($tezosAddress : String!) {
    tokens(where: {holdings: {_and: {amount: {_gt: "0"}}, holder_address: {_eq: $tezosAddress }}}) { 
        fa2_address
        artifact_uri
        token_id
        name
    }
  }
`;

// Retrieve upcoming events
export const getUpcomingEvents = async (set: any) => {
    await fetchGraphcms({
        key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
        query: gcmsQuery.query.queryLatestEvents,
        variables:{
            network: `mainnet`
        }
    }).then((response) => {
        const allEvents = response.events
        const currentDt = new Date().toISOString();
        const upcomingEvents = allEvents.filter((eventObj: any) => eventObj.startDate >= currentDt)
        set(allEvents)
    }).catch((error) => {
        console.log(error)
    })
}

// Retrieve selected event start/end date
export const getEventDetails = async (network: "tezos" | "ethereum", name: string, setEventId: any,
                                setCuratorAddress: any, setCuratorFee: any,
                                setStart?: any, setEnd?: any) => {
    await fetchGraphcms({
        key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
        query: gcmsQuery.query.queryEventByName,
        variables: {
            name,
        }
    }).then(async (response: any) => {
        const event = response.events?.[0]

        let eventId = event?.id
        let curatorAddress = network === "tezos" ? (event?.account.tezos || DEFAULT_CURATOR_TZ_ADDRESS) : 
                                                    (event?.account.ethereum || DEFAULT_CURATOR_ETH_ADDRESS)
        let curatorFee = event?.curatorFee

        setEventId(eventId);
        setCuratorAddress(curatorAddress);
        setCuratorFee(curatorFee);

        // Remove miliseconds and UTC timezone as we will add that later
        setStart && setStart(event.auctionStartDate.slice(0,-9));
        setEnd && setEnd(event.auctionEndDate.slice(0,-9))
        
    }).catch((error: any) => {
        console.log(error)
    })
}

export const TezosAuction = () => {

    // Connected Eth / Tezos addresses
    const tezosState = tezosStore()
    const ethereumState = ethereumStore()
    const notifications = notificationStore()

    const [events, setEvents] = useState<IEvent[]>([])
    const [selectedEvent, setSelectedEvent] = useState("")
    const [reservePrice, setReservePrice] = useState(1.0)
    const [start, setStart] = useState(new Date().toISOString().split('.')[0].slice(0,-3))
    const [end, setEnd] = useState(new Date().toISOString().split('.')[0].slice(0,-3))
    
    const [eventId, setEventId] = useState("")
    const [curatorAddress, setCuratorAddress] = useState(DEFAULT_CURATOR_TZ_ADDRESS)
    const [curatorFee, setCuratorFee] = useState(10)

    const [openAddAuction, setOpenAddAuction] = useState(false)
    const [contract, setContract] = useState("")
    const [tokenId, setTokenId] = useState("")
    const [tokensToDisplay, setTokensToDisplay] = useState<Tokens[] | undefined>([])
    
    const [page, setPage] = useState(1);
    //const filterOptions = ["token_id", "name", "fa2_address"]

    const { loading, error, data } = useQuery<TokensData, TokensVar>
    (GET_USER_TEZOS_NFTS, {
        variables: { tezosAddress: tezosState.address }
    });

    useEffect(() => {
        if (!tezosState.address) return
        
        // Load existing events
        getUpcomingEvents(setEvents)

        // Load the first 20 tokens
        setTokensToDisplay(data?.tokens)

    }, [, tezosState.address, data])

    // Callback function when pagination is clicked
    
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
    }

    const createAuction = async () => {
        
        if (!tezosState?.signer) {
            await waitSignerTezos()
        }
       
        else{

            const Tezos = tezosState.provider
    
            // Interact with contract     
            const OBJKT_ENGLISH_AUCTIONS_V2_CONTRACT_ADDRESS = "KT18p94vjkkHYY3nPmernmgVR7HdZFzE7NAk"                     
            const OBJKT_ENGLISH_AUCTIONS_V2_CONTRACT = await Tezos.contract.at(OBJKT_ENGLISH_AUCTIONS_V2_CONTRACT_ADDRESS)
            const TOKEN_CONTRACT = await Tezos.contract.at(contract);

            const notifID = notifications.addNotification({
                message: 'Adding token to auction',
                status: 'pending',
            })

            try {
                let royaltySplit: any[] = [];

                if (curatorFee > 0) {
                    if(curatorFee < 5) {
                        royaltySplit = [{
                            amount: (curatorFee * 100),
                            recipient: curatorAddress
                        }]   
                    }
                    else { 
                        royaltySplit = [{
                            amount: (curatorFee * 100)-250,
                            recipient: curatorAddress
                       }]  
                    }  
                }

                let list: any = [
                    {
                        kind: OpKind.TRANSACTION,
                        ...TOKEN_CONTRACT.methods.update_operators([
                            {
                                add_operator: {
                                    owner: tezosState.address,
                                    operator: OBJKT_ENGLISH_AUCTIONS_V2_CONTRACT_ADDRESS,
                                    token_id: parseInt(tokenId)
                                }
                            }
                        ]).toTransferParams(),
                        storageLimit: 310,
                        source: tezosState.address
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...OBJKT_ENGLISH_AUCTIONS_V2_CONTRACT.methods.create_auction(
                            contract, // Token Contract Address
                            parseInt(tokenId), // Token ID
                            "fa12", // currency type 
                            "KT1TjnZYs5CGLbmV6yuW169P8Pnr9BiVwwjz", // currency address
                            reservePrice*1000000, // reserve price in  micro tez
                            start+':00.000Z',
                            end+':00.000Z',
                            TEZOS_AUCTION_EXTENSION,
                            TEZOS_PRICE_INCREMENT,
                            royaltySplit
                        ).toTransferParams(),
                        storageLimit: 310,
                        source: tezosState.address
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...TOKEN_CONTRACT.methods.update_operators([
                            {
                                remove_operator: {
                                    owner: tezosState.address,
                                    operator: OBJKT_ENGLISH_AUCTIONS_V2_CONTRACT_ADDRESS,
                                    token_id: parseInt(tokenId)
                                }
                            }
                        ]).toTransferParams(),
                        storageLimit: 310,
                        source: tezosState.address
                    }
                ]


                let batch = Tezos.wallet.batch(list)
                let confirm = await batch.send()

                // Add token to specified event in the database
                const data = await addToken({
                    token: {
                        id: "",
                        tokenId: parseInt(tokenId),
                        cryptoNetwork: "tezos",
                        contract,
                        saleType: 'auction',
                        saleStart: start+':00Z',
                        saleEnd: end+':00Z'
                    },
                    type: 'add',
                    eventId: eventId
                })

                notifications.setNotificationMessage({
                    id: notifID,
                    message: 'Publishing Token',
                })


                
                await publishToken(data)

                notifications.resolve(notifID)

                return await confirm.confirmation(2)
                

            } catch (error) {
                if((error as Error).message.includes("ABORTED_ERROR")) {
                    
                    notifications.setNotificationMessage({
                        id: notifID,
                        message: 'Aborted by User',
                    })
        
                    notifications.reject(notifID)
        
                }
                
                else {
                    console.error(error)
                }
            }
        }
    }   

     // Display error if no Tezos Wallet connected
     if (!tezosState.address) {
        return (
            <Container>
                <div className={styles.message}>
                    Please connect your Tesoz wallet to continue.
                </div>
            </Container>
        )
    }

    if (loading) {
        console.log("Still fetching NFTS...")
    }
    
    if (error) {
        console.log("Error fetching NFTS...")
    }

    return (
        <Container>             
            <h1 style={{ marginBottom: '1em'}}>Create an Auction</h1>

            <Search options={["token_id", "name", "fa2_address"]}
                    placeholder="Search by token id, contract address or token description..."
                    allData={data?.tokens}
                    setNewData={setTokensToDisplay}
                    resetPage={setPage}
                    />
            
            { tokensToDisplay && 
                <>                    
                <Masonry columns={{ xs: 1, sm: 2, lg: 4 }}>
                    { tokensToDisplay.slice((page-1)*20, ((page-1)*20)+20).map(token => (
                        <CleanToken
                        network="tezos"
                        contract={token.fa2_address}
                        id={parseInt(token.token_id)}
                        key={token.fa2_address + token.token_id.toString()}

                        onClick={() => {
                            // set params required by OBJKT API 
                            setTokenId(token.token_id || "0")
                            setContract(token.fa2_address || "")
                            setOpenAddAuction(true)
                        }}>
                        </CleanToken>
                    ))
                    }
                </Masonry>

                <CustomPagination 
                    onChange={handlePageChange}
                    page={page}
                    itemsToDisplay={tokensToDisplay} 
                    itemAmountToDisplay={20} />
                </>
            }

            <Modal
                open={openAddAuction}
                onClose={() => {
                    setOpenAddAuction(false)

                    // Clear values
                    setStart(new Date().toISOString().split('.')[0].slice(0,-3))
                    setEnd(new Date().toISOString().split('.')[0].slice(0,-3))
                    setEventId("")
                    setCuratorAddress(DEFAULT_CURATOR_TZ_ADDRESS)
                    setCuratorFee(10)
                    setReservePrice(1.0)
                    setSelectedEvent("")
                }}
                title="Add token to auction"
                >

                <Modal.Wrapper key="event">
                    <Label>Event</Label>

                    <DropdownInput
                        list={(events.map((event: IEvent) => { return event.name }))}
                        value={selectedEvent}
                        onChange={(event) => {
                            getEventDetails("tezos", event, setEventId, setCuratorAddress, setCuratorFee, setStart, setEnd).then(() => {
                                setSelectedEvent(event)
                            })                            
                        }}
                    >
                    </DropdownInput>
                </Modal.Wrapper>

                <Modal.Spacer key="spacer-0"/>

                <Modal.Wrapper key="start">
                    <Label>Sale Start Date and Time (UTC)</Label>
                    <div className={styles.date__container}>
                        <DateInput
                            value={start.split('T')[0]}
                            onChange={(e) => {
                                setStart(e.target.value + 'T' + start.split('T')[1])
                            }}
                            placeholder="Select Start Date"
                            className={styles.picker__input}
                        />

                        <TimeInput
                            value={start.split('T')[1].split('+')[0]}
                            onChange={(e) => {
                                setStart(start.split('T')[0] + 'T' + e.target.value)
                            }}
                            className={styles.picker__input}
                        />
                    </div>
                </Modal.Wrapper>

                <Modal.Wrapper key="end">
                    <Label>Sale End Date and Time (UTC)</Label>
                    <div className={styles.date__container}>
                        <DateInput
                            value={end.split('T')[0]}
                            onChange={(e) => {
                                setEnd(e.target.value + 'T' + end.split('T')[1])
                            }}
                            placeholder="Select Start Date"
                            className={styles.picker__input}
                        />

                        <TimeInput
                            value={end.split('T')[1].split('+')[0]}
                            onChange={(e) => {
                                setEnd(end.split('T')[0] + 'T' + e.target.value)
                            }}
                            className={styles.picker__input}
                        />
                    </div>
                </Modal.Wrapper>

                <Modal.Wrapper key="price">
                <Label>Reserve price (XTZ):</Label>

                <NumberInput
                    value={reservePrice}
                    onChange={(e) => setReservePrice(parseFloat(e.target.value))}
                    placeholder={`eg. 1, 2, 3... XTZ`}

                    min={0.1}
                    step={0.1}
                />
                </Modal.Wrapper>

                <Modal.Spacer />

                <Modal.Wrapper key="curationFee">
                <Label>Curation Fee (in %):</Label>

                <NumberInput
                    value={curatorFee}
                    onChange={(e) => setCuratorFee(parseFloat(e.target.value))}
                    placeholder={`eg. 10, 15, 20%`}

                    min={0}
                    max={100}
                    step={1}
                />
                </Modal.Wrapper>

                <Modal.Spacer />

                <Modal.Wrapper>
                    <Button
                        onClick={createAuction}
                    > Confirm Auction
                    </Button>
                </Modal.Wrapper>
            </Modal>
        </Container>
    )
}
