import { useEffect } from "react";
import { Loader } from "../design-system";

const vanityLinks = require('../../constants/vanitylinks.json')


export function getVanityLink(key: string) {
    if (vanityLinks[key] !== null) {
        return vanityLinks[key]
    } else {
        return null
    }
}

export const VanityLink = () => {
    
    useEffect(() => {

        // get key from url
        const key = window.location.pathname.toLowerCase().split('/')[2]
        if (getVanityLink(key)) {
            window.location.href = getVanityLink(key)
        }
    }, []) 

    return (<Loader />)
}