import { Countdown, Label, LazyLoad, EndingCountdown } from '../design-system'
import styles from './styles.module.scss'
import { useEffect, useState } from 'react'
import { ethers } from "ethers"
import { cleanHash, getFxhashSwapData, shorten } from '../../utils/crypto'
import { IPFS_GATEWAY } from '../../constants'
import { FXHASH_CONTRACT_ADDRESS } from '../../constants'
import { getDateTime } from '../../utils/time'
import { findXTZArtistName } from '../../components/token/token'
import { getHistory, getAbProjectInfo } from "../../utils/crypto"

const physicalPrints = require('../../constants/physical-prints.json')

//const blacklistedEventIds = ['clgm1wcu3izhd0bmcd94cr3cb', 'clgm00x2fj1y30bl8d50i2wus', 'clglzz1n6j0ko0bmkk95i83fd', 'clglzym1jit560amk3h1vcz9z', 'clglzxyatj0hc0bmk5krqbpok', 'clglzvub2j1ot0bl8xqlf97co', 'clglzwyvxiszp0amkroopwu21']

export const getTokenInfo = ((id: number) => {
    const info = physicalPrints.find((entry: any) => entry.id === id)
    return info
})
export const DummyToken = ({
    id,
    to = "",
    metadata,
    swapdata,
    saleStart,
    saleEnd,
    saleType,
    sold,
    additionalInfo,
    artist_name,
    title,
    edition,
    token_price,
    dropType,
    dropDate,
    contract,
    cryptoNetwork
} : {
    id: number;
    to: string;
    metadata: any;
    swapdata?: any;
    saleStart?: string;
    saleEnd?: string;
    saleType?: string;
    sold: boolean;
    additionalInfo?: string;
    artist_name?: string;
    title?: string;
    edition?: string;
    token_price?: string;
    dropType?: string;
    dropDate?: string;
    contract?: string;
    cryptoNetwork: "ethereum" | "tezos",
}) => {
    const [ price, setPrice ] = useState<Number>(0);
    const [ balance, setBalance ] = useState<number>(0);
    const [ coverImage, setCoverImage ] = useState<string>("");
    const [ artist, setArtist ] = useState<string>("");
    const [ swapData, setSwapData ] = useState<any>();
    const [ bidData, setBidData ] = useState<any>();
    const [project, setProject] = useState<any>({})
    
    const [ availability, setAvailability ] = useState<Number>(0);
    const [ manifoldDetails, setManifoldDetails ] = useState<any>(undefined);

    useEffect(() => {
        const queryABTokens = async () => {
            if (saleType && saleType.toLowerCase().includes("exhibition")) {
                if (dropType == "Art Blocks Engine" && to != "https://vcanftgallery.verticalcrypto.art/") {
                    try {
                        const projectDetails: any = await getAbProjectInfo(parseInt(to.split('/')[6]))

                        // Reconstruct object to correct types
                        setProject({
                            artist: projectDetails.artist,
                            artistAddress: projectDetails.royaltyInfo.artistAddress,
                            currency: 'ETH',
                            description: projectDetails.description,
                            invocations: projectDetails.invocations.toNumber(),
                            license: projectDetails.license || null,
                            maxInvocations: projectDetails.maxInvocations,
                            projectName: projectDetails.collection_name.split(`by ${projectDetails.artist}`)[0],
                            royalties: projectDetails.royaltyInfo.royaltyFeeByID || 0,
                            library: JSON.parse(projectDetails.scriptJSON).type || null,
                            aspectRatio: JSON.parse(projectDetails.scriptJSON).aspectRatio || 1,
                            saletype: ethers.utils.formatEther(projectDetails.basePrice) == ethers.utils.formatEther(projectDetails.startPrice) ? 'fixed' : 'auction',
                            auctionDetails: {
                            startPrice: parseFloat(ethers.utils.formatEther(projectDetails.startPrice)),
                            basePrice: parseFloat(ethers.utils.formatEther(projectDetails.basePrice)),
                            timeInterval: projectDetails.priceDecayHalfLifeSeconds.toNumber()/60
                            },
                            price: parseFloat(ethers.utils.formatEther(projectDetails.tokenPriceInWei)),
                            startTime: getDateTime(projectDetails.timestampStart.toNumber()*1000),
                            startTimestamp: projectDetails.timestampStart.toNumber()*1000,
                            saleDuration: projectDetails.saleDuration ? projectDetails.saleDuration : null,
                            flex: projectDetails.flex,
                            endTimeStamp: (projectDetails.timestampStart.toNumber() + projectDetails.saleDuration)*1000,
                            projectbalance: (projectDetails.maxInvocations-projectDetails.invocations.toNumber())
                        })
                    } catch (error) {
                    console.error(error)
                    }
                }
            }
        }
        queryABTokens();
    }, [])
    
    useEffect(() => {
        const queryFxhashTokens = async () => {
            if (saleType && saleType.toLowerCase().includes("exhibition")) {
                //obtain bids
                if (dropType == "fx(hash)") {
                    try {
                        const swapdata: any = await getFxhashSwapData(getTokenInfo(id).fxhashId)
                        // Only set token availability when sale started, so its position is accurate
                        if(swapdata.pricingFixed && swapdata.pricingFixed.opensAt <= new Date().toISOString() ||  
                        swapdata.pricingDutchAuction && swapdata.pricingDutchAuction.opensAt <= new Date().toISOString()) {
                            
                            let reserveAmt = 0;

                            //obtain reserves
                            for (let i = 0; i < swapdata.reserves.length; i++) {
                                reserveAmt+= swapdata.reserves[i].amount;
                            }

                            let xavailability = swapdata.balance - reserveAmt
                            setAvailability(xavailability)
                        }

                        // set token availability to 0 if sale has not started so it's at the back
                        else {
                            let xavailability = 0
                            setAvailability(xavailability)
                        }

                        setSwapData(swapdata)
                        //if token has a fixed pricing, return this:
                        if (swapdata.pricingFixed) {
                            setPrice(swapdata.pricingFixed.price/1e6)
                        }

                        //if token is dutch auction
                        else if(swapdata.pricingDutchAuction) {
                            const { opensAt, levels, decrementDuration } = swapdata.pricingDutchAuction
                            // compute the time difference (in seconds) between opens and mint time
                            const timeDiff = (Date.now() - new Date(opensAt).getTime()) / 1000
                            // find the index of the level based on this time difference
                            const index = Math.min(
                            Math.floor(timeDiff / parseInt(decrementDuration)),
                            levels.length-1
                            )
                            // return corresponding level
                            setPrice(parseInt(levels[index])/1e6)
                        } 

                        let reserveAmt = 0;

                        //obtain reserves
                        for (let i = 0; i < swapdata.reserves.length; i++) {
                            reserveAmt+= swapdata.reserves[i].amount;
                        }

                        setBalance(swapdata.balance-reserveAmt)

    
                    } catch (error) {
                        console.log(error)
                    }
                }
            }
        }
        queryFxhashTokens();
    }, [])

    useEffect(() => {
        const queryTokenBids = async () => {
            if(dropType == "Auction"){
                //obtain bids
                if (contract) {
                    const history:any = await getHistory({
                        network: cryptoNetwork,
                        contract: contract,
                        id: parseInt(to.split('/')[6]),
                    })
                    setBidData(history)
                }
            }
        }
        queryTokenBids();
    }, [])

    useEffect(() => {

        // get event slug 
        //const eventId = window.location.pathname.split("/")[2]
        //console.log(eventId)
        const func = async () => {
            if (saleType && saleType.toLowerCase().includes("fxhash")) {
                // fxhash tokens
                if (id) {
                    try {
                        const swapdata: any = await getFxhashSwapData(id)

                        // Only set token availability when sale started, so its position is accurate
                        if(swapdata.pricingFixed && swapdata.pricingFixed.opensAt <= new Date().toISOString() ||  
                        swapdata.pricingDutchAuction && swapdata.pricingDutchAuction.opensAt <= new Date().toISOString()) {
                            
                            let reserveAmt = 0;

                            //obtain reserves
                            for (let i = 0; i < swapdata.reserves.length; i++) {
                                reserveAmt+= swapdata.reserves[i].amount;
                            }

                            let xavailability = swapdata.balance - reserveAmt
                            setAvailability(xavailability)
                        }

                        // set token availability to 0 if sale has not started so it's at the back
                        else {
                            let xavailability = 0
                            setAvailability(xavailability)
                        }

                        setSwapData(swapdata)
                        //if token has a fixed pricing, return this:
                        if (swapdata.pricingFixed) {
                            setPrice(swapdata.pricingFixed.price/1e6)
                        }

                        //if token is dutch auction
                        else if(swapdata.pricingDutchAuction) {
                            const { opensAt, levels, decrementDuration } = swapdata.pricingDutchAuction
                            // compute the time difference (in seconds) between opens and mint time
                            const timeDiff = (Date.now() - new Date(opensAt).getTime()) / 1000
                            // find the index of the level based on this time difference
                            const index = Math.min(
                            Math.floor(timeDiff / parseInt(decrementDuration)),
                            levels.length-1
                            )

                            // return corresponding level
                            setPrice(parseInt(levels[index])/1e6)
                        } 

                        let reserveAmt = 0;

                        //obtain reserves
                        for (let i = 0; i < swapdata.reserves.length; i++) {
                            reserveAmt+= swapdata.reserves[i].amount;
                        }

                        setBalance(swapdata.balance-reserveAmt)

                        setArtist(swapdata.author.id)
                        setCoverImage(IPFS_GATEWAY + cleanHash(swapdata.thumbnailUri))
                    } catch (error) {
                        console.log(error)
                    }
                }

                // physical print tokens
                else {
                    let xavailability = 0;
                    setAvailability(xavailability)
                }
            }

            else if (!saleType && to.toLowerCase().includes('gallery.manifold.xyz/listing?listingid')) {
                // Manifold pre-minted tokens
                const listingId = to.toLowerCase().split('listingid=')[1]
                const listingData = await fetch('https://marketplace.api.manifoldxyz.dev/listing/0x3a3548e060be10c2614d0a4cb0c03cc9093fd799/' + listingId).then(data => data.json())
                // only do this for FIXED_PRICE tokens (type 2)
                if (listingData.details.type_ == 2) {
                    setManifoldDetails({
                        available: listingData?.details?.totalAvailable - listingData?.totalSold
                    })
                }
            }
        }
        func();
    }, [])

    if (saleType && saleType.toLowerCase().includes("exhibition")) {
        return (
                <div className={styles.exhibition__token} onClick={() => null}>
                    <div className={styles.token__devider}>
                        <div className={styles.exhibition__edit__container}>
                            <img
                                //src="/token_covers/KT1AAxomZ6cxN5zYj2efG1nQyM1ggab4e216_1.png"
                                alt="token"
                                className={styles.exhibition__token__cover}
                                src={saleType && saleType.toLowerCase().includes("fxhash") ? ("/token_covers/fxhash_" + id  + '.png') : getTokenInfo(id).coverUrl}
                            />
                        </div>
                    </div>
                    <div className={`${styles.token__devider} ${styles.dummy__token__info}`}>
                        <div className={styles.artist__title__box}>
                            <div className={styles.artist__info__box__name}>
                                { artist_name }
                            </div>
                            <div className={styles.artist__info__box__title}>
                                { title }
                            </div>
                        </div>
                        <br />
                        <br />

                        <div className={styles.token__info__box}>
                            <div className={styles.artist__info__box__two}>
                                {dropType == "Auction" ? (
                                    <>
                                    {bidData && (bidData.bidEvents.length || bidData.bids?.length > 0) ? (
                                        <>
                                        {cryptoNetwork === "ethereum" ? (
                                            (bidData.status === "ENDED" || (bidData.expiresAt &&  new Date(bidData.expiresAt) <= new Date())) ? "Sold For " :
                                            bidData.bidEvents?.length === 0 ? "Reserve Price " :
                                            bidData.bidEvents?.length > 0 && <>Current Bid </>
                                        ) : (
                                            bidData.status === "concluded" ? 
                                            (bidData.highest_bid === null ? "" : "Sold For") :
                                            bidData.bids?.length === 0 ? "Reserve Price " :
                                            bidData.bids?.length > 0 && <>Current Bid </>
                                        )}
                                        {cryptoNetwork === "ethereum" ? (
                                            (bidData.status === "ENDED" || (bidData.expiresAt && new Date(bidData.expiresAt) <= new Date())) ? bidData.bidEvents[0].value :
                                            bidData.bidEvents?.length === 0 ? bidData.reservePrice :
                                            bidData.bidEvents?.length > 0 && bidData.bidEvents[0].value) / 1e18 + " ETH"
                                         : (
                                            (bidData.highest_bid === null && bidData.status === "concluded") ? "In the auction house" :
                                            (bidData.status === "concluded" ? bidData.highest_bid :
                                            bidData.bids.length === 0 ? bidData.reserve :
                                            bidData.bids.length > 0 && bidData.highest_bid) / 1e6 + " XTZ")
                                        }
                                        </>
                                    ):(
                                        <>
                                        {token_price}
                                        </>
                                    )}
                                    </>
                                ):(
                                    <>
                                    {token_price}
                                    </>
                                )}
                            </div>
                            <div className={styles.artist__info__box__two}>
                                { edition }
                            </div>
                            <div className={styles.artist__info__box__two}>
                                { dropType }
                            </div>
                            {dropType == "Auction" && (
                                <>
                                {bidData && (bidData.bidEvents.length || bidData.bids?.length > 0) ? (
                                    <>
                                    {bidData.expiresAt &&  new Date(bidData.expiresAt) >= new Date() ? (
                                        <>
                                        <div style={{ paddingTop: "2rem" }} className={styles.artist__info__box__two}>
                                            <Countdown message="Sale Ends in" target={new Date(bidData.expiresAt)} className={styles.ab__dummy__countdown}/>
                                        </div> 
                                        </>
                                    ):(
                                        <>
                                        <div className={styles.artist__info__box__two}>
                                            SALE ENDED
                                        </div>
                                        </>
                                    )}
                                    </>
                                ):(
                                    <>
                                    </>
                                )}
                                </>
                            )}
                        </div>
                        <br />
                        { to == "https://vcanftgallery.verticalcrypto.art/" ? (
                            <>
                            <div className={styles.artist__info__box__two}>
                                {dropDate}
                            </div>
                            {saleStart && (
                                <>
                                {new Date(saleStart) >= new Date() ? (
                                    <>
                                        <div style={{ paddingTop: "2rem" }} className={styles.artist__info__box__two}>
                                            <Countdown message="Sale Starts in" target={new Date(saleStart)} className={styles.ab__dummy__countdown}/>
                                        </div> 
                                    </>
                                ):(
                                    null
                                )}
                                </>
                            )}
                            </>
                        ):(
                            <>
                            { dropType == "Auction" || dropType == "Fixed-Price" || dropType == "Reserved" || dropType == "Limited edition drop" ? (
                                
                                <div  style={{ paddingBottom: "1rem" }}>
                                    <a href={to} className={styles.dummy__buy__button}>
                                        View
                                    </a>
                                    
                                    {saleStart && saleEnd && (
                                        <>
                                        {new Date(saleStart) >= new Date() ? (
                                            <>
                                                <div style={{ paddingTop: "2rem" }} className={styles.artist__info__box__two}>
                                                    <Countdown message="Sale Starts in" target={new Date(saleStart)} className={styles.ab__dummy__countdown}/>
                                                </div> 
                                            </>
                                        ):(
                                            <>
                                            {new Date(saleEnd) >= new Date() ? (
                                                <div style={{ paddingTop: "2rem" }} className={styles.artist__info__box__two}>
                                                    <Countdown message="Sale Ends in" target={new Date(saleEnd)} className={styles.ab__dummy__countdown}/>
                                                </div> 
                                            ):(
                                                <>
                                                <div className={styles.artist__info__box__two}>
                                                    SALE ENDED
                                                </div>
                                                </>
                                            )}
                                            </>
                                        )}
                                        </>
                                    )}
                                </div>
                            ):(
                                null
                            )}
                            
                            
                            { dropType == "Art Blocks Engine" ? (
                                <>
                                {project && (
                                    <>
                                    { project.startTimestamp > new Date().getTime() ? (
                                        <>
                                        <div className={styles.artist__info__box__two}>
                                            <Countdown message="Sale starts in" target={new Date(project.startTimestamp)} className={styles.ab__dummy__countdown}/>
                                        </div> 
                                        
                                        <div  style={{ paddingBottom: "1rem" }}>
                                            <a href={to} className={styles.dummy__buy__button}>
                                                View
                                            </a>
                                        </div>
                                        </>
                                    ):(
                                        <>
                                        { (project.startTimestamp && project.saleDuration && project.saleDuration > 0 && 
                                            (new Date().getTime() < project.endTimeStamp) && 
                                            (new Date().getTime() >= Number(project.startTimestamp)) && project.projectbalance > 0 ) &&
                                             
                                             <div className={styles.token__info__box}>
                                                <div className={styles.artist__info__box__two}>
                                                Sale ends in
                                                </div>
                                                <div style={{ marginLeft: "-0.5rem" }} className={styles.artist__info__box__two}>
                                                    <Countdown message="" target={new Date(project.endTimeStamp)} className={styles.ab__dummy__countdown}/>
                                                </div> 
                                             </div>
                                         }
                                        {
                                        (project.saleDuration > 0 &&
                                            (new Date().getTime() >= Number(project.startTimestamp + project.saleDuration*1000))) ? 
                                        (
                                            <>
                                                <div className={styles.token__info__box}>
                                                    <div style={{ paddingBottom: "1rem" }} className={styles.artist__info__box__two}>
                                                            {project.invocations === undefined ? (
                                                                <>
                                                                loading...
                                                                </>
                                                            ):(
                                                                <>
                                                                { project.invocations && project.maxInvocations == project.invocations ? "SOLD OUT" : (`${project.invocations} minted`) }
                                                                </>
                                                            )}
                                                    </div>
                                                    <div style={{ paddingBottom: "1rem" }} className={styles.artist__info__box__two}>
                                                        SALE ENDED
                                                    </div>
                                                    <div  style={{ paddingBottom: "1rem" }} >
                                                        <a href={to} className={styles.dummy__buy__button}>
                                                            View
                                                        </a>
                                                    </div>
                                                </div>
                                            </>
                                        ):
                                        (
                                            <div className={styles.token__info__box}>
                                                <div style={{ paddingBottom: "1rem" }} className={styles.artist__info__box__two}>
                                                        {project.invocations === undefined ? (
                                                                <>
                                                                loading...
                                                                </>
                                                            ):(
                                                                <>
                                                                { project.invocations && project.maxInvocations == project.invocations ? "SOLD OUT" : (`${project.invocations} minted`) }
                                                                </>
                                                            )}
                                                </div>
                                                
                                                <div  style={{ paddingBottom: "1rem" }} >
                                                    <a href={to} className={styles.dummy__buy__button}>
                                                    { project.invocations && project.maxInvocations == project.invocations ? "View" : ("View & Collect") }
                                                    </a>
                                                </div>
                                            </div>
                                        )}

                                        </>
                                    )}
                                    </>
                                )}
                                </>
                            ):(
                                null
                            )}
                            { dropType == "fx(hash)" ? (
                                <div className={styles.token__info__box}>
                                    {/** if not sold out */}
                                    { balance > 0 ? (
                                    /** Display fxhash token info if swap sale is ongoing **/
                                    
                                        <div className={styles.artist__info__box__two}>
                                            {swapData.supply} - {balance} left
                                            <div className={styles.artist__info__box__two}>
                                            </div>
                                            {swapData.pricingFixed && swapData.pricingFixed.opensAt && (
                                                <>    
                                                    {swapData.pricingFixed.opensAt > new Date().toISOString() ? (
                                                        <div className={styles.artist__info__box__two}>
                                                            <Countdown target={new Date(swapData.pricingFixed.opensAt)} className={styles.ab__dummy__countdown}/>
                                                        </div> 
                                                    ):(
                                                        null
                                                    )}
                                                </>
                                            )}
                                            {swapData.pricingDutchAuction && swapData.pricingDutchAuction.opensAt && (
                                                <>    
                                                    {swapData.pricingDutchAuction.opensAt > new Date().toISOString() ? (
                                                        <div className={styles.artist__info__box__two}>
                                                            <Countdown target={new Date(swapData.pricingDutchAuction.opensAt)} className={styles.ab__dummy__countdown}/>
                                                        </div> 
                                                    ):(
                                                        null
                                                    )}
                                                </>
                                            )}
                                            
                                            <div className={styles.artist__info__box__two}>
                                                <a href={to} className={styles.dummy__buy__button}>
                                                View & Collect
                                                </a>
                                            </div>
                                        </div>
                                    ):(
                                    <div className={styles.token__info__box}>
                                        <div  style={{ paddingBottom: "1rem" }} className={styles.artist__info__box__two}>
                                            SOLD OUT
                                        </div>
                                        <div  style={{ paddingBottom: "1rem" }} >

                                        <a href={to} className={styles.dummy__buy__button}>
                                            View
                                        </a>
                                        </div>
                                    </div>
                                    )}
                                </div>
                            ):(
                                null
                            )}
                            </>
                        )}
                    </div>
                </div>
        )
    }
    if (saleType && saleType.toLowerCase().includes("sub-token")) {
        return (
                
            <a href={to} >
            <div className={styles.token} onClick={() => null}>
                <div className={styles.edit__container}>
                    <img
                        //src="/token_covers/KT1AAxomZ6cxN5zYj2efG1nQyM1ggab4e216_1.png"
                        alt="token"
                        className={styles.event__cover}
                        src={saleType && saleType.toLowerCase().includes("fxhash") ? ("/token_covers/fxhash_" + id  + '.png') : getTokenInfo(id).coverUrl}
                    />
                </div>
                <div className={styles.artist__name_event_view}>                
                    { saleType && saleType.toLowerCase().includes("fxhash") ? findXTZArtistName(artist) : getTokenInfo(id).artist }
                </div>
                <div className={styles.label__container}>
                    <Label>
                        { additionalInfo }
                    </Label>
                </div>
                <div className={styles.label__container}>
                    <Label>
                        { edition }
                    </Label>
                    { to == "https://vcanftgallery.verticalcrypto.art/" ? (
                        <>
                            <Label>
                                { dropDate }
                            </Label>
                        </>
                    ):(
                        <>
                        <a href={to} className={styles.dummy__buy__button}>
                            View & Collect
                        </a>
                        </>
                    )
                    }
                </div>
                

            </div>
        </a>
        )
    }
    else {

        return (
            <a href={to}>
                <div className={styles.token} onClick={() => null}>
                    <div className={styles.edit__container}>
                        <img
                            //src="/token_covers/KT1AAxomZ6cxN5zYj2efG1nQyM1ggab4e216_1.png"
                            alt="token"
                            className={styles.event__cover}
                            src={saleType && saleType.toLowerCase().includes("fxhash") ? ("/token_covers/fxhash_" + id  + '.png') : getTokenInfo(id).coverUrl}
                        />
                    </div>
                    <div className={styles.artist__name_event_view}>                
                        { saleType && saleType.toLowerCase().includes("fxhash") ? findXTZArtistName(artist) : getTokenInfo(id).artist }
                    </div>
                    { (!saleType && getTokenInfo(id).print || saleType?.toLowerCase().includes("fxhash")) ? (
                      <Label>
                        <div className={styles.label__truncated}>{ getTokenInfo(id)?.print || swapData?.name }</div> 
                      </Label>
                    ): (
                    <div className={styles.label__container}>
                        <Label>
                            { additionalInfo }
                        </Label>
                    </div>
                    )}

                    {/** Display info for Physical PRINT tokens or 
                     * Any Manifold/Foundation/Superrare tokens
                     * Only show buy button when start date < current date
                     * Otherwise it will display an Available on xxx banner (see below).
                     * Configure details in  constants/physical-print.jsoon 
                     * */}
                    { (!saleType && getTokenInfo(id).type.toLowerCase().includes("print") ||
                      !saleType && getTokenInfo(id).startDate < new Date().toISOString()) && (
                    <div className={styles.auction__history}>
                        <div className={styles.info__container}>
                            <div>
                                { (!getTokenInfo(id).price.toLowerCase().includes('sold out') &&
                                  (!manifoldDetails || manifoldDetails?.available !== 0)) &&
                                <Label>
                                    On Sale
                                </Label>
                                }
                                {sold ? (
                                    <>
                                    <div className={styles.event__title} style={{ paddingTop: 0, paddingBottom: '1rem' }}>
                                        Sold Out
                                    </div>
                                    </>
                                ):(
                                    <>
                                    <div className={styles.event__title} style={{ paddingTop: 0, paddingBottom: '1rem' }}>
                                        { (manifoldDetails && manifoldDetails?.available == 0) ? 'Sold Out' : getTokenInfo(id).price }
                                    </div>
                                    </>
                                )}

                            </div>
                            { (!getTokenInfo(id).price.toLowerCase().includes('sold out') && (!manifoldDetails || manifoldDetails?.available !== 0)) &&
                            <a href={to} className={styles.buy__button}>
                                    Buy
                            </a>
                            } 
                        </div>
                            {getTokenInfo(id).endDate > new Date().toISOString() &&
                            (<div style={{ width: '100%' }}>
                                <Countdown target={new Date(getTokenInfo(id).endDate)} 
                                className={styles.countdown} />        
                            </div>)
                            }
                    </div>
                    )}
                    {/** Display info for fxhash tokens */}
                    { saleType && saleType.toLowerCase().includes("fxhash") && (
                    <div className={styles.auction__history}>
                        {/** if not sold out */}
                        { balance > 0 ? (
                        /** if swapped before swap sale start date, display available date instead **/
                        swapData.pricingFixed && swapData.pricingFixed.opensAt > new Date().toISOString() || 
                        swapData.pricingDutchAuction && swapData.pricingDutchAuction.opensAt > new Date().toISOString() ? 
                        (
                            <div className={styles.info__container}>
                            <div>
                                <div className={styles.event__title} style={{ paddingTop: 0 }}>
                                Available on { swapData.pricingFixed && getDateTime(swapData.pricingFixed.opensAt) ||  
                                                swapData.pricingDutchAuction && getDateTime(swapData.pricingDutchAuction.opensAt) }
                                </div>
                            </div>
                            </div>
                        ):(
                        /** Display fxhash token info if swap sale is ongoing **/
                        <div className={styles.info__container}>
                            <div>
                                <Label>
                                    Edition of {swapData.supply} - {balance} left
                                </Label>
                                <div className={styles.event__title} style={{ paddingTop: 0 }}>
                                    {price} XTZ
                                </div>
                            </div>
                            <a href={to} className={styles.buy__button}>
                                Collect
                            </a> 
                        </div>
                        )
                        ):(
                        <div className={styles.info__container}>
                            <div className={styles.event__title} style={{ paddingTop: 0 }}>
                                Sold Out
                            </div>
                        </div>
                        )}
                    </div>
                    )}
                    {/** Display info for upcoming tokens -- Tokens that are not ready yet 
                     * For all dummy tokens - manifold/superrare/foundation, set type as upcoming
                     * Or give a start date later than current date to display this 
                    */}
                    { (!saleType && getTokenInfo(id).type.toLowerCase().includes("upcoming") || 
                      !saleType && getTokenInfo(id).startDate > new Date().toISOString())  && (
                    <div className={styles.auction__history}>
                        <div className={styles.info__container}>
                            <div className={styles.event__title} style={{ paddingTop: 0 }}>
                                Available on { getDateTime(getTokenInfo(id).startDate) }
                            </div>
                        </div>
                    </div>
                    )}
    
                </div>
            </a>
        )
    }
}
