import { tezosStore } from "../../state/crypto/tezosStore"
import { ethereumStore } from "../../state/crypto/ethereumStore"
import { connectMetamask } from "../../libs/crypto/ethereum"
import { connectBeacon } from "../../libs/crypto/tezos"
import { disconnectAll } from "../../libs/crypto/crypto"

import { Button, Label } from "../design-system/"
import { WalletButton } from "./components/wallet-button"

import metamask from "./logos/metamask-fox.svg"
import beacon from "./logos/beacon.svg"

import logoStyles from "./logos.module.scss"
import styles from "./styles.module.scss"


export const ConnectWallet = () => {
    const ethereumState = ethereumStore()
    const tezosState = tezosStore()

    return (
        <div className={styles.buttons__container} >
            <div className={styles.inner__buttons__container}>
                <Label center className={styles.connect__label}>
                    Connect Wallets
                </Label>

                <WalletButton
                    onClick={() => {
                        connectMetamask(window)
                    }}
                    logo={metamask}
                    className={logoStyles.metamask}
                    disabled={ethereumState.address !== ''}
                >
                    Metamask

                    <div className={logoStyles.network__text}>
                        Ethereum
                    </div>
                </WalletButton>
                <WalletButton
                    onClick={() => {
                        connectBeacon()
                    }}
                    logo={beacon}
                    className={logoStyles.beacon}
                    disabled={tezosState.address !== ''}
                >
                    Beacon

                    <div className={logoStyles.network__text}>
                        Tezos
                    </div>
                </WalletButton>
                <Button
                    onClick={() => {
                        disconnectAll()    
                    }}
                    className={styles.log__out}
                >
                    Disconnect
                </Button>
            </div>
        </div>
    )
}