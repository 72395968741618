const axios = require('axios').default

export const upload = async ({
    file
}: {
    file: File,
}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const formData = new FormData()
            formData.append("file", file)
        
            const res = await axios.post(
                'https://api.pinata.cloud/pinning/pinFileToIPFS',
                formData,
                {
                    maxContentLength: "Infinity",
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "pinata_api_key": process.env.REACT_APP_PINATA_KEY,
                        "pinata_secret_api_key": process.env.REACT_APP_PINATA_SECRET,
                    },
                }
            )
        
            resolve(res.data)    
        } catch (error) {
            reject(error)
        }
    })
}