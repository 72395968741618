import { ethereumStore, getSigner, getProvider} from "../../../state/crypto/ethereumStore"
import { tezosStore } from "../../../state/crypto/tezosStore"
import { notificationStore } from "../../../state/global/notificationStore"
import { useEffect } from "react"
import { getUpcomingEvents } from "../swap"
import { VCA_ETH_MINT_ADDRESS, DEFAULT_CURATOR_ETH_ADDRESS } from "../../../constants"
import { addToken, publishToken } from '../../admin/manage-tokens/tokens'
import { getMintedEthTokens } from "../../../utils/crypto"
import { useState } from "react"
import { IEvent } from "../../admin/manage-events"
import { Container, DropdownInput, Label, Modal, NumberInput, Button, CustomPagination, TimeInput, DateInput } from "../../../components/design-system"
import { Masonry } from "@mui/lab"
import { CleanToken } from "../../../components/token/cleantoken"
import { getEventDetails } from "./tezos-auction"
import { ethers } from "ethers";
import { getAbi } from "../../../utils/crypto"

import styles from './styles.module.scss'
import { waitSignerEthereum } from "../../../libs/crypto/crypto"

interface Tokens {
    previewURI: string;
    tokenId: string;
    name: string;
}

export const EthAuction = () => {
    // Connected Eth / Tezos addresses
    const tezosState = tezosStore()
    const ethereumState = ethereumStore()
    const notifications = notificationStore()

    const [events, setEvents] = useState<IEvent[]>([])
    const [selectedEvent, setSelectedEvent] = useState("")
    const [reservePrice, setReservePrice] = useState(1.0)
    const [start, setStart] = useState(new Date().toISOString().split('.')[0].slice(0,-3))
    const [duration, setDuration] = useState(24)
    
    const [eventId, setEventId] = useState("")
    const [curatorAddress, setCuratorAddress] = useState(DEFAULT_CURATOR_ETH_ADDRESS)
    const [curatorFee, setCuratorFee] = useState(10)

    const [tokensToDisplay, setTokensToDisplay] = useState<Tokens[] | []>([])
    
    const [page, setPage] = useState(1);
    const [openAddAuction, setOpenAddAuction] = useState(false)
    const [tokenId, setTokenId] = useState("")



    useEffect(() => {
        
        if (!ethereumState.address) return

        const getEthTokens = async() => {
            const tokens = await getMintedEthTokens(ethereumState.address)
            setTokensToDisplay(tokens)
        }
        
        // Load existing events
        getUpcomingEvents(setEvents)
        

        // Load the first 20 tokens
        getEthTokens()

    }, [, ethereumState.address])

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
    }

    // Display error if no eth wallet is connected
    if (!ethereumState.address) {
        return (
            <Container>
                <div className={styles.message}>
                    Please connect your Ethereum wallet to continue.
                </div>
            </Container>
        )
    }

    const createAuction = async () => {

        const signer = getSigner()
        const provider = getProvider()

        const notifID = notifications.addNotification({
            message: 'Adding token to auction',
            status: 'pending',
        })

        
        if (!signer) {
            await waitSignerEthereum(window)
        }
       
        else{ 
            try {

                const vcaAbi = await getAbi("0xF4398a4d8bf5e267abdA3D62C47F8eF5f43e8c34")
                const vca721Contract = new ethers.Contract("0xF4398a4d8bf5e267abdA3D62C47F8eF5f43e8c34", vcaAbi, signer)

                const zoraAuctionAbi = await getAbi("0xe468ce99444174bd3bbbed09209577d25d1ad673")
                const zoraContract = new ethers.Contract("0xe468ce99444174bd3bbbed09209577d25d1ad673", zoraAuctionAbi, signer)
                    
                try {

                    const isApproved = await vca721Contract.isApprovedForAll(ethereumState.address, "0xe468ce99444174bd3bbbed09209577d25d1ad673");

                    if (!isApproved) {
                      await vca721Contract.setApprovalForAll("0xe468ce99444174bd3bbbed09209577d25d1ad673", true)
                    }

                    // Create auction 
                    let txn = await zoraContract.createAuction(
                                        tokenId,
                                        VCA_ETH_MINT_ADDRESS,
                                        duration*3600, // duration is stored in seconds
                                        ethers.utils.parseEther((reservePrice).toString()),
                                        curatorAddress,
                                        curatorFee,
                                        "0x0000000000000000000000000000000000000000"
                                    )
                    
                    await provider.waitForTransaction(txn.hash);

                    // Add token to specified event in the database
                    const data = await addToken({
                        token: {
                            id: "",
                            tokenId: parseInt(tokenId),
                            cryptoNetwork: "ethereum",
                            contract: VCA_ETH_MINT_ADDRESS.toLowerCase(),
                            saleType: 'auction',
                            saleStart: start+':00Z',
                        },
                        type: 'add',
                        eventId: eventId
                    })

                    notifications.setNotificationMessage({
                        id: notifID,
                        message: 'Publishing Token',
                    })


                    await publishToken(data)

                    notifications.resolve(notifID)
                    
                } catch (error) {
                    console.error(error)
                }
                
            } catch (error) {
                console.error(error)
            }



        }
    }

    return (
        <Container>             
            <h1 style={{ marginBottom: '1em'}}>Create an Auction</h1>
            
            { tokensToDisplay && 
                <>                    
                <Masonry columns={{ xs: 1, sm: 2, lg: 4 }}>
                    { tokensToDisplay.slice((page-1)*20, ((page-1)*20)+20).map(token => (
                        <CleanToken
                        network="ethereum"
                        contract={VCA_ETH_MINT_ADDRESS}
                        id={parseInt(token.tokenId)}
                        key={VCA_ETH_MINT_ADDRESS + token.tokenId.toString()}

                        onClick={() => {
                            // set params required by OBJKT API 
                            setTokenId(token.tokenId)
                            setOpenAddAuction(true)
                        }}>
                        </CleanToken>
                    ))
                    }
                </Masonry>

                <CustomPagination 
                    onChange={handlePageChange}
                    page={page}
                    itemsToDisplay={tokensToDisplay} 
                    itemAmountToDisplay={20} />
                </>
            }

            <Modal
                open={openAddAuction}
                onClose={() => {
                    setOpenAddAuction(false)

                    // Clear values
                    setStart(new Date().toISOString().split('.')[0].slice(0,-3))
                    setDuration(24)
                    setEventId("")
                    setCuratorAddress(DEFAULT_CURATOR_ETH_ADDRESS)
                    setCuratorFee(10)
                    setReservePrice(1.0)
                    setSelectedEvent("")
                }}
                title="Add token to auction"
                >

                <Modal.Wrapper key="event">
                    <Label>Event</Label>

                    <DropdownInput
                        list={(events.map((event: IEvent) => { return event.name }))}
                        value={selectedEvent}
                        onChange={(event) => {
                            getEventDetails("ethereum", event, setEventId, setCuratorAddress, setCuratorFee, setStart).then(() => {
                                setSelectedEvent(event)
                            })                            
                        }}
                    >
                    </DropdownInput>
                </Modal.Wrapper>

                <Modal.Spacer key="spacer-0"/>

                <Modal.Wrapper key="start">
                    <Label>Sale Start Date and Time (UTC)</Label>
                    <div className={styles.date__container}>
                        <DateInput
                            value={start.split('T')[0]}
                            onChange={(e) => {
                                setStart(e.target.value + 'T' + start.split('T')[1])
                            }}
                            placeholder="Select Start Date"
                            className={styles.picker__input}
                        />

                        <TimeInput
                            value={start.split('T')[1].split('+')[0]}
                            onChange={(e) => {
                                setStart(start.split('T')[0] + 'T' + e.target.value)
                            }}
                            className={styles.picker__input}
                        />
                    </div>
                </Modal.Wrapper>

                <Modal.Spacer />

                <Modal.Wrapper key="duration">
                <Label>Duration of auction (in hours):</Label>

                <NumberInput
                    value={duration}
                    onChange={(e) => setDuration(parseInt(e.target.value))}
                    placeholder={`eg. 1, 24, 48, etc.`}

                    min={1}
                    step={10}
                />
                </Modal.Wrapper>

                <Modal.Wrapper key="price">
                <Label>Reserve price (ETH):</Label>

                <NumberInput
                    value={reservePrice}
                    onChange={(e) => setReservePrice(parseFloat(e.target.value))}
                    placeholder={`eg. 0.1, 0.2, 0.3`}

                    min={0.1}
                    step={0.1}
                />
                </Modal.Wrapper>

                <Modal.Spacer />

                <Modal.Wrapper key="curationFee">
                <Label>Curation Fee (in %):</Label>

                <NumberInput
                    value={curatorFee}
                    onChange={(e) => setCuratorFee(parseFloat(e.target.value))}
                    placeholder={`eg. 10, 15, 20%`}

                    min={0}
                    max={100}
                    step={1}
                />
                </Modal.Wrapper>

                <Modal.Spacer />

                <Modal.Wrapper>
                    <Button
                        onClick={createAuction}
                    > Confirm Auction
                    </Button>
                </Modal.Wrapper>
            </Modal>
        </Container>
    )
}