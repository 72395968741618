import { accounts } from "./users"
import { events } from "./events"
import { tokens } from "./tokens"
import { swaps } from "./swaps"
import { projects } from "./projects"
import { bundles } from "./bundles"
import { collections } from "./collections"
import { addresses } from "./addresses"

const query = {
    ...accounts.query,
    ...events.query,
    ...tokens.query,
    ...swaps.query,
    ...projects.query,
    ...bundles.query,
    ...collections.query,
    ...addresses.query
}

const mutation = {
    ...accounts.mutation,
    ...events.mutation,
    ...tokens.mutation,
    ...swaps.mutation,
    ...projects.mutation,
    ...collections.mutation,
    ...addresses.mutation
}

export const gql = {
    query,
    mutation
}
