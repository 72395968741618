import { ARTBLOCKS_FLEX_CONTRACT_ADDRESS, ARTBLOCKS_GENERATOR_ADDRESS, ARTBLOCKS_IMAGE_PREVIEW } from "../../constants"
import { useEffect } from "react"
import { useState } from "react";
import { Container } from "@mui/material";
import { Loader } from "../../components/design-system";

const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const coreABI = require("../../constants/ab-core-abi.json");

// url /mint/live/ab_contract_address/project_id

export const LiveMintPage = () => {

    const [aspectRatio, setAspectRatio] = useState<number>(1.0)
    const [projectId, setProjectId] = useState<number>(0)
    const [tokenId, setTokenId] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {

        // check if AB contract address
        const contractAddress = window.location.pathname.split("/")[3]

        if (contractAddress.toLowerCase() == ARTBLOCKS_FLEX_CONTRACT_ADDRESS.toLowerCase()) {

            //retrieve project id
            const _projectId = window.location.pathname.split("/")[4]
            const web3 = createAlchemyWeb3(process.env.REACT_APP_ALCHEMY_TEST_API_KEY);
            const abCoreContract = new web3.eth.Contract(coreABI, ARTBLOCKS_FLEX_CONTRACT_ADDRESS);

            (async() => {
                const scriptInfo = await abCoreContract.methods.projectScriptInfo(Number(_projectId)).call(); 
                setAspectRatio(JSON.parse(scriptInfo.scriptJSON).aspectRatio || 1)
                setProjectId(Number(_projectId))
            })();
        }
    }, [])

    useEffect(() => {
        
        const contractAddress = window.location.pathname.split("/")[3]
        if (contractAddress.toLowerCase() == ARTBLOCKS_FLEX_CONTRACT_ADDRESS.toLowerCase()) {

            const web3 = createAlchemyWeb3(process.env.REACT_APP_ALCHEMY_TEST_API_KEY);
            const abCoreContract = new web3.eth.Contract(coreABI, ARTBLOCKS_FLEX_CONTRACT_ADDRESS);


            const interval = setInterval(async() => {

                const tokenInfo = await abCoreContract.methods.projectTokenInfo(projectId).call();

                if (tokenId != Number(tokenInfo.invocations-1)) {
                    setTokenId(Number(tokenInfo.invocations-1))
                }

                setLoading(false)
      
            }, 30000);  
          
    
            return () => clearInterval(interval);
        }
    }, [aspectRatio])
    
    if (loading) {
        return (
            <Container>
                <Loader />
            </Container>
        )
    }

    return (
      <Container>
        <img
        src={ARTBLOCKS_IMAGE_PREVIEW + projectId.toString() + tokenId.toString().padStart(6, "0")+'.png'}
        style={{
            display: 'block',
            margin: '0 auto',
            height: `80vh`,
            width: `calc(80vh * ${aspectRatio})`
        }}
        />
      </Container>
    )
}