import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { ConnectWallet } from "../../components/connect-wallet"

import { ethereumStore } from "../../state/crypto/ethereumStore"
import { tezosStore } from "../../state/crypto/tezosStore"

export const LogIn = () => {
    const ethereumState = ethereumStore()
    const tezosState = tezosStore()
    
    const navigate = useNavigate()

    const params = new URLSearchParams(window.location.search)

    const requirements = params.get('req')
    const redirect: any = params.get("redirect")

    useEffect(() => {
        if (!requirements) return

        if (requirements === 'eth' && ethereumState.address || requirements === 'xtz' && tezosState.address || requirements === 'all' && (ethereumState.address && tezosState.address) || requirements === 'any' && (ethereumState.address || tezosState.address)) {
            navigate(redirect, { replace: true })
        }
    }, [ethereumState.address, tezosState.address])
    
    return (
        <ConnectWallet />
    )
}