import { IPFS_GATEWAY } from '../../constants'
import { Label, LazyLoad } from '../design-system'
import styles from './styles.module.scss'
import { IEvent } from '../../pages/admin/manage-events'

export const Event = ({event} : {event: IEvent}) => {
    return (
        <div className={styles.event}>
            <div className={styles.event__background} />

            <div className={styles.image__container}>
                <img
                    //src="/token_covers/KT1AAxomZ6cxN5zYj2efG1nQyM1ggab4e216_1.png"
                    alt="event"
                    className={styles.event__cover}
                    src={"/event_covers/" + event?.id  + '.png'}
                />
            </div>

            <div className={`${styles.event__info} ${styles.right}`}>
                <h2>{event.name}</h2>
                <h3 style={{ fontStyle: "italic" }}>{event.tagline}</h3>

                <Label className={styles.event__label}>{event?.tokens?.length || 0} Token{(event?.tokens?.length || 0) === 1 ? '' : 's'}</Label>
            </div>

            <div className={`${styles.event__info} ${styles.left}`}>
                <Label className={styles.event__label}>
                    {new Date(event.startDate).toLocaleString()} to {new Date(event.endDate).toLocaleString()}
                </Label>

                {event?.eventCategory && (
                    <Label className={styles.event__label}>
                        Part of the {event?.eventCategory.name} Collection
                    </Label>
                )}
            </div>
        </div>
    )
}