import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import classNames from "classnames"

export const CustomPagination = ({
    className,
    onChange,
    page,
    itemsToDisplay,
    itemAmountToDisplay,
    ...props
}: {
    className?: string;
    onChange: (event: React.ChangeEvent<unknown>, page: number) => void;
    page: number;
    itemsToDisplay?: any[] | 0;
    itemAmountToDisplay: number;
}) => {
    
    return (
        <Stack spacing={2}>
            <Pagination count={itemsToDisplay? Math.ceil(itemsToDisplay.length/itemAmountToDisplay) : 0} // Display 20 items per page
                        onChange={onChange}
                        page={page} 
                        sx={{
                            '.MuiPagination-ul': {
                                justifyContent: 'center',
                                marginTop: '2em'
                            },
                            '.MuiPaginationItem-root': {
                              color: 'white',
                            },
                            '.MuiButtonBase-root.MuiPaginationItem-root.Mui-selected': {
                              backgroundColor: 'rgba(0, 0, 0, 0.3)'
                            }
                          }}
                        />
        </Stack>
    )
}