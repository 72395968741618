import { GRAPHCMS_NETWORK as NETWORK } from "../../constants"

const query: any = {}
const mutation: any = {}

mutation.addToken = `
    mutation addToken($contract: String!, $cryptoNetwork: CryptoNetworks!, $tokenId: Int!, $saleType: String!, $saleStart: DateTime, $saleEnd: DateTime, $eventId: ID! ) {
        createToken(
            data: {contract: $contract, cryptoNetwork: $cryptoNetwork, tokenId: $tokenId, saleType: $saleType, saleStart: $saleStart, saleEnd: $saleEnd, network: ${NETWORK}, event: {connect: {id: $eventId}}}
        ) {
            id
        }
    }  
`

mutation.editToken = `
    mutation editToken($id: ID!, $contract: String!, $cryptoNetwork: CryptoNetworks!, $tokenId: Int!, $saleStart: DateTime, $saleEnd: DateTime, $eventId: ID!) {
        updateToken(
            data: {contract: $contract, cryptoNetwork: $cryptoNetwork, network: ${NETWORK}, tokenId: $tokenId, saleStart: $saleStart, saleEnd: $saleEnd, event: {connect: {id: $eventId}}}
            where: {id: $id}
        ) {
            id
        }
    }  
`

mutation.publishToken = `
    mutation publishToken($id: ID!) {
        publishToken(where: {id: $id}) {
            id
        }
    }  
`

mutation.removeToken = `
    mutation removeToken($id: ID!) {
        deleteToken(where: {id: $id}) {
            id
        }
    }  
`
query.getToken = `
    query getToken($contract: String!, $cryptoNetwork: CryptoNetworks!, $tokenId: Int!) {
        tokens(
            where: { AND: {contract: $contract, cryptoNetwork: $cryptoNetwork, tokenId: $tokenId}}
          ) {
            contract
            id
            tokenId
            event {
              account {
                tezos
              }
            }
            cryptoNetwork
            externalUrl
            collection
            additionalInfo
            saleType
            saleStart
          }
    }
`

export const tokens = {
    query,
    mutation,
}