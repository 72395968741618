import { useEffect, useState } from "react"
import { ButtonLink, Label } from "../../components/design-system"
import { accountStore } from "../../state/global/accountStore"

import { motion } from "framer-motion"

import styles from './styles.module.scss'

export const Clearance = ({
    required,
    children
}: {
    required: number;
    children: React.ReactNode;
}) => {
    const accountState = accountStore()

    const [clearance, setClearance] = useState(0)

    useEffect(() => {
        console.log(accountState)
        
        if (accountState?.id) {
            setClearance(accountState?.clearance || 0)
        }
    }, [, accountState?.id])

    if (clearance >= required) {
        return (<>
            {children}
        </>)
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            
            transition={{
                delay: 0.5
            }}

            className={styles.message}
        >
            <Label>
                You're not authorized to see this.
            </Label>

            <ButtonLink to="/" className={styles.button} replace>
                Back to home
            </ButtonLink>
        </motion.div>
    )
}