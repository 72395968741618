const axios = require('axios').default

const query__collections = `
    query queryCollections($creator: String = "") {
        fa(where: {creator_address: {_eq: $creator}}) {
            contract
            logo
            collection_id
        }
    }  
`

export const fetchCollections = async (address: string) => {
    const response = await axios.post(`https://data.objkt.com/v3/graphql`, {
        query: query__collections,
        variables: {
            creator: address
        },
        operationName: 'queryCollections'
    }, {
        headers: {
            'Content-Type': 'application/json',
        }
    })

    return response.data.data.fa
}