import { GRAPHCMS_NETWORK as NETWORK } from "../../constants"

const query: any = {}
const mutation: any = {}

mutation.addEvent = {}

mutation.addEvent.uncategorized = `
    mutation addEvent($cover: String = "", $name: String = "", $tagline: String = "", $slug: String = "", $startDate: DateTime!, $endDate: DateTime!, $curatorFee: Int = 0, $curatorId: ID="") {
        createEvent(
            data: {name: $name, cover: $cover, tagline: $tagline, network: ${NETWORK}, slug: $slug, startDate: $startDate, endDate: $endDate, curatorFee: $curatorFee, account: {connect: {id: $curatorId}}}
        ) {
            id
        }
    }  
`

mutation.addEvent.categorized = `
    mutation addEvent($cover: String = "", $name: String = "", $tagline: String = "", $slug: String = "", $startDate: DateTime!, $endDate: DateTime!, $curatorFee: Int = 0, $curatorId: ID="", $categoryId: ID = "") {
        createEvent(
            data: {name: $name, cover: $cover, tagline: $tagline, network: ${NETWORK}, slug: $slug, startDate: $startDate, endDate: $endDate, curatorFee: $curatorFee, account: {connect: {id: $curatorId}}, eventCategory: {connect: {id: $categoryId}}}
        ) {
            id
        }
    }  
`

mutation.addEventCategory = `
    mutation addEventCategory($cover: String = "", $name: String = "", $slug: String = "") {
        createEventCategory(
            data: {name: $name, cover: $cover, network: ${NETWORK}, slug: $slug}
        ) {
            id
        }
    }  
`

mutation.editEvent = {}

mutation.editEvent.uncategorized = `
    mutation editEvent($slug: String = "", $name: String = "", $tagline: String = "", $cover: String = "", $startDate: DateTime!, $endDate: DateTime!, $id: ID = "", $curatorFee: Int = 0, $curatorId: ID="") {
        updateEvent(
            data: {name: $name, tagline: $tagline, network: ${NETWORK}, slug: $slug, cover: $cover, startDate: $startDate, endDate: $endDate, curatorFee: $curatorFee, account: {connect: {id: $curatorId}}}
            where: {id: $id}
        ) {
            id
        }
    }  
`

mutation.editEvent.categorized = `
    mutation editEvent($slug: String = "", $name: String = "", $tagline: String = "", $cover: String = "", $startDate: DateTime!, $endDate: DateTime!, $id: ID = "", $curatorFee: Int = 0, $curatorId: ID="", $categoryId: ID = "") {
        updateEvent(
            data: {name: $name, tagline: $tagline, network: ${NETWORK}, slug: $slug, cover: $cover, startDate: $startDate, endDate: $endDate, curatorFee: $curatorFee, account: {connect: {id: $curatorId}}, eventCategory: {connect: {id: $categoryId}}}
            where: {id: $id}
        ) {
            id
        }
    }  
`

mutation.editEvent.disconnect = `
    mutation editEvent($slug: String = "", $name: String = "", $tagline: String = "", $cover: String = "", $startDate: DateTime!, $endDate: DateTime!, $id: ID = "", $curatorFee: Int = 0, $curatorId: ID="") {
        updateEvent(
            data: {name: $name, tagline: $tagline, network: ${NETWORK}, slug: $slug, cover: $cover, startDate: $startDate, endDate: $endDate, curatorFee: $curatorFee, account: {connect: {id: $curatorId}}, eventCategory: {disconnect: true}}
            where: {id: $id}
        ) {
            id
        }
    }  
`

mutation.editEventCategory = `
    mutation editEventCategory($slug: String = "", $name: String = "", $cover: String = "", $id: ID = "") {
        updateEventCategory(
            data: {name: $name, network: ${NETWORK}, slug: $slug, cover: $cover}
            where: {id: $id}
        ) {
            id
        }
    }  
`

mutation.publishEvent = `
    mutation publishEvent($id: ID = "") {
        publishEvent(where: {id: $id}) {
            id
        }
    }  
`

mutation.publishEventCategory = `
    mutation publishEventCategory($id: ID = "") {
        publishEventCategory(where: {id: $id}) {
            id
        }
    }  
`

mutation.unpublishEvent = `
    mutation unpublishEvent($id: ID = "") {
        unpublishEvent(where: {id: $id}) {
            id
        }
    } 
`

mutation.removeEvent = `
    mutation removeEvent($id: ID = "") {
        deleteEvent(where: {id: $id}) {
            id
        }
    }  
`

mutation.removeEventCategory = `
    mutation removeEventCategory($id: ID = "") {
        deleteEventCategory(where: {id: $id}) {
            id
        }
    }  
`

query.queryEventCategories = `
    query queryEventCategories {
        eventCategories(where: {network: ${NETWORK}}) {
            id
            name
            slug
            cover
        }
    }  
`

query.queryEvents = `
    query queryEvents {
        events(where: {network: ${NETWORK}}) {
            id
            name
            slug
            cover
            startDate
            endDate
            curatorFee
            account {
                username
                tezos
                ethereum
                roles
            }
            tokens {
                id
                tokenId
                contract
                cryptoNetwork
            }
            eventCategory {
                id
                name
                slug
                cover
            }
            artblocksProjects {
                id
                title
                projectId
                mintPrice
                artistAddress
            }
            subevents {
              id
              name
              artblocksProjects {
                id
                title
                projectId
                mintPrice
                artistAddress
              }
              tokens {
                contract
                id
                tokenId
                cryptoNetwork
                externalUrl
                additionalInfo
                saleType
                saleStart
                saleEnd
                artist
                title
                edition
                dropType
                price
                dropDate
                sold
              }
            }
            eventType
        }
    }  
`

query.queryLatestEvents = `
    query queryLatestEvents($network: Networks!) {
        events(where: {network: $network}, orderBy: startDate_DESC ) {
            id
            name
            tagline
            slug
            cover
            eventUrl
            startDate
            endDate
            curatorFee
            account {
                username
                tezos
                ethereum
                roles
            }
            tokens {
                id
                tokenId
                contract
                cryptoNetwork
                sold
            }
            eventCategory {
                id
                name
                slug
                cover
            }
            artblocksProjects {
                id
                title
                projectId
                mintPrice
                artistAddress
            }
            subevents {
              id
              name
              artblocksProjects {
                id
                title
                projectId
                mintPrice
                artistAddress
              }
              tokens {
                contract
                id
                tokenId
                cryptoNetwork
                externalUrl
                additionalInfo
                saleType
                saleStart
                saleEnd
                artist
                title
                edition
                price
                dropType
                dropDate
                sold
              }
            }
            eventType
        }
    }  
`

query.queryUnpublishedEvents = `
    query queryUnpublishedEvents {
        events(where: {network: ${NETWORK}}, orderBy: startDate_DESC, stage: DRAFT) {
            id
            name
            tagline
            slug
            cover
            startDate
            endDate
            curatorFee
            account {
                username
                tezos
                ethereum
                roles
            }
            tokens {
                id
                tokenId
                contract
                cryptoNetwork
            }
            eventCategory {
                id
                name
                slug
                cover
            }
            artblocksProjects {
                id
                title
                projectId
                mintPrice
                artistAddress
            }
            subevents {
              id
              name
              artblocksProjects {
                id
                title
                projectId
                mintPrice
                artistAddress
              }
              tokens {
                contract
                id
                tokenId
                cryptoNetwork
                externalUrl
                additionalInfo
                saleType
                saleStart
                saleEnd
                artist
                title
                edition
                dropType
                price
                dropDate
                sold
              }
            }
            eventType
        }
    }  
`

query.queryEvent = `
    query queryEvent($id: ID = "") {
        events(where: {id: $id, network: ${NETWORK}}) {
        cover
        displayCover
        description
        curatorFee
        account {
            username
            tezos
            ethereum
        }
        endDate
        id
        name
        startDate
        tokens {
            contract
            id
            tokenId
            cryptoNetwork
            externalUrl
        }
        artblocksProjects {
            id
            title
            projectId
            mintPrice
            artistAddress
        }
        subevents {
          id
          name
          artblocksProjects {
            id
            title
            projectId
            mintPrice
            artistAddress
          }
          tokens {
            contract
            id
            tokenId
            cryptoNetwork
            externalUrl
            additionalInfo
            saleType
            saleStart
            saleEnd
            artist
            title
            edition
            dropType
            price
            dropDate
            sold
          }
        }
        slug
        eventType
        }
    }  
`

query.queryUnpublishedEvent = `
    query queryEvent($id: ID = "") {
        events(where: {id: $id}, stage: DRAFT) {
        cover
        displayCover
        description
        curatorFee
        account {
            username
            tezos
            ethereum
        }
        endDate
        id
        name
        tagline
        startDate
        tokens {
            contract
            id
            tokenId
            cryptoNetwork
            externalUrl
            additionalInfo
            saleType
            saleStart
            saleEnd
            sold
        }
        artblocksProjects {
            id
            title
            projectId
            mintPrice
            artistAddress
        }
        subevents {
          id
          name
          artblocksProjects {
            id
            title
            projectId
            mintPrice
            artistAddress
          }
          tokens {
            contract
            id
            tokenId
            cryptoNetwork
            externalUrl
            additionalInfo
            saleType
            saleStart
            saleEnd
            sold
            artist
            title
            edition
            dropType
            price
            dropDate
            event {
              account {
                tezos
              }
            }
          }
          sortEvent
        }
        slug
        eventType
        }
    }  
`

query.queryEventByName = `
    query queryEvent($name: String!) {
        events(where: {name: $name}) {
        cover
        curatorFee
        account {
            username
            tezos
            ethereum
        }
        endDate
        id
        name
        startDate
        auctionStartDate
        auctionEndDate
        tokens {
            contract
            id
            tokenId
            cryptoNetwork
            saleStart
            saleEnd
        }
        slug
        swaps {
            id
            active
            amount
            artifact_uri
            contract
            price
            creator
            tokenid
            royalties
        }
        artblocksProjects {
            id
            title
            projectId
            mintPrice
            artistAddress
        }
        subevents {
          id
          name
          artblocksProjects {
            id
            title
            projectId
            mintPrice
            artistAddress
          }
          tokens {
            contract
            id
            tokenId
            cryptoNetwork
            externalUrl
            additionalInfo
            saleType
            saleStart
            saleEnd
            artist
            title
            edition
            dropType
            price
            dropDate
            sold
          }
        }
        eventType
        }
    }  
`

query.queryEventNetwork = `
    query queryEvent($id: ID = "", $network: CryptoNetworks!) {
        events(where: {id: $id, network: ${NETWORK}}) {
        cover
        curatorFee
        account {
            username
            tezos
            ethereum
        }
        endDate
        id
        name
        startDate
        tokens(where: {cryptoNetwork: $network}) {
            contract
            id
            tokenId
            cryptoNetwork
        }
        artblocksProjects {
            id
            title
            projectId
            mintPrice
            artistAddress
        }
        subevents {
          id
          name
          artblocksProjects {
            id
            title
            projectId
            mintPrice
            artistAddress
          }
          tokens {
            contract
            id
            tokenId
            cryptoNetwork
            externalUrl
            additionalInfo
            saleType
            saleStart
            saleEnd
            artist
            title
            edition
            dropType
            price
            dropDate
            sold
          }
        }
        slug
        eventType
        }
    }  
`

export const events = {
    query,
    mutation,
}