import { GRAPHCMS_NETWORK as NETWORK } from "../../constants"

const query: any = {}
const mutation: any = {}

query.querySwaps = `
    query querySwaps {
        swaps(where: {active: false}) {
            id
            active
            amount
            artifact_uri
            contract
            price
            creator
            tokenid
            royalties
        }
    }
  
`

mutation.createSwap = `
    mutation createSwap($contract: String!, $creator: String!, $tokenid: Int!, $artifact_uri: String!, $amount: Int!, $price: Float!, $royalties: Int!, $active: Boolean!, $eventId: ID!) {
        createSwap(
            data: {contract: $contract, creator: $creator, tokenid: $tokenid, artifact_uri: $artifact_uri, amount: $amount, price: $price, royalties: $royalties, active: $active, event: {connect: {id: $eventId}}}
        ) {
            id
        }
    }  
`

mutation.updateActiveSwap = `
    mutation updateSwap($id: ID!) {
        updateSwap(data: {active: true},where: {id: $id}) {
            id
        }
    }  
`

mutation.publishSwap = `
    mutation publishSwap($id: ID!) {
        publishSwap(where: {id: $id}) {
            id
        }
    }  
`

mutation.removeSwap = `
    mutation removeSwap($id: ID!) {
        deleteSwap(where: {id: $id}) {
            id
        }
    }  
`



export const swaps = {
    query,
    mutation,
}