import { useEffect, useState } from "react"
import { Container, DropdownInput, Label } from "../../../components/design-system"
import { ethereumStore, getProvider, getSigner } from "../../../state/crypto/ethereumStore"
import { Input, Button } from "../../../components/design-system"
import { DEFAULT_CURATOR_ETH_ADDRESS, DEFAULT_DROP_MANAGER_ETH_ADDRESS, ETHERSCAN_URL } from "../../../constants"
import { waitSignerEthereum } from "../../../libs/crypto/crypto"
import { notificationStore } from "../../../state/global/notificationStore"
import { getUpcomingEvents } from "../swap"
import { fetchGraphcms, gql as gcmsQuery } from "../../../libs/graphcms"


import styles from '../mint/styles.module.scss'
import { ContractFactory } from 'ethers';
import { IEvent } from "../../admin/manage-events"
import { getEventDetails } from "../auction/tezos-auction"

const VCACustomFixedPrice = require("../../../constants/vcacustomfp-abi.json");

export const addCollection = async (name: string, artistAddress: string, contractAddress: string) => {
    return new Promise<string>(async (resolve, reject) => {
        const query = gcmsQuery.mutation.createCollection

        try {
            const data = await fetchGraphcms({
                key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
                query: query,
                variables: {
                    name,
                    artistAddress,
                    contractAddress
                }
            })

            const id: string = data.createCollection.id
            resolve(id)

        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

export const Collection = () => {
    const notifications = notificationStore()

    const ethereumState = ethereumStore()

    const [events, setEvents] = useState<IEvent[]>([])

    const [network, setNetwork] = useState<"tezos" | "ethereum">("ethereum")

    const [name, setName] = useState("")
    const [symbol, setSymbol] = useState("")
    const [selectedEvent, setSelectedEvent] = useState("")
    const [eventId, setEventId] = useState("")
    const [curatorAddress, setCuratorAddress] = useState(DEFAULT_CURATOR_ETH_ADDRESS)
    const [curatorFee, setCuratorFee] = useState(10)

    const [contractAddress, setContractAddress] = useState<string | null>(null)

    useEffect(() => {
        
        if (!ethereumState.address) return
        
        // Load existing events
        getUpcomingEvents(setEvents)
    
    }, [, ethereumState.address])

    const handleCreateCollection = async () => {
        const notifId = notifications.addNotification({
            message: "Creating collection: " + name,
            status: "pending"            
        })

        if (!name || !symbol || !selectedEvent) {
            notifications.setNotificationMessage({
                id: notifId,
                message: "Error. Please check all inputs have been entered",
            })

            notifications.reject(notifId)
        } 
        
        else {
            try {
                    const signer = getSigner()
        
                    if (!signer) {
                        await waitSignerEthereum(window)
                    }

                    const factory = new ContractFactory(VCACustomFixedPrice.abi, VCACustomFixedPrice.bytecode, signer);

                    // Args: name, symbol, curator address, artist address, drop manager address, curation fee
                    const contract = await factory.deploy(name, symbol, curatorAddress, ethereumState.address, DEFAULT_DROP_MANAGER_ETH_ADDRESS, curatorFee*10);
                    const receipt = await contract.deployTransaction.wait()
        
                    // Check the status of the transaction
                    if (receipt.status === 1) {
                        
                        // Add entry to database
                        const res = await addCollection(name, ethereumState.address, receipt.contractAddress)
                        setContractAddress(receipt.contractAddress)

                        // We dont publish the collection yet as that's not needed anywhere
                        // await publishCollection(res)

                        notifications.setNotificationMessage({
                            id: notifId,
                            message: "Successfully created collection"
                        })
                    
                        notifications.resolve(notifId)
        
                    } else {
                    // Catch any errors
                    notifications.setNotificationMessage({
                        id: notifId,
                        message: "Error creating collection"
                    })
        
                    notifications.reject(notifId)
                    }   
                }    
            catch (error) {
                console.error(error)
                notifications.setNotificationMessage({
                    id: notifId,
                    message: "Error creating collection",
                })

                notifications.reject(notifId)
            }
        }
    }

    if (!ethereumState.address) {
        return (
            <Container>
                <div className={styles.message}>
                    Sorry we currently only support deployments on Ethereum.
                </div>
            </Container>
        )
    }

    return (
        <Container>
            <div className={styles.container}>
                <div className={styles.fields__container}>
                    <h1>Create a Collection</h1>

                    <br />

                    <div className={styles.field}>
                        <Label>Network</Label>
                        <div className={styles.multiselect__container}>
                            <Button className={`${styles.multiselect__button} ${network === "ethereum" ? styles.active : null}`} onClick={() => setNetwork("ethereum")}>
                                Ethereum
                            </Button>
                        </div>
                    </div>

                    <br />

                    <div className={styles.field}>
                        <Label>Collection name</Label>
                        <Input
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Funfilled Lines"
                            value={name}
                        />
                    </div>

                    <div className={styles.field}>
                        <Label>Collection Symbol</Label>
                        <Input
                            onChange={(e) => setSymbol((e.target.value.toUpperCase()).replace(/\s+/g, ''))}
                            placeholder="FFL"
                            value={symbol}
                        />
                    </div>

                    <br />

                    <div className={styles.field}>


                    <Label>Event</Label>

                    <DropdownInput
                        list={(events.map((event: IEvent) => { return event.name }))}
                        value={selectedEvent}
                        onChange={(event) => {
                            getEventDetails("ethereum", event, setEventId, setCuratorAddress, setCuratorFee).then(() => {
                                setSelectedEvent(event)
                            })  
                        }}
                        >
                        </DropdownInput>
                    </div>

                    <Button className={styles.mint__button} onClick={() => {
                        handleCreateCollection()
                    }}>
                        Create collection
                    </Button>

                    <br></br>

                { contractAddress && <p className={styles.confirmation_text}>You have successfully created your collection at the following contract address: 
                    <a href={ETHERSCAN_URL+"address/"+contractAddress} target="_blank" rel="noreferrer" style={{ textDecoration: "underline" }}>{contractAddress}</a></p>}
                </div>
                
            </div>
        </Container>
    )
}