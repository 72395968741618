import { useRef } from 'react'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

import { useDetectClickOutside } from '../../../utils/hooks/useDetectClickOutside'
import { ButtonLink, Button } from '../buttons/button'

import styles from './styles.module.scss'

const Dropdown = ({
    children,
    className,
    ...props
}: {
    children: React.ReactNode
    className?: string
    [key: string]: any
}) => {
    return (
        <div className={classNames(styles.dropdown__container, className)} {...props}>
            {children}
        </div>
    )
}

const DropdownBody = ({
    children,
    className,
    isOpen,
    max = false,
    ...props
}: {
    children: React.ReactNode,
    className?: string,
    isOpen?: boolean,
    max?: boolean,
    [key: string]: any,
}) => {
    const dropdownItemsClassName = classNames(
        styles.dropdown__items,
        className,
    )

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.25, ease: [0.43, 0.13, 0.23, 0.96] }}

                    className={dropdownItemsClassName}
                    style={{
                        width: max ? '100%' : 'auto',
                    }}
                    {...props}
                >
                    {children}
                </motion.div>
            )}
        </AnimatePresence>
    )
}

const DropdownSpacer = ({
    className,
    ...props
}: {
    className?: string,
    [key: string]: any,
}) => {
    return (
        <div className={classNames(styles.dropdown__spacer, className)} {...props}/>
    )
}

const DropdownTrigger = ({
    children,
    className,
    onClick,
    setActive,
    ...props
}: {
    children: React.ReactNode,
    className?: string,
    onClick: () => void,
    setActive: (isActive: boolean) => void,
    [key: string]: any,
}) => {
    const triggerRef = useRef<HTMLDivElement>(null)
    useDetectClickOutside(triggerRef, () => setActive(false))

    const dropdownTriggerClassName = classNames(
        styles.dropdown__trigger,
        className,
    )

    return (
        <div ref={triggerRef} onClick={onClick}>
            <Button
                className={dropdownTriggerClassName}
                {...props}
            >
                {children}
            </Button>
        </div>
    )
}

const DropdownItems = ({
    children,
    className,
    ...props
}: {
    children: React.ReactNode,
    className?: string,
    [key: string]: any,
}) => {
    return (
        <div className={classNames(styles.dropdown, className)} {...props}>
            {children}
        </div>
    )
}

const DropdownItem = ({
    children,
    className,
    onClick,
    disabled,
    ...props
}: {
    children?: React.ReactNode,
    className?: string,
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    disabled?: boolean,
    [key: string]: any,
}) => {
    const dropdownItemClassName = classNames(
        styles.dropdown__item,
        className,
    )

    return (
        <div className={styles.item__container}>
            <Button
                className={dropdownItemClassName}
                onClick={!disabled ? onClick : undefined}
                disabled={disabled}
                {...props}
            >
                {children}
            </Button>
        </div>
    )
}

const DropdownItemLink = ({
    children,
    className,
    onClick,
    disabled,
    to,
}: {
    children?: React.ReactNode,
    className?: string,
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    disabled?: boolean,
    to: string,
}) => {
    const dropdownItemClassName = classNames(
        styles.dropdown__item,
        className,
    )

    return (
        <div className={styles.item__container}>
            <ButtonLink
                className={dropdownItemClassName}
                disabled={disabled}
                to={to}
                key={to}
            >
                {children}
            </ButtonLink>
        </div>
    )
}

Dropdown.Body = DropdownBody
Dropdown.Spacer = DropdownSpacer
Dropdown.Trigger = DropdownTrigger
Dropdown.Items = DropdownItems
Dropdown.Item = DropdownItem
Dropdown.ItemLink = DropdownItemLink
export { Dropdown, useDetectClickOutside }