import { Container, Button, Label, Input, NumberInput, DropdownInput } from '../../../components/design-system'
import styles from './styles.module.scss'
import { useState } from 'react'
import { getSigner } from '../../../state/crypto/ethereumStore'
import { waitSignerEthereum } from '../../../libs/crypto/crypto'
import { ethers } from 'ethers'
import { getAbi } from '../../../utils/crypto'
import { notificationStore } from '../../../state/global/notificationStore'
import { IProjectShell } from '../../../types/artblocks-types'
import { fetchGraphcms, gql as gcmsQuery } from "../../../libs/graphcms"
import { ARTBLOCKS_FLEX_CONTRACT_ADDRESS } from '../../../constants'
import { addAccount } from '../manage-users'
import { publishAccount } from '../manage-users'


export const addProjectShell = async (project: IProjectShell) => {
    return new Promise<string>(async (resolve, reject) => {
        const query = gcmsQuery.mutation.createProject

        try {
            const data = await fetchGraphcms({
                key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
                query: query,
                variables: {
                    title: project.title,
                    mintPrice: project.mintPrice,
                    artistAddress: project.artistAddress,
                    projectId: project.projectId
                }
            })

            const id: string = data.createArtblocksProject.id
            resolve(id)

        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

export const publishProject = async (id: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await fetchGraphcms({
                key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
                query: gcmsQuery.mutation.publishProject,
                variables: {
                    id,
                }
            })

            resolve(res)

        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

export const CreateProjectShell = () => {

    const notifications = notificationStore()

    const [projectName, setProjectName] = useState<string>("")
    const [mintPrice, setMintPrice] = useState<number>(0.01)
    const [artistName, setArtistName] = useState<string>("")
    const [artistAddress, setArtistAddress] = useState<string>("")
    const [errorMessage, setErrorMessage] = useState<string>("")

    const [selectedEvent, setSelectedEvent] = useState<any>([])


    // Validation before creating project shell
    const handleCreateShell = async () => {

        setErrorMessage("")

        if (projectName == "") {
            setErrorMessage("Project name must not be empty")
        }

        else if (artistName == "") {
            setErrorMessage("Artist name must not be empty")
        }

        else if (mintPrice < 0.01) {
            setErrorMessage("Price must be more than 0.01 eth")
        } 
        
        else if (!/^0x[0-9a-fA-F]{40}$/.test(artistAddress)) {
            setErrorMessage("Please enter a valid ETH address")
        } 
        
        // Connect with artblocks core engine
        else {

            const notifId = notifications.addNotification({
                message: 'Creating Project Shell',
                status: 'pending',
            })

            try {
                const signer = getSigner()

                if (!signer) {
                    await waitSignerEthereum(window)
                }

                const abi = await getAbi(ARTBLOCKS_FLEX_CONTRACT_ADDRESS)
                const abCoreEngine = new ethers.Contract(ARTBLOCKS_FLEX_CONTRACT_ADDRESS, abi, signer)
                const projectID = await abCoreEngine.nextProjectId()

                // Create project shell using `addProject` in Core Engine Contract
               const tx = await abCoreEngine.addProject(
                    projectName,
                    artistAddress,
                    ethers.utils.parseEther((mintPrice).toString()),
                )

                tx.wait()


                // Add entry to database
                const res = await addProjectShell({
                    title: projectName,
                    artistAddress: artistAddress,
                    mintPrice: mintPrice,
                    projectId: projectID.toNumber(),
                    event: selectedEvent,
                })


                //Publish Swap
                await publishProject(res)
 

                notifications.setNotificationMessage({
                    id: notifId,
                    message: "Project Shell Created"
                })

                // Add artist to creator
                const userID: string = await addAccount({
                    account: {
                        id: "",
                        username: artistName,
                        ethereum: ethers.utils.getAddress(artistAddress),
                        tezos: undefined,
                        roles: ["creator"],
                        cover: "ipfs://"
                    },
                
                    type: "add"
                })
    
                notifications.setNotificationMessage({
                    id: notifId,
                    message: "Adding user as creator..."
                })

                await publishAccount(userID).then(() => {
                    notifications.setNotificationMessage({
                        id: notifId,
                        message: "Publishing User"
                    })
    
                    notifications.resolve(notifId)
                })                
        
            } catch (error) {
                console.log(error)

                notifications.setNotificationMessage({
                    id: notifId,
                    message: "Error Creating Project Shell"
                })
    
                notifications.reject(notifId)
            }


        }
    }

    return (
        <Container>
            <div className={styles.container}>
                <div className={styles.fields__container}>
                    <h1>Create an Artblocks Project Shell</h1>

                    <br />

                    <div className={styles.field}>
                        <Label>Project Title</Label>
                        <Input
                            onChange={(e) => setProjectName(e.target.value)}
                            placeholder="i.e Fun-filled lines"
                            value={projectName}
                        />
                        { (errorMessage && errorMessage.toLowerCase().includes("name")) && (
                                <p className={styles.message}>{errorMessage}</p>
                        )}
                    </div>

                    <div className={styles.field}>
                        <Label>Artist Name</Label>
                        <Input
                            onChange={(e) => setArtistName(e.target.value)}
                            placeholder="i.e Jane Doe"
                            value={artistName}
                        />
                        { (errorMessage && errorMessage.toLowerCase().includes("artist")) && (
                                <p className={styles.message}>{errorMessage}</p>
                        )}
                    </div>

                    <br />

                    <div className={styles.field}>
                        <Label>Artist Address</Label>
                        <Input
                            onChange={(e) => setArtistAddress(e.target.value)}
                            placeholder="i.e 0xabcd.."
                            value={artistAddress}
                        />
                        { (errorMessage && errorMessage.toLowerCase().includes("address")) && (
                                <p className={styles.message}>{errorMessage}</p>
                        )}
                    </div>

                    <br />

                    <div className={styles.split}>
                        <div className={styles.split__el}>
                            <div className={styles.field}>
                                <Label>Price per mint (in ETH)</Label>
                                <NumberInput
                                    onChange={(e) => setMintPrice(parseFloat(e.target.value))}
                                    value={mintPrice}

                                    min={0.01}
                                    step={0.01}
                                />
                            </div>
                            { (errorMessage && errorMessage.toLowerCase().includes("price")) && (
                                <p className={styles.message}>{errorMessage}</p>
                            )}
                        </div>
                    </div>

                    {/*
                    <br /> 
                    
                    <div className={styles.split}>
                        <div className={styles.split__el}>
                            <div className={styles.field}>
                                <Label>Select Event</Label>
                            </div>
                        </div>
                    </div>
                    <DropdownInput
                        list={(events.map((event: IEvent) => { return event.name }))}
                        value={selectedEventName}
                        onChange={(event) => {
                            getEventDetails(event, setSelectedEvent)
                        }}
                        
                    >
                    </DropdownInput>
                    */}
                    <br />

                    <Button className={styles.mint__button} onClick={() => {
                        handleCreateShell()
                    }}>
                        Create Project Shell
                    </Button>
                </div>
            </div>
        </Container>
    )
}