import { Routes, Route, useLocation, matchRoutes, Navigate } from "react-router"

import { AnimatePresence, motion } from "framer-motion"

import type { IRoutes, IRoute } from "../types/routes"
import { routes } from "./routes";
import { useEffect } from "react";
import { NotFound } from "../pages/404";

export const getBase = ({
    path,
    depth
} : {
    path: string,
    depth: number
}) => {
    const base = path.split('/')
    base.splice(base.length - 1, depth)

    return base.join('/')
}

export const getTail = ({
    path,
    depth
}: {
    path: string,
    depth: number
}) => {
    const base = path.split('/')

    return base[base.length - 1]
}

export const RouterComponent = ({ routes, nested }: { routes: IRoutes, nested: boolean }) => {
    const location = useLocation()
    
    return (
        <AnimatePresence exitBeforeEnter initial={false}>
            <Routes
                location={location}
                key={nested ? location.pathname : getBase({ path: location.pathname, depth: routes.depth })}
            >
                <Route path="*" element={
                    <NotFound />
                } />

                {routes.pages.map(({ path, element: Element, animate }: IRoute) => (
                    <Route
                        path={path}
                        element={
                            <RouteWrapper
                                key={`wrapper-${routes.routerId}-${path}`}
                                element={<Element />}
                                animate={animate}
                            />
                        }
                        key={`route-${routes.routerId}-${path}`}
                    />       
                ))}
            </Routes>
        </AnimatePresence>
    )
}

const RouteWrapper = ({
    element,
    animate,
    ...props
}: {
    element: React.ReactElement,
    animate: boolean,
    [key: string]: any
}) => {
    const animateVariant = {
        initial: {
            opacity: 0,
        },
        animate: {
            opacity: 1,
        },
        exit: {
            opacity: 0,
        },

        transition: {
            duration: 0.25,
            ease: [0.6, 0.01, -0.05, 0.9]
        }
    }

    const stillVariant = {
        initial: {
            opacity: 1,
        },
        animate: {
            opacity: 1,
        },
        exit: {
            opacity: 1,
        }
    }

    return (
        <motion.div
            initial={animate ? animateVariant.initial : stillVariant.initial}
            animate={animate ? animateVariant.animate : stillVariant.animate}
            exit={animate ? animateVariant.exit : stillVariant.exit}

            transition={animateVariant.transition}
        >
            {element}
        </motion.div>
    )
}

export const GlobalRouter = () => {
    return (
        <RouterComponent routes={routes} nested={false}/>
    )
}