import styles from './styles.module.scss'
import classNames from 'classnames'

export const Label = ({
    children,
    className,
    ...props
}: {
    children: React.ReactNode,
    className?: string,
    left?: boolean,
    right?: boolean,
    center?: boolean,
    [key: string]: any,
}) => {
    const labelClassNames = classNames(
        styles.label,
        {
            [styles.left]: props.left,
            [styles.right]: props.right,
            [styles.center]: props.center,
        },
        className,
    )

    return (
        <div className={labelClassNames}>{children}</div>
    )
}