import axios from 'axios'

export const fetchAxios = async ({
    url,
    options
}: {
    url: string,
    options?: any
}) => {
    const response = await axios(url, options)
    return response.data
}