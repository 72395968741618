import styles from './styles.module.scss'
import { Label, ButtonLink, Countdown } from '../design-system'

export const SaleInfoPreview = ({
    to,
    network,
    saleType,
    supply,
    balance,
    price,
    saleEnd,
}: {
    to: string;
    network: "ethereum" | "tezos";
    saleType: "swap" | "auction" | "vca-fixed";
    supply: number;
    balance: number;
    price: number;
    saleEnd?: string;
}) => {
    return (
        <div className={styles.auction__history}>
            <div className={styles.info__container}>
                { balance == 0 ? (
                  <div className={styles.event__title} style={{ paddingTop: 0 }}>
                    Sold Out
                  </div>
                )
                :
                (
                  <>
                    <div>
                      <Label>Edition of {supply} - {balance} left</Label>

                      <div className={styles.event__title} style={{ paddingTop: 0 }}>
                        { network == "tezos" ? (price/1e6 + " XTZ") : (price/1e18 + " ETH") }
                      </div>
                    </div>

                    <ButtonLink to={to} className={styles.bid__button}>
                    Collect
                    </ButtonLink>   
                  </>
                )}
                { saleEnd && 
                    ( saleEnd > new Date().toString() ? (
                    <div style={{ width: '100%' }}>
                        <Countdown target={new Date(saleEnd)} className={styles.countdown}/>
                    </div>  
                    ):(
                    <div style={{ width: '100%' }}>
                      <div className={styles.event__title} style={{ paddingTop: 0 }}>
                        Sold out
                      </div>  
                    </div>         
                    )
                  )
                }
            </div>
        </div>
    )
}