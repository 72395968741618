import { GRAPHCMS_NETWORK as NETWORK } from "../../constants"

const query: any = {}
const mutation: any = {}

mutation.addAccount = `
    mutation createAccount($username: String = "", $tezos: String = "", $ethereum: String = "", $roles: [Roles!] = [], $profilePicture: String = "") {
        createAccount(data: {network: ${NETWORK}, username: $username, ethereum: $ethereum, tezos: $tezos, roles: $roles, profilePicture: $profilePicture}) {
            id
        }
    }
`

mutation.editAccount = `
    mutation editAccount($ethereum: String = "", $profilePicture: String = "", $tezos: String = "", $username: String = "", $website: String = "", $id: ID = "", $roles: [Roles!] = []) {
        updateAccount(
            data: {ethereum: $ethereum, network: testnet, profilePicture: $profilePicture, tezos: $tezos, website: $website, username: $username, roles: $roles}
            where: {id: $id}
        ) {
            id
        }
    }
`

mutation.publishAccount = `
    mutation publishAccount($id: ID!) {
        publishAccount(where: {id: $id}) {
            id
        }
    }
`

mutation.deleteAccount = `
    mutation deleteAccount($id: ID!) {
        deleteAccount(where: {id: $id}) {
            id
        }
    }
`

query.queryAccount = `
    query queryAccount($tezos: String, $ethereum: String) {
        accounts(
            where: {OR: [{ethereum: $ethereum}, {tezos: $tezos}], AND: {network: ${NETWORK} }}
        ) {
            username
            ethereum
            tezos
            id
            roles
            profilePicture
        }
    }  
`

query.queryAccounts = `
    query queryAccount {
        accounts(where: { network: ${NETWORK} }) {
            username
            ethereum
            tezos
            id
            roles
            profilePicture
        }
    }
`

export const accounts = {
    query,
    mutation,
}