import { useState, useEffect } from 'react'
import Masonry from '@mui/lab/Masonry'

import { IPFS_GATEWAY } from '../../../constants'
import { fetchGraphcms, gql } from "../../../libs/graphcms"

import { FileInput, Input, Label, Modal, Button, Container } from '../../../components/design-system'
import styles from './styles.module.scss'
import { notificationStore } from '../../../state/global/notificationStore'
import { upload } from '../../../libs/pinata'
import { LazyLoad } from '../../../components/design-system/lazy/lazy'

import { motion } from 'framer-motion'

export interface ICategory {
    id?: string;
    name: string;
    cover?: string;
    slug: string;
}

export const getCategories = async (set: any) => {
    await fetchGraphcms({
        key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
        query: gql.query.queryEventCategories
    }).then((response) => {
        const categories = response.eventCategories

        set(categories)
    }).catch((error) => {
        console.log(error)
    })
}

const addCategory = async ({ category, type }: { category: ICategory, type: "add" | "edit" }) => {
    return new Promise<string>(async (resolve, reject) => {
        try {
            const data = await fetchGraphcms({
                key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
                query: type === 'add' ? gql.mutation.addEventCategory : gql.mutation.editEventCategory,
                variables: {
                    id: category.id,
                    name: category.name,
                    slug: category.slug,
                    cover: category.cover
                }
            })

            const id: string = type === 'add' ? data.createEventCategory.id : category.id
            resolve(id)
        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

const publishCategory = async (id: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await fetchGraphcms({
                key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
                query: gql.mutation.publishEventCategory,
                variables: {
                    id
                }
            })

            resolve(res)
        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

const removeCategory = async (id: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await fetchGraphcms({
                key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
                query: gql.mutation.removeEventCategory,
                variables: {
                    id
                }
            })

            resolve(res)
        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

export const ManageEventCategories = () => {
    const notifications = notificationStore()

    const [categories, setCategories] = useState([])

    const [openAddCategory, setOpenAddCategory] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState<ICategory>()

    const [name, setName] = useState('')
    const [cover, setCover] = useState('')
    const [slug, setSlug] = useState('')

    const [ready, setReady] = useState(false)

    useEffect(() => {
        getCategories(setCategories)
    }, [])

    useEffect(() => {
        setSlug(name.replace(/\s/g, '-').toLowerCase())
    }, [name])

    useEffect(() => {
        if (cover && name) {
            setReady(true)
        } else {
            setReady(false)
        }
    }, [
        name,
        cover
    ])

    const handleUploadCover = async (files: FileList | null) => {
        if (!files || !files.length) return

        const file = files[0]

        const notifID = notifications.addNotification({
            message: 'Uploading Cover',
            status: 'pending',
        })

        try {
            const cid: any = await upload({ file })
            notifications.setNotificationMessage({
                id: notifID,
                message: 'Cover Uploaded',
            })
            notifications.resolve(notifID)

            setCover('ipfs://' + cid.IpfsHash)
        } catch (error) {
            console.log(error)
            notifications.reject(notifID)
        }
    }

    const handleAddCategory = async (type: "add" | "edit") => {
        if (!name || !slug) return

        const notifID = notifications.addNotification({
            message: type === 'add' ? 'Adding Category' : 'Editing Category',
            status: 'pending',
        })

        try {
            const res = await addCategory({
                category: {
                    id: selectedCategory?.id,
                    name,
                    slug,
                    cover
                },
                type
            })

            notifications.setNotificationMessage({
                id: notifID,
                message: 'Publishing Category',
            })

            await publishCategory(res)

            notifications.setNotificationMessage({
                id: notifID,
                message: 'Category Added',
            })

            notifications.resolve(notifID)

            getCategories(setCategories).then(() => {
                setCover('')
                setName('')
                setSlug('')

                setOpenAddCategory(false)
                setSelectedCategory(undefined)
            })
        } catch (error) {
            console.log(error)
            notifications.reject(notifID)
        }
    }

    return (
        <Container>
            <Masonry columns={{ xs: 1, sm: 2, lg: 4 }}>
                {categories.map((category: ICategory) => (
                    <div className={styles.category} key={category.id}>
                        <div className={styles.edit__container}>
                            <svg onClick={() => {
                                setCover(category?.cover || '')
                                setName(category.name)
                                setSlug(category.slug)

                                setSelectedCategory(category)
                            }} className={styles.edit__icon} xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                                <path d="M5 19H6.4L16.45 8.975L15.75 8.25L15.025 7.55L5 17.6ZM3 21V16.75L16.45 3.325Q17.025 2.75 17.863 2.75Q18.7 2.75 19.275 3.325L20.675 4.75Q21.25 5.325 21.25 6.15Q21.25 6.975 20.675 7.55L7.25 21ZM19.25 6.15 17.85 4.75ZM16.45 8.975 15.75 8.25 15.025 7.55 16.45 8.975Z"/>
                            </svg>

                            <LazyLoad
                                className={styles.category__cover}

                                src={IPFS_GATEWAY + (category?.cover || 'ipfs://QmT7h1TJbHeUGKtGNJE6EYgNVcTTpxXuyeXqNxsyoT8cde').split('ipfs://')[1]}
                                placeholderSrc={IPFS_GATEWAY + (category?.cover || 'ipfs://QmT7h1TJbHeUGKtGNJE6EYgNVcTTpxXuyeXqNxsyoT8cde').split('ipfs://')[1] + '?img-quality=5'}
                                alt="Category Cover"
                            />
                        </div>

                        <div className={styles.category__title}>{category.name}</div>
                        <div className={styles.label__container}>
                            <Label>/{category.slug}</Label>
                            <div className={styles.remove__container} onClick={async () => {
                                const notifID = notifications.addNotification({
                                    message: 'Removing Category',
                                    status: 'pending',
                                })

                                try {
                                    await removeCategory(category.id as string)
                                    
                                    notifications.setNotificationMessage({
                                        id: notifID,
                                        message: 'Category Removed',
                                    })

                                    getCategories(setCategories)

                                    notifications.resolve(notifID)
                                } catch (error) {
                                    console.log(error)
                                    notifications.reject(notifID)
                                }
                            }}>
                                <Label className={styles.remove} right>Remove</Label>
                            </div>
                        </div>
                    </div>
                ))}

                <div className={`${styles.category} ${styles.add__container}`} onClick={() => setOpenAddCategory(true)}>
                    <div className={styles.edit__container} style={{ margin: '0 auto', padding: '2rem 0', width: 'fit-content' }}>
                        <svg className={styles.add__category} xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                            <path d="M22.5 38V25.5H10V22.5H22.5V10H25.5V22.5H38V25.5H25.5V38Z"/>
                        </svg>
                    </div>

                    <div className={styles.category__title}>Add Category</div>
                </div>
            </Masonry>

            <Modal
                open={openAddCategory || !!selectedCategory}
                onClose={() => {
                    setCover('')
                    setSlug('')
                    setName('')

                    setOpenAddCategory(false)
                    setSelectedCategory(undefined)
                }}
                title={selectedCategory ? 'Edit Category' : 'Add Category'}
            >
                <Modal.Wrapper>
                    <Label>Category Name</Label>
                    <Input
                        value={name}
                        onChange={(e) => setName(e.target.value)}

                        placeholder="Enter a Category Name"
                    />
                </Modal.Wrapper>

                <Modal.Wrapper>
                    <Label>Cover Image</Label>
                    <FileInput
                        onChange={(e) => handleUploadCover(e.target.files)}
                        accept="image/*"
                    />

                    <div className={styles.cover__container}>
                        {cover && (
                            <a href={IPFS_GATEWAY + cover.split('ipfs://')[1]} target="_blank" rel='noreferrer'>
                                <Label className={styles.underline}>View </Label>
                            </a>
                        )}
                        <Label right>.png .jpeg .jpg</Label>
                    </div>
                </Modal.Wrapper>

                <Modal.Spacer />

                <Modal.Wrapper>
                    <Button
                        disabled={!ready}
                        onClick={async () => {
                            await handleAddCategory(selectedCategory ? 'edit' : 'add')
                        }}
                    >
                        {ready ? (
                            selectedCategory ? 'Update' : 'Add'
                        ) : (
                            'Please fill out all fields'
                        )}
                    </Button>
                </Modal.Wrapper>
                {/* <div className={styles.modal__container}>
                adpioajdp */}

            </Modal>
        </Container>
    )
}