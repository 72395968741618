import { useState, useEffect } from "react"
import { Link, Navigate, useNavigate } from "react-router-dom"
import { DropdownInput, Container, Loader, Label } from "../../components/design-system"
import { Masonry } from "@mui/lab"
import type { IToken } from "../admin/manage-tokens/tokens"
import { DummyToken } from "../../components/token/dummytoken"
import { Token } from "../../components/token/token"

import { ArtblockProject } from "../../components/token/artblockproject"

import styles from './styles.module.scss'

export const ExhibitionEvent = ({
    id,
    name,
    artblocksProjects,
    tokens
}: {
    id: string;
    name: string;
    artblocksProjects: any;
    tokens: any;
}) => {
    return (
        <Container>
            <Masonry columns={{ xs: 1, sm: 2, lg: 4 }}>
            {artblocksProjects.map((artblockProject: any) => {
                return(
                    <ArtblockProject
                        id={artblockProject.id}
                        title={artblockProject.title}
                        projectId={artblockProject.projectId}
                        mintPrice={artblockProject.mintPrice}
                        artistAddress={artblockProject.artistAddress}
                        contract={process.env.REACT_APP_ARTBLOCKS_CONTRACT}
                        to={"/token/ethereum" + "/" + process.env.REACT_APP_ARTBLOCKS_CONTRACT + "/" + artblockProject.projectId}
                    />
                )
            })}
            </Masonry>
            <Masonry columns={{ xs: 1, sm: 1, lg: 1 }}>
                {tokens.map((token: any) => {
                    return token.externalUrl ? 
                        (
                        <DummyToken
                            id={token.tokenId}
                            to={token.externalUrl}
                            metadata={token.metadata}
                            saleStart={token.saleStart}
                            saleEnd={token.saleEnd}
                            saleType={token.saleType}
                            sold={token.sold}
                            additionalInfo={token.additionalInfo}
                            artist_name={token.artist}
                            title={token.title}
                            edition={token.edition}
                            dropType={token.dropType}
                            token_price={token.price}
                            dropDate={token.dropDate}
                            contract={token.contract}
                            cryptoNetwork={token.cryptoNetwork}
                            key={token.tokenId}
                        />
                        )
                        :
                        ( 
                        <>
                        <Token
                            network={token.cryptoNetwork}
                            contract={token.contract}
                            id={token.tokenId}
                            key={token.contract + token.tokenId.toString()}
                            additionalInfo={token.additionalInfo}
                            externalUrl={token.externalUrl}
                            saleStart={token.saleStart}
                            saleEnd={token.saleEnd}
                            saleType={token.saleType}
                            to={"/token/" + token.cryptoNetwork + "/" + token.contract + "/" + token.tokenId}
                        />
                    </>
                    )  
                    })
                }
            </Masonry>
        </Container>
    )

}
