import type { IRoutes } from "../../types/routes"
import { Navigate } from "../../utils/redirect/redirect"
import { SelectEvent } from "../../pages/admin/manage-tokens/select-event"
import { Tokens } from "../../pages/admin/manage-tokens/tokens"

export const routes: IRoutes = {
    routerId: 'tokenRouter',
    depth: 3,
    pages: [
        {
            path: '',
            element: SelectEvent,
            animate: false,
        },
        {
            path: '/event/*',
            element: Tokens,
            animate: false,
        }
    ],
}