import { IPFS_GATEWAY } from "../../../constants"

const axios = require('axios').default

export const uploadImage = async (file: any) => {
    const formData = new FormData()
    formData.append('file', file)

    const response = await axios.post(`https://api.pinata.cloud/pinning/pinFileToIPFS`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'pinata_api_key': `${process.env.REACT_APP_PINATA_KEY}`,
            'pinata_secret_api_key': `${process.env.REACT_APP_PINATA_SECRET}`,
        }
    })

    return response.data.IpfsHash
}

export const generateCover = async (artifact: string, width: number, height: number, quality: number) => {
    const response = await fetch(IPFS_GATEWAY + artifact + `?img-width=${width}&img-height=${height}&img-quality=${quality}&img-fit=contain`)
    const blob = await response.blob()

    const cid = uploadImage(blob)
    return cid
}