import { ButtonLink } from '../../components/design-system'

import styles from './styles.module.scss'

export const NotFound = () => {

    if (window.location.pathname !== '/404') {
        window.location.assign('/404')
    }

    if (window.location.pathname === '/404') {
        return (
            <div
                className={styles.container}
            >
                <h1>404</h1>
                <p>Page not found</p>
    
                <ButtonLink className={styles.button} to="/">
                    Go Home
                </ButtonLink>
            </div>
        )            
    }

    return null
}