import create from "zustand"
import { devtools } from "zustand/middleware"

interface INotification {
    id: number;
    status: "pending" | "success" | "error";
    message: string;

    expiryLength?: number;
    expiryDate?: number;
    reference?: string;
}

interface INotificationInput {
    status: "pending" | "success" | "error";
    message: string;
}

type NotificationType = {
    notifications: INotification[];
    currentId: number;

    addNotification: (notification: INotificationInput) => number;
    setNotificationMessage: ({ id, message }: { id: number; message: string; }) => void;
    setNotificationStatus: ({ id, status }: { id: number; status: "pending" | "success" | "error"; }) => void;
    resolve: (id: number, reference?: string) => void;
    reject: (id: number, reference?: string) => void;

    removeNotification: (id: number) => void;

    clearNotifications: () => void;
}

export const notificationStore = create<NotificationType>(
    devtools((set, get) => ({
        notifications: [],
        currentId: 0,

        addNotification: (notification: INotificationInput) => {
            set((state: NotificationType) => ({
                ...state,
                currentId: state.currentId + 1,
    
                notifications: [...state.notifications, {
                    ...notification,
                    id: state.currentId++,
                }],
            }))

            const id: number = get().currentId - 1
            return id
        },
        setNotificationMessage: ({
            id,
            message
        }: {
            id: number;
            message: string;
        }) => set((state: NotificationType) => {
            const notification = state.notifications.find(n => n.id === id);

            if (notification) {
                notification.message = message;
            }
        }),
        setNotificationStatus: ({
            id,
            status,
        }: {
            id: number;
            status: "pending" | "success" | "error";
        }) => set((state: NotificationType) => {
            const notification = state.notifications.find(n => n.id === id);

            if (notification) {
                notification.status = status;
            }
        }),
        resolve: (id: number, reference?: string) => set((state: NotificationType) => {
            const notification = state.notifications.find(n => n.id === id)
            if (notification) {
                notification.status = "success"
                notification.reference = reference

                const timeout = setTimeout(() => {
                    state.removeNotification(id)
                }, 5000);

                notification.expiryLength = 5000
                notification.expiryDate = new Date(Date.now() + 5000).getTime()

                return () => clearTimeout(timeout)
            }
        }),
        reject: (id: number, reference?: string) => set((state: NotificationType) => {
            let timeout;

            const notification = state.notifications.find(n => n.id === id)
            if (notification) {
                notification.status = "error"
                notification.reference = reference

                const timeout = setTimeout(() => {
                    state.removeNotification(id)
                }, 10000);

                notification.expiryLength = 10000
                notification.expiryDate = new Date(Date.now() + 10000).getTime()

                return () => clearTimeout(timeout)
            }
        }),

        removeNotification: (id: number) => set((state: NotificationType) => ({
            ...state,
            notifications: state.notifications.filter(n => n.id !== id)
        })),

        clearNotifications: () => set(() => ({
            notifications: []
        }))
    })
))