import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { connectMetamaskBackground } from '../../libs/crypto/ethereum'
import { connectBeaconBackground } from '../../libs/crypto/tezos'
import { ethereumStore } from '../../state/crypto/ethereumStore'

import { Logo } from "./components/profile/logo"
import { Profile } from "./components/profile/profile"

import styles from './styles.module.scss'

export const Header = () => {
    useEffect(() => {
        connectMetamaskBackground(window)
        connectBeaconBackground()
    }, [])

    return (
        <div className={styles.header}>
            <div className={styles.header__container}>
                <Link className={styles.logo__container} to="/">
                    <Logo />
                </Link>
                <Link className={styles.events__link__container} to="/exhibitions">
                    Exhibitions
                </Link>
                
                <a style={{ paddingLeft: "2rem" }} href="https://gallery.verticalcrypto.art/event/clk1j6563hu5m0bmj06mwckb5/all">
                    Live
                </a>
                { /*  <Link className={styles.events__link__container} to="/event/clesnijkmc89o0amjjbpyq4ci/all">
                    Live
                </Link>
                &nbsp;
                 <a href="https://gallery.verticalcrypto.art/bid/squiggles" style={{ paddingLeft: "2rem" }}  target="_blank" rel="noopener noreferrer" className={styles.dummy__buy__button}>
                    Live
                </a> */}
            </div>
            <Profile />
        </div>
    )
}
