import { useEffect, useState, useCallback } from 'react'
import classNames from 'classnames'

import { AnimatePresence, motion } from 'framer-motion'

import styles from './styles.module.scss'

export const LazyLoad = ({
    children,
    className,
    placeholderSrc,
    fill = false,
    ...props
}: {
    children?: React.ReactNode
    className?: string
    placeholderSrc?: string

    [key: string]: any
    src?: string
    alt?: string

    fill?: boolean
}) => {
    const [coverLoaded, setCoverLoaded] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [width, setWidth] = useState(0)
    
    const [timeoutRef, setTimeoutRef] = useState<any>()

    const classes = classNames(styles.lazyload, className)

    useEffect(() => {
        return () => { clearTimeout(timeoutRef) }
    }, [timeoutRef])

    const transition = coverLoaded && {
        delay: 1.6,
        ease: 'easeInOut',
    } || {}
    
    return (
        <AnimatePresence>
            {!loaded && (
                <div className={styles.overlay} key={props.src + "-overlay"}>
                    <div className={styles.loader__container}>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, rotate: [0, 360] }}
                            exit={{ opacity: 0 }}

                            transition={{
                                opacity: { loop: 0, delay: 1.5 },
                                duration: 0.7,
                                ease: "easeInOut",
                                loop: Infinity,
                                delay: 0.5,
                            }}

                            className={styles.loader}
                        />
                    </div>
                </div>
            )}

            <motion.div
                initial={!loaded && { height: 250 }}
                animate={!fill ? (coverLoaded || loaded) && { height: 'fit-content' } : { height: '100%' }}
                transition={{ duration: 1, ease: 'easeInOut', height: { delay: 0.5, duration: 0.5 } }}
                className={styles.container}
            >
                <motion.div
                    animate={coverLoaded && { opacity: 1 } || {}}
                    
                    transition={transition}
                    className={styles.image__container}
                    style={{ height: fill ? '100%' : 'fit-content' }}
                    key={placeholderSrc + '-cover'}
                >
                    <svg className={styles.filter}>
                        <filter id='noiseFilter'>
                            <feTurbulence 
                                type='fractalNoise' 
                                baseFrequency='0.65' 
                                numOctaves='3' 
                                stitchTiles='stitch'
                            />
                        </filter>

                        <rect filter='url(#noiseFilter)' />
                    </svg>

                    <img {...props} src={placeholderSrc} className={`${classes} ${styles.lazyload}`} alt="Low Resolution Placeholder" onLoad={() => setCoverLoaded(true)}/>
                </motion.div>

                <motion.div
                    initial={{ opacity: 0 }}
                    animate={loaded && { opacity: 1 }}
                    transition={{ duration: 2, ease: 'easeInOut' }}       
                    
                    className={styles.image__container}
                    style={{ position: 'relative', height: fill ? '100%' : 'fit-content' }}
                    key={props.src + "-source"}
                >
                    <motion.img
                        {...props}
                        key={props.src}
                        className={classes}

                        alt="loading"
                        onLoad={() => {
                            setTimeoutRef(setTimeout(() => {
                                setLoaded(true)
                            }, 3000))
                        }}
                    />
                </motion.div>
            </motion.div>

            {children}
        </AnimatePresence>
    )
}