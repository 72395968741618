import { useEffect, useState } from "react"
import { Container, Label } from "../../../../components/design-system"
import { fetchGraphcms, gql } from "../../../../libs/graphcms"
import { Masonry } from "@mui/lab"
import { LazyLoad } from "../../../../components/design-system/lazy/lazy"
import { IPFS_GATEWAY } from "../../../../constants"
import { Link } from 'react-router-dom'
import { getUnpublishedEvents } from "../../manage-events"

import styles from './styles.module.scss'

interface IEvent {
    id?: string;
    name: string;
    cover?: string;
    slug: string;
    startDate: string;
    endDate: string;
    curatorFee: Number;
    categoryId?: string;
    eventCategory?: any;
    tokens: any[];
}

export const SelectEvent = () => {
    const [events, setEvents] = useState([])
    
    useEffect(() => {
        getUnpublishedEvents(setEvents)
    }, [])

    return (
        <Container>
            <Masonry columns={{ xs: 1, sm: 2, lg: 4 }}>
                {events.map((event: IEvent) => (
                    <div className={styles.event} key={event.id}>
                        <div className={styles.edit__container}>
                            <Link to={'event/' + event.id}>
                                <svg className={styles.edit__icon} xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                                    <path xmlns="http://www.w3.org/2000/svg" d="M5 19V14H7V17H10V19ZM5 10V5H10V7H7V10ZM14 19V17H17V14H19V19ZM17 10V7H14V5H19V10Z"/>
                                </svg>
                            </Link>

                            <LazyLoad
                                className={styles.event__cover}

                                src={IPFS_GATEWAY + (event?.cover || 'ipfs://QmT7h1TJbHeUGKtGNJE6EYgNVcTTpxXuyeXqNxsyoT8cde').split('ipfs://')[1]}
                                placeholderSrc={IPFS_GATEWAY + (event?.cover || 'ipfs://QmT7h1TJbHeUGKtGNJE6EYgNVcTTpxXuyeXqNxsyoT8cde').split('ipfs://')[1] + '?img-quality=5'}
                                alt="event Cover"
                            />
                        </div>

                        <div className={styles.event__title}>{event.name}</div>
                        <Label>
                            {event.tokens.length} Tokens
                        </Label>
                    </div>
                ))}
            </Masonry>

        </Container>
    )
}