import { PageWrapper, Container } from "../../../components/design-system"
import { Masonry } from "@mui/lab"
import { CreatorCard } from "../dashboard"

export const Auction = () => {
    return (
        <PageWrapper>
            <Container>
                <Masonry sx={{ width: 'auto' }} columns={{ xs: 1, lg: 2 }}>
                    <CreatorCard to="/creator/auction/ethereum" message="Auction on">
                        <h1>Ethereum</h1>
                    </CreatorCard>

                    <CreatorCard to="/creator/auction/tezos" message="Auction on">
                        <h1>Tezos</h1>
                    </CreatorCard>
                </Masonry>
                
                <CreatorCard to="/" message="Go to">
                        <h1>Home</h1>
                </CreatorCard>
            </Container>
        </PageWrapper>
    )
}