import { useEffect } from "react"
import { useNavigate } from "react-router"

export const Redirect = ({
    href,
    replace
}: {
    href: string,
    replace?: boolean
}) => {
    if (replace) {
        window.open(href)
    } else {
        window.location.replace(href)
    }
}

export const Navigate = ({
    to,
    replace
}: {
    to: string,
    replace?: boolean
}) => {
    const navigate = useNavigate()

    useEffect(() => {
        navigate(to, { replace })
    }, [])

    return null
}