import { RightsEnum } from ".";
import { IPFS_GATEWAY } from "../../../constants";

const axios = require('axios').default;

export const uploadJSON = async (json: any) => {

    const response = await axios.post(`https://api.pinata.cloud/pinning/pinJSONtoIPFS`, json, {
        headers: {
            'Content-Type': 'application/json',
            'pinata_api_key': `${process.env.REACT_APP_PINATA_KEY}`,
            'pinata_secret_api_key': `${process.env.REACT_APP_PINATA_SECRET}`,
        }
    })

    return response.data.IpfsHash
}

export const generateMetadata = {
    tezos: async ({
        name = "",
        description = "",
        editions = 1,
        royalties = 0,
        license = RightsEnum.NONE,
        artifactUri,
        coverUri,
        thumbnailUri,
        creator,
        mediaType,
        width,
        height
    }: {
        name: string;
        description: string;
        editions: number;
        royalties: number;
        license: RightsEnum;
        artifactUri: string;
        coverUri: string;
        thumbnailUri: string;
        creator: string;
        mediaType: string;
        width: number;
        height: number;
    }) => {
        const cid = await uploadJSON(
            JSON.stringify({
                name: name,
                description: description,
                tags: [],
                rights: license,
                minter: "KT1Aq4wWmVanpQhq4TTfjZXB5AjFpx15iQMM",
                mintingTool: "https://auction.verticalcrypto.art",
                date: new Date().toISOString(),
                symbol: 'OBJKTCOM',
                artifactUri: artifactUri,
                displayUri: coverUri,
                thumbnailUri: thumbnailUri,
                image: coverUri,
                creators: [
                    creator
                ],
                formats: [
                    {
                        uri: artifactUri,
                        mimeType: mediaType,
                        fileName: `artifact.${mediaType.split("/")[1]}`,
                        dimensions: {
                            value: `${width}x${height}`,
                            unit: "px"
                        }
                    },
                    {
                        uri: coverUri,
                        mimeType: mediaType,
                        fileName: `cover.${mediaType.split("/")[1]}`,
                        dimensions: {
                            value: '1024x1024',
                            unit: "px"
                        }
                    },
                    {
                        uri: thumbnailUri,
                        mimeType: mediaType,
                        fileName: `thumbnail.${mediaType.split("/")[1]}`,
                        dimensions: {
                            value: '350x350',
                            unit: "px"
                        }
                    }
                ],
                attributes: [],
                decimals: 0,
                isBooleanAmount: false,
                shouldPreferSymbol: false,
                royalties: {
                    decimals: 3,
                    shares: {
                        [creator]: royalties * 10
                    }
                }
            })
        )
    
        return cid    
    },
    
    ethereum: async ({
        name = "",
        description = "",
        royalties = 0,
        image,
        previewURI,
        contentURI,
        animationURI = null,
        creator,
        mediaType,
    }: {
        name: string;
        description: string;
        royalties: number;
        image: string;
        previewURI: string;
        contentURI: string;
        animationURI: string | null;
        creator: string;
        mediaType: string;
    }) => {
        const cid = await uploadJSON(
            JSON.stringify({
                name: name,
                description: description,
                mediaType: (mediaType.split("/")[0]).toUpperCase(),
                contentType: mediaType,
                creator: creator,
                previewURI: previewURI,
                contentURI: contentURI,
                image: image,
                external_url: IPFS_GATEWAY + contentURI.split('ipfs://')[1],
                animation_url: animationURI,
                royalties: {
                    amount: royalties,
                    recipient: creator
                }
            })
        )

        return cid
    }
}