import create from "zustand"
import { devtools } from "zustand/middleware"

import { TEZOS_NETWORK } from "../../constants"
import type { TezosType, TezosNetworkEnum } from "../../types/crypto-types"

export const tezosStore = create<TezosType>(
    devtools((set) => ({
        network: TEZOS_NETWORK,
        address: "",
        balance: 0,
        
        beacon: null,
        provider: null,
        signer: null,

        setNetwork: (network: TezosNetworkEnum) => set((state: TezosType) => ({ ...state, network })),
        setAddress: (address: string) => set((state: TezosType) => ({ ...state, address })),
        setBalance: (balance: number) => set((state: TezosType) => ({ ...state, balance })),

        setBeacon: (beacon: any) => set((state: TezosType) => ({ ...state, beacon })),
        setProvider: (provider: any) => set((state: TezosType) => ({ ...state, provider })),
        setSigner: (signer: any) => set((state: TezosType) => ({ ...state, signer })),

        clear: () => set(() => ({
            network: TEZOS_NETWORK,
            address: "",
            balance: 0,
            
            signer: null,
        })),
    })
))