import React from 'react';
import styles from './styles.module.scss'
import { Button, ButtonLink, Countdown, Label, LazyLoad } from '../design-system'
import { useEffect, useState } from 'react'
import { ethers } from "ethers"
import { getSigner } from "../../state/crypto/ethereumStore"
import { waitSignerEthereum } from '../../libs/crypto/crypto'
import { getAbi, getAbProjectInfo } from "../../utils/crypto"
import { findEthArtistName } from '../../components/token/token'
import { ARTBLOCKS_IMAGE_PREVIEW } from '../../constants';
import { getDateTime } from '../../utils/time';

export const ArtblockProject = ({
    id,
    title,
    projectId,
    mintPrice,
    artistAddress,
    contract,
    to
} : {
    id: number;
    title: string;
    projectId: any;
    mintPrice: string;
    artistAddress: string;
    contract: any;
    to: string;
}) => {

    const [availability, setAvailability] = useState<number>(0)
    const [maxTokens, setMaxTokens] = useState<number>(0)
    const [price, setPrice] = useState<number>(0)
    const [start, setStart] = useState<number>(0)
    const [artist, setArtist] = useState<string>('')

    useEffect(() => {
            const getArtblocksProjectInfo = async () => {
                try {
                    const projectDetails: any = await getAbProjectInfo(projectId)
                    const priceDetail: number = parseFloat(ethers.utils.formatEther(projectDetails.tokenPriceInWei))
                    setMaxTokens(Number(projectDetails.maxInvocations))
                    setAvailability(projectDetails.maxInvocations-Number(projectDetails.invocations))
                    setPrice(priceDetail)
                    setStart(Number(projectDetails.timestampStart)*1000)
                    setArtist(projectDetails.artist)
                } catch (error) {
                    console.error(error)
                }
            }
            getArtblocksProjectInfo()
    }, [])

    return (
        <a href={to} key={id}>
            <div className={styles.token} onClick={() => null}>
                <div className={styles.edit__container}>
                    <img
                        //src="/token_covers/KT1AAxomZ6cxN5zYj2efG1nQyM1ggab4e216_1.png"
                        alt="artblockstoken"
                        className={styles.event__cover}
                        src={ARTBLOCKS_IMAGE_PREVIEW+projectId.toString()+'0'.padStart(6, "0")+'.png'}
                    />
                    <div className={styles.artist__name_event_view}>
                        {artist? artist : 
                            artistAddress ? findEthArtistName(artistAddress) : 
                            "No Creator Found"
                        }
                    </div>
                    <Label>
                        <div className={styles.label__truncated}>{title || 'No Name Found'}</div>
                    </Label>
                    
                    <div className={styles.auction__history}>
                        <div className={styles.info__container}>

                          { start > new Date().getTime() ? 
                            <div className={styles.event__title} style={{ paddingTop: 0 }}>
                            Available on { getDateTime(start) }
                            </div>
                            : 
                            <>
                            <div>
                                <Label>Edition of {maxTokens} - {availability} left</Label>

                                <div className={styles.event__title} style={{ paddingTop: 0 }}>
                                    { (price + " ETH") }
                                </div>
                            </div>

                            <ButtonLink to={to} className={styles.bid__button}>
                                Mint
                            </ButtonLink> 
                            </>
                          }
                        </div>
                    </div>
                </div>
            </div>
        </a>
    )
}
