import { PROVIDER_NETWORK } from "../constants";
const {utils, ethers, BigNumber} = require('ethers');


export const replaceWithBr= (text: string) => {
    return text.replace(/\n/g, "<br />")
}

export const bidMapper = async (bids: any[]) => {

    const ethProvider =  new ethers.providers.InfuraProvider(PROVIDER_NETWORK, process.env.REACT_APP_INFURA_API_KEY);

    const newBidsPromises = bids.map(async(bid: any) => 
        {
            return {
            bidder: bid[0],
            bidderENS: await ethProvider.lookupAddress(bid[0]) || '',
            bidAmount: BigNumber.from(bid[1]),
            bidTimestamp: bid[2],
            bidAccepted: bid[3]
    }})
    
    const newBids = await Promise.all(newBidsPromises)

    return newBids
}

export const sortBids = (bids: any[]) => {
    return bids.sort((a,b) => b.bidAmount - a.bidAmount)
}