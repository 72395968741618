import classNames from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'

import { Button } from "../../design-system/buttons/button"
import styles from "./styles.module.scss"

export const WalletButton = ({
    className,
    onClick,
    children,
    logo,
    disabled,
    ...props
}: {
    className: string,
    onClick: () => void,
    children: React.ReactNode,
    logo?: string,
    disabled?: boolean,
    [key: string]: any,
}) => {
    const buttonClassNames = classNames(
        className,
        styles.button,
        disabled && styles.connected,
    )

    return (
        <div className={styles.button__container}>
            <AnimatePresence>
                {disabled && (
                    <motion.div
                        className={styles.connected__label}
                    
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}

                        transition={{
                            duration: 0.6,
                            ease: [0.43, 0.13, 0.23, 0.96]
                        }}
                    >
                        <span>Connected</span>
                    </motion.div>
                )}
            </AnimatePresence>

            <Button
                className={buttonClassNames}
                onClick={onClick}
                disabled={disabled}
                {...props}
            >
                <div className={styles.logo__container}>
                    <img className={styles.logo} src={logo}/>
                    {children}                
                </div>
            </Button>
        </div>
    )
}