import { useNavigate } from 'react-router'
import classNames from 'classnames'

import styles from './styles.module.scss'

const externalRedirect = (
    url: string,
    replace: boolean = false
) => {
    if (replace) {
        window.open(url)
    } else {
        window.location.assign(url)
    }
}

export const Button = ({
    children,
    className,
    onClick,
    disabled,
    ...props
}: {
    children?: React.ReactNode,
    className?: string,
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    disabled?: boolean,
    [key: string]: any,
}) => {
    const buttonClassName = classNames(
        styles.button,
        disabled && styles.disabled,
        className,
    )

    return (
        <button
            {...props}
            className={buttonClassName}
            onClick={!disabled ? onClick : undefined}
            disabled={disabled}
        >
            {children}
        </button>
    )
}

export const ButtonLink = ({
    children,
    className,
    to,
    replace,
    ...props
}: {
    children?: React.ReactNode,
    className?: string,
    to: string,
    replace?: boolean,
    external?: boolean,
    [key: string]: any,
}) => {
    const navigate = useNavigate()

    return (
        <Button
            className={className}
            onClick={() => props.external ? externalRedirect(to, replace) : navigate(to, { replace })}
            disabled={false}
        >
            {children}
        </Button>
    )
}