import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Label, Loader } from "../../components/design-system"
import { getCategories, ICategory } from "../admin/manage-event-categories"
import { Container, CustomPagination, LazyLoad } from '../../components/design-system'
import { IPFS_GATEWAY } from '../../constants'
import { getEvents, IEvent } from '../admin/manage-events'
import { motion } from 'framer-motion'
import { Event } from '../../components/event/event'

import styles from './styles.module.scss'

export const HomeEvent = () => {
    const [events, setEvents] = useState<IEvent[]>([])
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1);

    useEffect(() => {
        getEvents(setEvents).then(() => {
            setLoading(false)
        })
    }, [])

    // Display Residency 5th Cohort Event
    const residencyC6 = ((events: IEvent[]) => {
        return events.filter((event: IEvent) => event.slug === "vca-residency-6-cohort")
    })

    // Display Linea Recta
    const lineaRecta = ((events: IEvent[]) => {
        return events.filter((event: IEvent) => event.slug === "linea-recta")
    })

    // Display squiggles
    const squiggles = ((events: IEvent[]) => {
        return events.filter((event: IEvent) => event.slug === "snowfros-squiggles-the-auction")
    })
    // Display La Caverna
    const laCaverna = ((events: IEvent[]) => {
        return events.filter((event: IEvent) => event.slug === "la-caverna")
    })

     // Display Emanuele Pasin
     const spensieratezza = ((events: IEvent[]) => {
        return events.filter((event: IEvent) => event.slug === "spensieratezza")
    })

    // Display Expanded Art
    const expandedArt = ((events: IEvent[]) => {
        return events.filter((event: IEvent) => event.slug === "expanded-art-vca")
    })

    // Display Proof of People 2023 Event
    const proofOfPeople2023Event = ((events: IEvent[]) => {
        return events.filter((event: IEvent) => event.slug === "proof-of-people-x-refraction")
    })

    // Display Residency 5th Cohort Event
    const residency5thCohortEvent = ((events: IEvent[]) => {
        return events.filter((event: IEvent) => event.slug === "residency-c5")
    })

    // Display Proof of People Event
    const proofOfPeopleRefraction2023Event = ((events: IEvent[]) => {
        return events.filter((event: IEvent) => event.slug === "proof-of-people-refraction")
    })

    // Display All AB Tokens Event
    const allAbTokensEvent = ((events: IEvent[]) => {
        return events.filter((event: IEvent) => event.slug === "all-artblocks-tokens")
    })

    
    if (loading) {
        return (
            <Loader />
        )
    }

    return (
        <Container>

            <motion.div
                className={styles.events}

                initial={{ opacity: 0 }}
                animate={{
                    opacity: 1
                }}

                transition={{ duration: 0.5, delay: 1 }}
            >

                    {/*
                <div className={`${styles.event__wrapper}`}>
                    <h1 style={{ marginBottom: '1em'}}>Andrew Strauss Event</h1>
                    { andrewStraussEvent(events).map((event: IEvent) => (
                        
                        (<a href="https://proofofpeoplerefraction.xyz"  target="_blank" rel="noopener noreferrer">  
                        <div className={styles.dummy__dropdown}>
                        <Event event={event} />
                        </div>
                        </a>)
                        (<Link className={`${styles.link__wrapper}`}
                        to={"https://proofofpeoplerefraction.xyz" || "/404"} key={event.id}>
                        <Event event={event} />
                        </Link>)
                    ))}
                </div>
                    */ }
                

                <div className={`${styles.event__wrapper}`}>
                    <h1 style={{ marginBottom: '1em'}}>VCA Residency 6th Cohort Auction</h1>
                    { residencyC6(events).map((event: IEvent) => (
                        <Link className={`${styles.link__wrapper}`}
                        to={"/event/" + event?.id + "/all" || "/404"} key={event.id}>
                        <Event event={event} />
                        </Link>
                    ))}
                </div>

                <div className={`${styles.event__wrapper}`}>
                    <h1 style={{ marginBottom: '1em'}}>Linea Recta by Moodsoup</h1>
                    { lineaRecta(events).map((event: IEvent) => (
                        (<a className={`${styles.link__wrapper}`}
                        href="https://gallery.verticalcrypto.art/token/ethereum/0x32d4be5ee74376e08038d652d4dc26e62c67f436/15" key={event.id}>
                        <Event event={event} />
                        </a>)
                    ))}
                </div>

                <div className={`${styles.event__wrapper}`}>
                    <h1 style={{ marginBottom: '1em'}}>Snowfro's Squiggles: The Auction</h1>
                    { squiggles(events).map((event: IEvent) => (
                        (<a className={`${styles.link__wrapper}`}
                        href="https://gallery.verticalcrypto.art/bid/squiggles" key={event.id}>
                        <Event event={event} />
                        </a>)
                    ))}
                </div>
                <div className={`${styles.event__wrapper}`}>
                    <h1 style={{ marginBottom: '1em'}}>la caverna by Marcelo Soria-Rodríguez</h1>
                    { laCaverna(events).map((event: IEvent) => (
                        (<a className={`${styles.link__wrapper}`}
                        href="https://gallery.verticalcrypto.art/token/ethereum/0x32d4be5ee74376e08038d652d4dc26e62c67f436/12" key={event.id}>
                        <Event event={event} />
                        </a>)
                    ))}
                </div>

                <div className={`${styles.event__wrapper}`}>
                    <h1 style={{ marginBottom: '1em'}}>Spensieratezza by Emanuele Pasin</h1>
                    { spensieratezza(events).map((event: IEvent) => (
                        (<a className={`${styles.link__wrapper}`}
                        href="https://gallery.verticalcrypto.art/token/ethereum/0x32d4be5ee74376e08038d652d4dc26e62c67f436/13" key={event.id}>
                        <Event event={event} />
                        </a>)
                    ))}
                </div>

                <div className={`${styles.event__wrapper}`}>
                    <h1 style={{ marginBottom: '1em'}}>Expanded Art x VCA</h1>
                    { expandedArt(events).map((event: IEvent) => (
                        (<a className={`${styles.link__wrapper}`}
                        href={"/event/" + event?.id + "/all" || "/404"} key={event.id}>
                        <Event event={event} />
                        </a>)
                    ))}
                </div>

                <div className={`${styles.event__wrapper}`}>
                    <h1 style={{ marginBottom: '1em'}}>Proof of People x Refraction</h1>
                    { proofOfPeople2023Event(events).map((event: IEvent) => (
                        (<Link className={`${styles.link__wrapper}`}
                        to={"/event/" + event?.id + "/all" || "/404"} key={event.id}>
                        <Event event={event} />
                        </Link>)
                    ))}
                </div>

                <div className={`${styles.event__wrapper}`}>
                    <h1 style={{ marginBottom: '1em'}}>Residency 5th Cohort</h1>
                    { residency5thCohortEvent(events).map((event: IEvent) => (
                        (<Link className={`${styles.link__wrapper}`}
                        to={"/event/" + event?.id + "/all" || "/404"} key={event.id}>
                        <Event event={event} />
                        </Link>)
                    ))}
                </div>

                <div className={`${styles.event__wrapper}`}>
                    <h1 style={{ marginBottom: '1em'}}>Proof of People x Refraction</h1>
                    { proofOfPeopleRefraction2023Event(events).map((event: IEvent) => (
                        
                        (<a href="https://proofofpeoplerefraction.xyz"  target="_blank" rel="noopener noreferrer">  
                        <div className={styles.dummy__dropdown}>
                        <Event event={event} />
                        </div>
                        </a>)
                        /*
                        (<Link className={`${styles.link__wrapper}`}
                        to={"https://proofofpeoplerefraction.xyz" || "/404"} key={event.id}>
                        <Event event={event} />
                        </Link>)
                        */ 
                    ))}
                </div>

                <div className={`${styles.event__wrapper}`}>
                    <h1 style={{ marginBottom: '1em'}}>Art Blocks Engine</h1>
                    { allAbTokensEvent(events).map((event: IEvent) => (
                        (<Link className={`${styles.link__wrapper}`}
                        to={"/event/" + event?.id + "/all" || "/404"} key={event.id}>
                        <Event event={event} />
                        </Link>)
                    ))}
                </div>

            </motion.div>

            {/* <Masonry */}
        </Container>
    );
}
