import styles from './styles.module.scss'
import { Label, Button, Countdown } from '../../components/design-system';
import { useEffect, useState } from "react"
import { cleanHash } from '../../utils/crypto';
import { ARTBLOCKS_FLEX_CONTRACT_ADDRESS, ARTBLOCKS_GENERATOR_ADDRESS, ARTBLOCKS_IMAGE_PREVIEW, ARTBLOCKS_OPENSEA_LINK, IPFS_GATEWAY } from '../../constants';
import ImageIcon from '@mui/icons-material/Image';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Tooltip from '@mui/material/Tooltip';
import { getAbTokenMintInfo } from '../../utils/crypto';
import { getDateTime } from '../../utils/time';
import { shorten } from '../../utils/crypto';
import ArtblocksEngine from './images/artblocks.png';
import ArtblocksFlex from './images/artblocks-flex.png';
import OpenseaLogo from './images/opensea-logo.svg'
import SansaLogo from './images/sansa-logo.svg'

export const TokenSaleInfoBox = ({
    network,
    token = 'others',
    supply,
    balance,
    price,
    royalties,
    license,
    library,
    type = 'fixed',
    auctionDetails,
    releaseinfos,
    saleDuration,
    additionalInfos
}:{
    network: "ethereum" | "tezos";
    token?: string;
    supply: number;
    balance: number;
    price: number;
    royalties: number;
    license?: string;
    library?: string;
    type?: string;
    auctionDetails?: any;
    releaseinfos?: string;
    saleDuration?: number;
    additionalInfos?: string;
}) => {

    const endTimestamp = Number(releaseinfos || 0) + Number(saleDuration || 0 )*1000

    return(
        <>
        <div className={styles.editions__info}>
            { token.includes('artblocks') &&
                <>
                <div className={styles.editions__info__box}>
                    <Label>Powered by</Label>
                    <img src={token.includes('flex') ? ArtblocksFlex : ArtblocksEngine} style={{ width: "8rem", paddingLeft: "0.5rem", marginTop: "0.5rem" }} />
                </div>
                { additionalInfos && 
                <div style={{ marginBottom: "1rem" }}>
                    <Label>Charitable Giving</Label>
                    <div style={{ paddingLeft: "0.5rem" }}>
                        <span className={styles.da_label}>{additionalInfos}</span>
                    </div>
                </div>
                }
                </>
            }
            <div className={styles.editions__info__box}>
                <Label>{type == 'auction' && network == 'ethereum' ? 'Linear Dutch Auction' : 'Edition Price'}</Label>         
                { /** Artblocks DA */}
                { (type == 'auction' && network == 'ethereum' && auctionDetails) ?                             
                (<>
                <div style={{ paddingLeft: "0.5rem", marginBottom: "1rem" }}>
                    <h2>{`${auctionDetails.startPrice} -> ${auctionDetails.basePrice} ETH`}</h2>
                    <span className={styles.da_label}>Price decreases continuously and halves every {auctionDetails.timeInterval} minutes. <br />
                    Price updates every block (about 15 seconds)
                   </span>
                </div>
                 </>
                ) :
                (<h2 style={{ paddingLeft: "0.5rem"}}>{ network == "tezos" ? (price/1e6 + " XTZ") : (price/1e18 + " ETH")}</h2>) 
                }
                { /** Display limited edition drop info */}
                { (saleDuration && saleDuration > 0) && 
                    <div style={{ marginTop: "1rem", marginBottom: "0.5rem"}}>
                    <Label>Open { token.includes('artblocks') && 'algorithmic' } edition drop runs for</Label>
                    <h2 style={{ paddingLeft: "0.5rem"}}>{ saleDuration < 3600 ? `${saleDuration/60} minutes` : `${saleDuration/3600} hours`}</h2>
                    <div style={{paddingLeft: "0.5rem"}}>
                        {supply == 1000000 ? (
                        <span className={styles.da_label}>Sale will only be active for this duration and is an Open Algorithmic Edition</span>
                            ):(
                                <span className={styles.da_label}>Sale will only be active for this duration and capped at {supply} {token.includes('artblocks') && 'algorithmic'} editions</span>
                            )}
                    </div>
                    </div>
                }
            </div>
            <div className={styles.editions__info__box}>
                { /** If timed edition */}
                { (saleDuration && saleDuration > 0) ?
                  ( (new Date().getTime() >= Number(releaseinfos)) &&
                    <>
                    <Label>Algorithmic editions created</Label>
                    <h2 style={{ paddingLeft: "0.5rem", marginBottom:"1rem"}}>{supply-balance}</h2>
                    </>
                  )
                  :
                (
                    <>
                    <Label>Availability</Label>
                    <h2 style={{ paddingLeft: "0.5rem", marginBottom:"1rem"}}>{balance} of {supply}</h2>
                    </>
                )
                }

                { releaseinfos && 
                <><Label>Released on</Label>
                <h2 style={{ paddingLeft: "0.5rem"}}>{getDateTime(Number(releaseinfos))}</h2></>
                }
            </div>
            { /** Only display sale countdown once sale starts and within sale period */}
            { (releaseinfos && saleDuration && saleDuration > 0 && 
               (new Date().getTime() < endTimestamp) && 
               (new Date().getTime() >= Number(releaseinfos)) && balance > 0 ) &&
                
                <div className={styles.editions__info__box}>
                    <Label>Sale ends in</Label>
                    <h2 style={{ marginLeft: "-1rem" }}>
                        <Countdown message="" target={new Date(endTimestamp)} className={styles.countdown}/>
                    </h2>
                </div>
            }
        </div>{ /*
        <div className={styles.royalties__info__box}>   
            <Label>
                Royalties {royalties} %
            </Label>                          
            </div> */}
        </>
    )
}

export const TokenExternalLinks = ({
    network,
    contract,
    id,
    ipfsImage,
    balance,
    collection
}:{
    network: "ethereum" | "tezos";
    contract: string;
    id: number;
    ipfsImage?: string;
    balance: number;
    collection: string;
}) => {
    return(
        <div className={styles.links}>
            { network == "tezos" ?
            (
            <>
            <div className={styles.link}>
                <a className={styles.link__container} href={`https://objkt.com/asset/${contract}/${id}`} target="_blank" rel='noopener noreferrer'>
                    <Label center className={styles.link__label}>View on OBJKT</Label>
                </a>
            </div>
            <div className={styles.link} style={{ borderLeft: '1px solid var(--f40)' }}>
                <a className={styles.link__container} href={`https://tzkt.io/${contract}/tokens/${id}`} target="_blank" rel='noopener noreferrer'>
                    <Label center className={styles.link__label}>View on TZKT</Label>
                </a>
            </div>
            </>
            ):(
            <>
            {balance != 0 ? (
                null
            ):(
                <div className={styles.link} style={{ borderRight: '1px solid var(--f40)' }}>
                    <a className={styles.link__container} href={`https://opensea.io/assets/ethereum/${contract}/${id}`} target="_blank" rel='noopener noreferrer'>
                        <Label center className={styles.link__label}>View on OpenSea</Label>
                    </a>
                </div>
            )}
            { collection && (
                <div className={styles.link} style={{ borderRight: '1px solid var(--f40)' }}>
                    <a className={styles.link__container} href={collection} target="_blank" rel='noopener noreferrer'>
                        <Label center className={styles.link__label}>View Collection on OpenSea</Label>
                    </a>
                </div>
            )}
              <div className={styles.link} style={{ borderRight: '1px solid var(--f40)' }}>
                <a className={styles.link__container} href={`https://etherscan.io/address/${contract}`} target="_blank" rel='noopener noreferrer'>
                    <Label center className={styles.link__label}>View on Etherscan</Label>
                </a>
              </div>
            </>
            )}
            { ipfsImage && (
            <div className={styles.link} >
                <a className={styles.link__container} href={IPFS_GATEWAY + cleanHash(ipfsImage)} target="_blank" rel='noopener noreferrer'>
                    <Label center className={styles.link__label}>View on IPFS</Label>
                </a>
            </div>
            )}

        </div>
    )
}

// Links for AB specific tokens
export const ArtBlockLinks = ({
    displayedId,
    projectId,
    openSeaCollectionLink,
    sansaCollectionLink
}:{
    displayedId: string,
    projectId: number,
    openSeaCollectionLink: string,
    sansaCollectionLink: string
}) => {
    return (
        <>
        <div className={styles.link}>
          <a className={styles.link__container} href={sansaCollectionLink} target="_blank" rel='noopener noreferrer'>
            <Tooltip title="View on Sansa">
                <img src={SansaLogo} className={styles.icon__style} style={{ width: "1.3rem" }}/>
            </Tooltip>
          </a>
        </div>
        {/*
        <div className={styles.link}>
          <a className={styles.link__container} href={openSeaCollectionLink} target="_blank" rel='noopener noreferrer'>
            <Tooltip title="View on OpenSea">
                <img src={OpenseaLogo} className={styles.icon__style} style={{ width: "1.3rem" }}/>
            </Tooltip>
          </a>
        </div>
        */}
        <div className={styles.link}>
          <a className={styles.link__container} href={ARTBLOCKS_IMAGE_PREVIEW+projectId.toString()+displayedId+'.png'} target="_blank" rel='noopener noreferrer'>
            <Tooltip title="View Image">
                <ImageIcon 
                    className={styles.icon__style}
                />
            </Tooltip>
          </a>
        </div>
        <div className={styles.link}>
          <a className={styles.link__container} href={ARTBLOCKS_GENERATOR_ADDRESS+projectId.toString()+displayedId} target="_blank" rel='noopener noreferrer'>
            <Tooltip title="View Live View">
                <AspectRatioIcon 
                    className={styles.icon__style}
                />
            </Tooltip>
          </a>
        </div>
    </>
    )
}

// Links for AB specific tokens
export const ArtBlockLicenseAndLibrary = ({
    license,
    library
}:{
    license: string,
    library: string
}) => {
    return (
        <>
        <div className={styles.links}>
        {(license && library) && (
            <div className={styles.link} style={{ borderLeft: '1px solid var(--f40)' }}>
                <Label center className={styles.link__label}>License</Label>
                <h3>{license}</h3> 
            </div>
        )}
        {(license && library) && (
            <div className={styles.link} style={{ borderLeft: '1px solid var(--f40)', borderRight: '1px solid var(--f40)' }}>
                <Label center className={styles.link__label}>Library</Label>
                <h3>{library}</h3> 
            </div>
        )}
        </div>
    </>
    )
}

// Infos for AB specific tokens - Display Notes
export const ArtBlockDisplayNotes = () => {
    const [artBlockNotes, setArtBlockNotes] = useState<boolean>(false)

    const openNotes = () => {
        setArtBlockNotes(!artBlockNotes);
    }
    return (
        <>
        <div className={styles.links}>
            <div className={styles.notes__info}>
                <Button className={styles.notes__button} onClick={openNotes}>
                    Display Notes &nbsp;&nbsp;
                    {artBlockNotes ? (
                        <RemoveIcon 
                            className={styles.notes__icon__style}
                        />
                    ):(
                        <>
                            <Tooltip title="Click to append">
                                <AddIcon 
                                    className={styles.notes__icon__style}
                                />
                            </Tooltip>
                        </>
                    )}
                </Button>
                {artBlockNotes ? (
                    <div className={styles.notes__info__text}>
                        WebGL is required. The rendering could be memory-intensive for some mobile devices, potentially leading to a browser crash on memory-limited devices. A modern browser on a desktop is recommended for optimal performance.
                    </div>
                ):(
                    null
                )}
            </div>
        </div>
    </>
    )
}

// Infos for AB specific tokens - Display Notes
export const ArtBlockDisplayAdditionalDetails = ({
    license,
    library
}:{
    license: string,
    library: string
}) => {
    const [artBlockNotes, setArtBlockNotes] = useState<boolean>(false)

    const openNotes = () => {
        setArtBlockNotes(!artBlockNotes);
    }
    return (
        <>
        <div className={styles.links}>
            <div className={styles.notes__info}>
                <Button className={styles.notes__button} onClick={openNotes}>
                    Additional Infos &nbsp;&nbsp;
                    {artBlockNotes ? (
                        <RemoveIcon 
                            className={styles.notes__icon__style}
                        />
                    ):(
                        <>
                            <Tooltip title="Click to append">
                                <AddIcon 
                                    className={styles.notes__icon__style}
                                />
                            </Tooltip>
                        </>
                    )}
                </Button>
                {artBlockNotes ? (
                    <>
                    <div className={styles.additional__info__text}>
                        <div className={styles.additional__info__headline}>
                            Sales Notes:
                        </div>
                        <div>
                            24-hour timed edition at the fixed price of 0.06 ETH
                        </div>
                    </div>
                    <div className={styles.links}>
                    {library ? (
                        <>
                        <div className={styles.link}>
                            <Label center className={styles.link__label}>Library</Label>
                            <h3>{library}</h3> 
                        </div>
                        </>
                    ):(
                        null
                    )}
                    {license ? (
                        <>
                        <div className={styles.link}>
                            <Label center className={styles.link__label}>License</Label>
                            <h3>{license}</h3> 
                        </div>
                        </>
                    ):(
                        null
                    )}
                    </div>
                    </>
                ):(
                    null
                )}
            </div>
        </div>
    </>
    )
}

export const IndividualTokenInfo = ({
    projectId,
    tokenId,
    supply
}: {
    projectId: number,
    tokenId: string,
    supply: number
}) => {

    const [owner, setOwner] = useState<string>("")
    const [mintedDate, setMintedDate] = useState<string>("")

    const getIndividualTokenInfo = async(id: string) => {
        const mintData:any = await getAbTokenMintInfo(id)

        if (mintData) {
            setOwner(mintData.owner.id)
            setMintedDate(mintData.createdAt)
        }
    }

    useEffect(() => {
        const id = projectId.toString()+tokenId.toString().padStart(6, "0")
        getIndividualTokenInfo(`${ARTBLOCKS_FLEX_CONTRACT_ADDRESS.toLowerCase()}-${id}`)
    }, [])
    return (
        <div className={styles.editions__info}>
            <div className={styles.editions__info__box}>
                <Label>Token</Label>
                <h2 style={{ paddingLeft: "0.5rem" }}>{tokenId} of {supply}</h2>
            </div>
            <div className={styles.editions__info__box}>
                <Label>Minted on</Label>
                <h2 style={{ paddingLeft: "0.5rem" }}>{getDateTime(Number(mintedDate)*1000)}</h2>
            </div>
            <div className={styles.editions__info__box}>
                <Label>Owned by</Label>
                <a href={`https://etherscan.io/address/${owner}`}><h2 style={{ paddingLeft: "0.5rem", marginBottom:"1rem"}}>{shorten(owner, 10)}</h2></a>
            </div>
        </div>
    )
}
