import { useEffect, useState } from 'react'
import classNames from 'classnames'

import { Dropdown } from '../dropdown/dropdown'

import styles from './styles.module.scss'

export const Input = ({
    placeholder,
    value,
    onChange,
    className,
    disabled,
    ...props
} : {
    placeholder: string,
    value: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    className?: string,
    disabled?: boolean,
    [key: string]: any,
}) => {
    return (
        <input
            className={classNames(styles.input, className)}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            {...props}
        />
    )
}

export const NumberInput = ({
    value,
    onChange,
    className,
    disabled,
    ...props
}: {
    value: number,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void, 
    className?: string,
    disabled?: boolean,
    [key: string]: any,
}) => {
    return (
        <input
            className={classNames(styles.input, disabled && styles.disabled, className)}
            type="number"
            value={value}
            onChange={onChange}
            disabled={disabled}
            {...props}
        />
    )
}

export const FileInput = ({
    accept,
    message = "Click to Upload",
    onChange = () => {},
    className,
    disabled,
    ...props
}: {
    accept: string,
    message?: string,
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    className?: string,
    disabled?: boolean,
    [key: string]: any,
}) => {
    const classes = classNames(styles.file__input, disabled, className)

    return (
        <label className={classes}>
            <input
                type="file"
                accept={accept}
                onChange={(e) => onChange(e)}

                className={styles.file__input}
            />

            <span className={styles.upload__text}>{message}</span>
        </label>
    )
}

export const DateInput = ({
    value,
    onChange = () => {},
    className,
    disabled,
    ...props
}: {
    value: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    className?: string,
    disabled?: boolean,
    [key: string]: any,
}) => {
    return (
        <input
            className={classNames(styles.date__input, className)}
            type="date"
            value={value}
            onChange={onChange}
            disabled={disabled}
            {...props}
        />
    )
}

export const TimeInput = ({
    value,
    onChange,
    className,
    disabled,
    ...props
}: {
    value: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    className?: string,
    disabled?: boolean,
    [key: string]: any,
}) => {
    return (
        <input
            className={classNames(styles.time__input, className)}
            type="time"
            value={value}
            onChange={onChange}
            disabled={disabled}
            {...props}
        />
    )
}

export const DropdownInput = ({
    list,
    value,
    onChange,
    className,
    disabled,
    max = false,
    ...props
}: {
    list: string[],
    value: string,
    onChange: (item: string) => void,
    className?: string,
    disabled?: boolean,
    max?: boolean,
    [key: string]: any,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [selected, setSelected] = useState(list[0])

    useEffect(() => {
        setSelected(value)
    }, [value])

    return (
        <Dropdown className={classNames(styles.dropdown__container, className)}>
            <Dropdown.Trigger setActive={setIsOpen} onClick={() => { if (!disabled) { setIsOpen(!isOpen) } }} className={classNames(styles.dropdown__trigger, disabled && styles.disabled)}>
                <div>
                    {selected}
                </div>

                <svg className={styles.dropdown__icon} xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M12 15.375 6 9.375 7.4 7.975 12 12.575 16.6 7.975 18 9.375Z"/></svg>
            </Dropdown.Trigger>

            <Dropdown.Body isOpen={isOpen} className={styles.dropdown__body} max={max}>
                <Dropdown.Items>
                    {list.map((item, index) => (
                        <Dropdown.Item key={index} onClick={() => {
                            setSelected(item)
                            onChange(item)
                        }}>
                            {item}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Items>
            </Dropdown.Body>
        </Dropdown>
    )
}

export const CheckBoxInput = ({
    onChange = () => {},
    className,
    disabled,
    ...props
}: {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    className?: string,
    disabled?: boolean,
    [key: string]: any,
}) => {
    return (
        <input
            className={classNames(styles.checkbox__input, className)}
            type="checkbox"
            onChange={onChange}
            disabled={disabled}
            {...props}
        />
    )
}