import { ManageEvents } from "../../pages/admin/manage-events"
import { AdminDashboard } from "../../pages/admin/dashboard"

import type { IRoutes } from "../../types/routes"
import { Navigate } from "../../utils/redirect/redirect"
import { ManageUsers } from "../../pages/admin/manage-users"
import { ManageEventCategories } from "../../pages/admin/manage-event-categories"
import { ManageTokens } from "../../pages/admin/manage-tokens"
import { ManageSwaps } from "../../pages/admin/manage-swaps"
import { CreateProjectShell } from "../../pages/admin/create-project-shell"

import { Clearance } from "../../containers/clearance"

export const routes: IRoutes = {
    routerId: 'adminRouter',
    depth: 2,
    pages: [
        {
            path: '',
            element: () => <Navigate to="dashboard" replace/>,
            animate: false,
        },
        {
            path: '/dashboard',
            element: AdminDashboard,
            animate: false,
        },
        {
            path: '/manage-users',
            element: () => (
                <Clearance required={3}>
                    <ManageUsers />
                </Clearance>
            ),
            animate: false,
        },
        {
            path: '/manage-event-categories',
            element: ManageEventCategories,
            animate: false,
        },
        {
            path: '/manage-events',
            element: ManageEvents,
            animate: false,
        },
        {
            path: '/manage-tokens/*',
            element: ManageTokens,
            animate: false,
        },
        {
            path: '/manage-swaps/*',
            element: ManageSwaps,
            animate: false,
        },
        {
            path: '/create-project-shell',
            element: CreateProjectShell,
            animate: false,
        }
    ],
}