import styles from './styles.module.scss'

export const Container = ({
    children,
}: {
    children: React.ReactNode,
}) => (
    <div className={styles.container}>
        {children}
    </div>
)
