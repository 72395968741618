import create from "zustand"
import { persist } from "zustand/middleware"

import { fetchGraphcms, gql } from "../../libs/graphcms"

import type { AccountType } from "../../types/account-types"

const checkClearance = (account: any) => {
    if (account.roles.includes("superAdmin")) {
        return 3
    }

    if (account.roles.includes("admin")) {
        return 2
    }

    if (account.roles.includes("creator")) {
        return 1
    }

    return 0
}

export const getAccountId = async ({
    tezosAddress,
    ethereumAddress,
}: {
    tezosAddress?: string,
    ethereumAddress?: string,
}) => {
    const accountState = accountStore.getState()

    if (!tezosAddress && !ethereumAddress) return setTimeout(() => accountState.setId(""), 500)

    if (!tezosAddress) {
        tezosAddress = "_";
    }
    if (!ethereumAddress) {
        ethereumAddress = "_";
    }
    await fetchGraphcms({
        key: process.env.REACT_APP_GRAPHCMS_USER_KEY,
        query: gql.query.queryAccount,
        variables: {
            tezos: tezosAddress,
            ethereum: ethereumAddress,
        },
    }).then(async (response) => {
        if (response.accounts.length === 0) {
            return accountState.setId("")
        }

        const clearance = checkClearance(response.accounts[0])
        accountState.setClearance(clearance)
        return accountState.setId(response.accounts[0].id)
    }).catch((error) => {
        console.error(error)
    })
}

export const accountStore = create<AccountType>(
    (set, get) => ({
        id: "",
        clearance: 0,

        setId: (id: string) => set((state: AccountType) => ({ ...state, id })),
        setClearance: (clearance: number) => set((state: AccountType) => ({ ...state, clearance })),

        clear: () => set(() => ({
            id: "",
        })),
    }),
)