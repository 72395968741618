import { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router'
import { Button, Container, Label, Loader, NumberInput, Countdown } from '../../components/design-system'
import { Media } from '../../components/token/media'
import { ARTBLOCKS_FLEX_CONTRACT_ADDRESS, PROVIDER_NETWORK, IPFS_GATEWAY } from '../../constants'
import { waitSignerEthereum, waitSignerTezos } from '../../libs/crypto/crypto'
import { getToken } from '../admin/manage-tokens/tokens'
import { checkProvider } from '../../libs/crypto/tezos'
import { ethereumStore, getProvider, getSigner } from '../../state/crypto/ethereumStore'
import { tezosStore } from '../../state/crypto/tezosStore'
import { notificationStore } from '../../state/global/notificationStore'
import { getMetadata, cleanHash, getHistory, shorten, getAbi, getVCAMetadata, endAuction } from '../../utils/crypto'
import { TokenSaleInfoBox, TokenExternalLinks } from './saleinfo'
import { getDateTime } from '../../utils/time'
import { OpKind } from '@taquito/taquito'
import axios from 'axios'
import ReactMarkdown from 'react-markdown'

import styles from './styles.module.scss'
import { ethers } from 'ethers'
import { isMobileDevice, mobileDeepLink } from '../../libs/crypto/ethereum'

import { findXTZArtistName, findEthArtistName } from '../../components/token/token'
import { ArtBlocksViewer } from './artblocks-display'

const VCAFixedPrice = require('../../constants/vcafixedprice-abi.json')

const query__swap__data = `
    query querySwapDataForToken($fa2_contract: String!, $tokenId: String!, $vcaAddress: String!, $minterAddress: String!) {
        listing(where: {_and: [{fa_contract: {_eq: $fa2_contract}}, {token: {token_id: {_eq: $tokenId}}},{amount_left: {_gt: 0}},{ status: {_eq: "active"}}, {_or: [{seller_address: {_eq: $vcaAddress}}, {seller_address: {_eq: $minterAddress}}]}]}, order_by: {timestamp: desc}) {
            amount
            amount_left
            price
            bigmap_key
            token {
              display_uri
              name
              supply
            }
            shares
            seller_address
        }
    }  
`

export const TokenPage = () => {
    const notifications = notificationStore()
    const tezosState = tezosStore()
    const ethereumState = ethereumStore()

    const navigate = useNavigate()
    const location = useLocation()
    const { additionalInfo } = location.state as any || {};
    const [saleType, setSaleType] = useState<string>("")
    const [saleStart, setSaleStart] = useState<string>("")
    const [saleCollection, setSaleCollection] = useState<string>("")

    const [loading, setLoading] = useState(true)
    const [broken, setBroken] = useState(false)

    const [metadata, setMetadata] = useState<any>({})
    const [history, setHistory] = useState<any>({})
    const [altMetadata, setAltMetadata] = useState<any>({})

    const [swapped, setSwapped] = useState<any[]>([])
    const [collectId, setCollectId] = useState<any[]>([])
    const [collectPrice, setCollectPrice] = useState<any[]>([])
    
    const [bid, setBid] = useState<number>(0)
    const [ready, setReady] = useState(false)

    let timeout: any = useRef()

    const network: "ethereum" | "tezos" = window.location.pathname.split("/")[2] || "ethereum" as any
    const contract = window.location.pathname.split("/")[3]
    const id = parseInt(window.location.pathname.split("/")[4])
    
    const refreshHistory = async () => {
        const history = await getHistory({
            network,
            contract,
            id,
        })

        setHistory(history)

        timeout = setTimeout(() => {
            refreshHistory()
        }, 60000)
    }

    const fetchVCAMetadata = async (custom: boolean = false) => {
        try {
            const structuredMetadata = await getVCAMetadata({
                contract,
                id,
                custom: custom
            })

            setMetadata(structuredMetadata)
            setLoading(false)
        } catch (error) {
            setBroken(true)
            console.log(error)
        }
    }

    const fetchMetadata = async (curator: string) => {
        try {
            const metadata:any = await getMetadata({
                network,
                contract,
                id,
            })

            // If metadata is not pulled correctly from ZORA API
            if(metadata && metadata?.nft?.metadataURI && !metadata?.metadata?.name) {
                const data = await fetch(IPFS_GATEWAY+metadata?.nft?.metadataURI)
                const alternateMetadata = await data.json()
                setAltMetadata(alternateMetadata)
            }

            setMetadata(metadata)


            if(network == "tezos"){
                fetchSwap(metadata, curator)
            }
            
        } catch (error) {
            setBroken(true)
            console.log(error)
        }
    }

    const fetchHistory = async () => {
        try {
            const history = await getHistory({
                network,
                contract,
                id,
            })

            setHistory(history)
            setLoading(false)
        } catch (error) {
            setBroken(true)
            console.log(error)
        }
    }

    /**
    useEffect(() => {

        const fetchVCAMetadata = async () => {

            try {
                const structuredMetadata = await getVCAMetadata({
                    contract,
                    id
                })

                setMetadata(structuredMetadata)
                setLoading(false)
            } catch (error) {
                setBroken(true)
                console.log(error)
            }
        }

        

        if(saleType.toLowerCase().includes('vca-fixed')) {
           fetchVCAMetadata()
        } 
        else {
          fetchMetadata().then(() => {
            fetchHistory()
          })
        }

        refreshHistory()

        return () => {
            clearTimeout(timeout)
        }
        
    }, [saleType])
     */

    useEffect(() => {
        if (!broken) return

        const timeout = setTimeout(() => {
            navigate("/", { replace: true })
        }, 1500)

        return () => clearTimeout(timeout)
    }, [broken])

    // Grabs token info from DB on mount
    useEffect(() => {

        // Check if Artblocks token 
        if (contract !== ARTBLOCKS_FLEX_CONTRACT_ADDRESS) {
            const getTokenFromDB = async () => {
                const data: any = await getToken(contract, network, id)
                setSaleType(data.tokens[0].saleType)
                setSaleStart(data.tokens[0].saleStart)
                setSaleCollection(data.tokens[0].collection)
                const curatorAddress = data.tokens[0].event.account.tezos || data.tokens[0].event.account.ethereum

                if(data.tokens[0].saleType.toLowerCase().includes('vca-fixed')) {
                    fetchVCAMetadata()
                } 

                else if(data.tokens[0].saleType.toLowerCase().includes('vca-custom-fp') && network=="ethereum") {
                    fetchVCAMetadata(true)
                }
                
                else {
                fetchMetadata(curatorAddress).then(() => {
                    fetchHistory()
                })
                }
        
                refreshHistory()
        
                return () => {
                    clearTimeout(timeout)
                }
            }

            getTokenFromDB();
        }

        else {
            setLoading(false)
        }

    }, [])

    const fetchSwap = async (metadata: any, curator: string) => {

        try {
            
            let xtzCreatorAddress = metadata?.creators[0].creator_address
                const response = await axios.post(`https://data.objkt.com/v3/graphql`, {
                    query: query__swap__data,
                    variables: {
                        fa2_contract: contract,
                        tokenId: id.toString(),
                        vcaAddress: curator,
                        minterAddress: xtzCreatorAddress
                    },
                    operationName: 'querySwapDataForToken'
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                setSwapped(response.data.data.listing)
                setLoading(false)
        } catch (error) {
            setBroken(true)
            console.log(error)
        }
    }

    const handleChangeBid = (amount: number) => {
        setBid(amount)

        let min: number = 0;
        if (network === "ethereum") {
            min = (history.bidEvents.length === 0 ?
            history.reservePrice / 1e18 : history.bidEvents[0].value / 1e18)
        } else if (network === "tezos") {
            min = (history.bids.length === 0 ? history.reserve / 1e6 : history.bids[0].amount / 1e6) + (history.bids.length === 0 ? 0 : (history.price_increment / 1e6))
        }

        if (amount < min) {
            setReady(false)
        } else {
            setReady(true)
        }
    }

    const handleSubmitBid = async () => {
        const notifId = notifications.addNotification({
            message: "Submitting Bid",
            status: "pending",
        })

        if (network === "ethereum") {
            try {
                const signer = getSigner()
                const provider = getProvider()

                if (!signer) {
                    await waitSignerEthereum(window)
                }

                let overrides = {
                    value: ethers.utils.parseEther((bid).toString())
                }

                const abi = await getAbi("0xe468ce99444174bd3bbbed09209577d25d1ad673")
                const auctionContract = new ethers.Contract("0xe468ce99444174bd3bbbed09209577d25d1ad673", abi, signer)

                const tx = await auctionContract.createBid(
                    history.auctionId,
                    ethers.utils.parseEther((bid).toString()),
                    // contract,
                    overrides
                )

                await provider.waitForTransaction(tx.hash);

                notifications.setNotificationMessage({
                    id: notifId,
                    message: "Bid Submitted"
                })

            } catch (error) {
                console.log(error)

                notifications.setNotificationMessage({
                    id: notifId,
                    message: "Error Submitting Bid"
                })
    
                notifications.reject(notifId)
            }
        } else if (network === "tezos") {            
            try {
                if (!tezosState?.signer) {
                    await waitSignerTezos()
                }

                const Tezos = tezosState.provider
                const addr = tezosState.address

                const wrapContract = await Tezos.wallet.at("KT1TjnZYs5CGLbmV6yuW169P8Pnr9BiVwwjz")
                const auctionContract = await Tezos.wallet.at("KT18p94vjkkHYY3nPmernmgVR7HdZFzE7NAk")
    
                let list = [
                    {
                        kind: OpKind.TRANSACTION,
                        ...wrapContract.methods.wrap(
                            addr
                        ).toTransferParams(),
                        amount: bid,
                        storageLimit: 310,
                        source: addr
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...wrapContract.methods.approve(
                            "KT18p94vjkkHYY3nPmernmgVR7HdZFzE7NAk",
                            bid * 1e6
                        ).toTransferParams(),
                        amount: 0,
                        storageLimit: 310,
                        source: addr
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...auctionContract.methods.bid(
                            history.bigmap_key,
                            bid * 1e6
                        ).toTransferParams(),
                        storageLimit: 310,
                        source: addr,
                        amount: 0
                    }
                ]

                const batch = Tezos.wallet.batch(list)
                const confirm = await batch.send()

                await confirm.confirmation(2)
    
                notifications.setNotificationMessage({
                    id: notifId,
                    message: "Bid Submitted"
                })
    
                notifications.resolve(notifId)    
            } catch (error) {
                notifications.setNotificationMessage({
                    id: notifId,
                    message: "Error Submitting Bid"
                })
    
                notifications.reject(notifId)
            }
        }
    }

    function checkForMultipleCreators (creators: any) {
        if(creators.length > 1){
            for (let i = 0; i < creators.length; i++) {
                const artistFound = findXTZArtistName(creators?.[i].creator_address)
                if(artistFound.slice(0, 2) != "tz"){
                    return artistFound;
                }
            }
        }
        return findXTZArtistName(creators?.[0].creator_address);
    }
    const handleSubmitCollect = async () => {
        const notifId = notifications.addNotification({
            message: "Collecting",
            status: "pending",
        })
        
            try {
                if (!tezosState?.signer) {
                    await waitSignerTezos()
                }

                const Tezos = tezosState.provider
                const addr = tezosState.address

                const COLLECT_CONTRACT = await Tezos.wallet.at("KT1WvzYHCNBvDSdwafTHv7nJ1dWmZ8GCYuuC")

                const price_in_mutez:any = collectPrice; 
                const price_in_tez:any = price_in_mutez / 1000000

                let op = await COLLECT_CONTRACT.methods.fulfill_ask([collectId]).send({ amount: price_in_tez })
                
                await op.confirmation(2)

                console.log(op)
    
                notifications.setNotificationMessage({
                    id: notifId,
                    message: "Bid Submitted"
                })
    
                notifications.resolve(notifId)    
            } catch (error) {
                console.log(error)
                notifications.setNotificationMessage({
                    id: notifId,
                    message: "Error Submitting Collect"
                })
    
                notifications.reject(notifId)
            }

    }

    const handleSubmitMint = async (contract: string, dropId: number, price: number) => {
        const notifId = notifications.addNotification({
            message: "Minting...",
            status: "pending",
        })

        if (window.ethereum?.isMetaMask) {
            const provider = new ethers.providers.InfuraProvider(PROVIDER_NETWORK, process.env.REACT_APP_INFURA_API_KEY);

            try {
                const signer = getSigner()

                if (!signer) {
                    await waitSignerEthereum(window)
                }

                let overrides = {
                    value: price
                }

                const VCAFixedPriceContract = new ethers.Contract(contract, VCAFixedPrice.abi, signer)
                const tx = await VCAFixedPriceContract.mint(dropId, overrides)
                
                await provider.waitForTransaction(tx.hash);

                // Get the transaction receipt
                const receipt = await provider.getTransactionReceipt(tx.hash);

                // Check the status of the transaction
                if (receipt.status === 1) {
                    notifications.setNotificationMessage({
                    id: notifId,
                    message: "Token succesfully minted"
                    })
            
                    notifications.resolve(notifId)                   

                } else {
                    // Catch any errors
                    notifications.setNotificationMessage({
                        id: notifId,
                        message: "Error minting token"
                    })

                    notifications.reject(notifId)
                }
            } catch (error) {
            
                console.log(error)

                notifications.setNotificationMessage({
                    id: notifId,
                    message: "Error minting token"
                })

                notifications.reject(notifId)
            }
        } 

        else if (isMobileDevice()){
            mobileDeepLink(window.location.pathname)
        } 

        else {
            console.log("No Metamask wallet detected")

            notifications.setNotificationMessage({
                id: notifId,
                message: "Error minting token"
            })

            notifications.reject(notifId)
        }
    }

    // Function that handles the ending of zora auctions when button is clicked
    const handleEndAuction = async () => {

        const notifId = notifications.addNotification({
            message: "Ending auction",
            status: "pending",
        })

        try {
            const signer = getSigner()

            if (!signer) {
                await waitSignerEthereum(window)
            }

            // end auction - returns 1 or 0 depending on whether tx is successful
            const tx:number = await endAuction(history.auctionId)
            
            if (tx == 1) {
             
                notifications.setNotificationMessage({
                id: notifId,
                message: "Successfully ended auction id: " + history.auctionId
                })
        
                notifications.resolve(notifId)                   

            } else {

                // Catch any errors
                notifications.setNotificationMessage({
                    id: notifId,
                    message: "Error ending auction"
                })

                notifications.reject(notifId)
            }

        } catch (error) {
        
            console.log(error)

            notifications.setNotificationMessage({
                id: notifId,
                message: "Error ending auction"
            })

            notifications.reject(notifId)
        }
    }
    

    if (loading) {
        return <Loader />
    }

    if (broken) {
        return (
            <Container>
                <div className={styles.container}>
                    <div>
                        This token doesn't seem to exist.
                    </div>
                    <Label>Bringing you home</Label>
                </div>
            </Container>
        )
    }

    if (contract == ARTBLOCKS_FLEX_CONTRACT_ADDRESS) {
        return (
            <ArtBlocksViewer projectId={id} setBroken={setBroken} />
        )
    } else {

    return (
        metadata && Object.keys(metadata).length == 0 || history && Object.keys(history).length == 0 ? (
            <Loader />
        ) : (
        <Container>
            <div className={styles.token__container}>
                <div className={styles.media__container}>
                    {metadata && Object.keys(metadata).length !== 0 ? (
                        network === "tezos" ? (
                            <Media
                                mediaType={metadata?.mime?.split('/')[0]}
                                cid={cleanHash(metadata?.artifact_uri) || ''}
                                placeholderCid={cleanHash(metadata?.display_uri) || ''}
                                displayCid={(metadata?.mime?.split('/')[0] === 'audio') ? cleanHash(metadata?.display_uri) : ''}
                                className={styles.event__cover}
                                alt="Token Cover"

                                fill
                            />
                        ) : (
                            <Media
                                mediaType={metadata?.metadata?.raw?.contentType?.split('/')[0] || metadata?.content?.mimeType?.split('/')[0] || altMetadata?.contentType?.split('/')[0] || 'image' }
                                cid={cleanHash(metadata?.metadata?.raw?.contentURI) || cleanHash(metadata?.metadata?.contentUri) || cleanHash(metadata?.metadata?.raw?.image) || cleanHash(altMetadata?.contentURI) || cleanHash(altMetadata?.image) || "" }
                                placeholderCid={cleanHash(metadata?.metadata?.raw?.contentURI) || cleanHash(metadata?.metadata?.contentUri) || cleanHash(metadata?.metadata?.raw?.image) || cleanHash(altMetadata?.contentURI) || cleanHash(altMetadata?.image) || ""}
                                displayCid={metadata?.metadata?.raw?.contentType?.split('/')[0] === 'audio' ? cleanHash(metadata?.media?.image?.uri) : ''}
                                className={styles.event__cover}
                                alt="Token Cover"

                                fill
                            />
                        )
                    ) : (
                        <div className={styles.broken}>
                            Error Loading Metadata
                            <Label>Check Token Info</Label>
                        </div>
                    )}
                </div>



                <div className={styles.info__container}>
                    <h1 className={styles.info__title}>
                        {network === "ethereum" ? (
                            <>
                            {contract == '0x4926c8057b3d9f8638fe7b2573757f429648ebfc' ? (
                                metadata?.metadata?.name + ' #' + id || "No Title Found"
                            ):(
                                metadata?.metadata?.name || altMetadata?.name || "No Title Found"
                            )}
                            </>
                        ) : (
                            metadata?.name || "No Title Found"
                        )}
                        
                    </h1>

                    <Label className={styles.creator}>
                        {network === "ethereum" ? (
                            "Created by " + metadata?.nft?.minted?.address && findEthArtistName(metadata?.nft?.minted?.address)
                        ) : (
                            "Created by " + (metadata?.creators?.length > 0) && checkForMultipleCreators(metadata?.creators)
                        )}
                    </Label>

                    {additionalInfo && (
                    <div className={styles.additionalinfo}>
                        <ReactMarkdown>{additionalInfo}</ReactMarkdown>
                    </div>
                    )}

                    <div className={styles.info__description}>
                        {network === "ethereum" ? (
                            metadata?.metadata?.description || altMetadata?.description || "No Description Found"
                        ) : (
                            metadata?.description || "No Description Found"
                        )}
                    </div>

                    <br />

                    <br />
                    
            {/* Display available on date if event has not started */}
            { saleStart && saleStart >= new Date().toISOString() ?        
            (
                <>
              <p className={styles.sold_out}>Available on { getDateTime(saleStart) }</p>
              { id == 68 ? (
                  
                  <div className={styles.bid__container}>
                  <NumberInput
                      value={bid}
                      onChange={(e) => handleChangeBid(parseFloat(e.target.value))}
                      type="number"
                      placeholder="0"
  
                      step={0.01}
                      min={
                          history.bidEvents.length === 0 ?
                              history.reservePrice / 1e18 :
                              history.bidEvents.length > 0 && history.bidEvents[0].value / 1e18
                      }
  
                      className={styles.bid__input}
                  />
  
                  <Button
                      className={styles.bid__submit}
                      onClick={() => handleSubmitBid()}
  
                      disabled={!ready}
                  >
                      Place Bid
                  </Button>
              </div>
              ):(
                  null
              )}
              </>
            ):
            (
            <>
            {/* If token is an auction token */}
            { saleType == "auction" &&
                (
                <>
                    {history ? (<>
                        {network === "ethereum" ? (
                            history?.bidEvents?.length > 0 && history.bidEvents[0].sender === ethereumState?.address && (
                                <div className={styles.top__bidder}>
                                    You're the Top Bidder!
                                </div>
                            )
                        ) : (
                            history?.bids?.length > 0 && history.bids[0].bidder_address === tezosState?.address && (
                                <div className={styles.top__bidder}>
                                    You're the Top Bidder!
                                </div>
                            )
                        )}
                        <div className={styles.auction__container}>
                            <div className={styles.auction__price}>
                                <Label>

                                    {network === "ethereum" ? (
                                        (history.status === "ENDED" || (history.expiresAt &&  new Date(history.expiresAt) <= new Date())) ? "Sold For" :
                                        history.bidEvents?.length === 0 ? "Reserve Price" :
                                        history.bidEvents?.length > 0 && "Current Bid"
                                    ) : (
                                        history.status === "concluded" ? 
                                        (history.highest_bid === null ? "" : "Sold For") :
                                        history.bids?.length === 0 ? "Reserve Price" :
                                        history.bids?.length > 0 && "Current Bid"
                                    )}
                                </Label>
                                <h2
                                    style={{
                                        paddingLeft: "0.5rem",
                                    }}
                                >
                                    {network === "ethereum" ? (
                                        (history.status === "ENDED" || (history.expiresAt && new Date(history.expiresAt) <= new Date())) ? history.bidEvents[0].value :
                                        history.bidEvents?.length === 0 ? history.reservePrice :
                                        history.bidEvents?.length > 0 && history.bidEvents[0].value) / 1e18 + " ETH"
                                     : (
                                        (history.highest_bid === null && history.status === "concluded") ? "In the auction house" :
                                        (history.status === "concluded" ? history.highest_bid :
                                        history.bids.length === 0 ? history.reserve :
                                        history.bids.length > 0 && history.highest_bid) / 1e6 + " XTZ")
                                    }
                                </h2>
                            </div>
                            <br/>
                            {network === "ethereum" ? (
                                (["APPROVED", "IN_PROGRESS"].includes(history.status)) && (
                                    (new Date(history.expiresAt) < new Date()) ? (
                                      <Button 
                                        style={{ width: "100%" }}
                                        className={styles.swap__submit}
                                        onClick={() => handleEndAuction()}
                                      >
                                      End auction
                                      </Button>
                                    ) : (
                                      <div className={styles.auction__price}>
                                        <h2>
                                            <Countdown message="Sales end in" target={
                                                new Date(history?.expiresAt)
                                            } className={styles.countdown}/> 
                                        </h2>
                                      </div>
                                    )
                                )
                            ) : (
                                history.status === "active" && (
                                    <div>
                                        <div className={styles.bid__container}>
                                            <Countdown message="Sales end in" target={
                                                new Date(history?.end_time)
                                            } className={styles.countdown}/>
                                        </div>
                                    </div>
                                )
                            )}
                            {network === "ethereum" ? (
                                (["APPROVED", "IN_PROGRESS"].includes(history.status) && (new Date(history.expiresAt) > new Date() || !history.expiresAt)) && (
                                    <div>
                                        <div className={styles.bid__container}>
                                            <NumberInput
                                                value={bid}
                                                onChange={(e) => handleChangeBid(parseFloat(e.target.value))}
                                                type="number"
                                                placeholder="0"

                                                step={0.01}
                                                min={
                                                    history.bidEvents.length === 0 ?
                                                        history.reservePrice / 1e18 :
                                                        history.bidEvents.length > 0 && history.bidEvents[0].value / 1e18
                                                }

                                                className={styles.bid__input}
                                            />

                                            <Button
                                                className={styles.bid__submit}
                                                onClick={() => handleSubmitBid()}

                                                disabled={!ready}
                                            >
                                                Place Bid
                                            </Button>
                                        </div>
                                        <Label>Bid must be no smaller than {
                                                history.bidEvents.length === 0 ?
                                                    history.reservePrice / 1e18 :
                                                    history.bidEvents.length > 0 && history.bidEvents[0].value / 1e18
                                        } ETH. Bids are non-cancellable.</Label>
                                    </div>
                                )
                            ) : (
                                history.status === "active" && (
                                    <div>
                                        <div className={styles.bid__container}>
                                            <NumberInput
                                                value={bid}
                                                onChange={(e) => handleChangeBid(parseFloat(e.target.value))}
                                                type="number"
                                                placeholder="0"

                                                step={0.01}
                                                min={
                                                    (history.bids.length === 0 ? history.reserve / 1e6 : history.bids[0].amount / 1e6) + (history.bids.length === 0 ? 0 : (history.price_increment / 1e6))
                                                }

                                                className={styles.bid__input}
                                            />

                                            <Button
                                                className={styles.bid__submit}
                                                onClick={() => handleSubmitBid()}

                                                disabled={!ready}
                                            >
                                                Place Bid
                                            </Button>
                                        </div>
                                        <Label>Bid must be no smaller than {
                                            (history.bids.length === 0 ? history.reserve / 1e6 : history.bids[0].amount / 1e6) + (history.bids.length === 0 ? 0 : (history.price_increment / 1e6))
                                        } XTZ. Bids are non-cancellable.</Label>
                                    </div>
                                )
                            )}


                            <div className={styles.links}>
                                <div className={styles.link}>
                                    {network === "ethereum" ? (
                                        <>
                                        <a className={styles.link__container} href={`https://opensea.io/assets/ethereum/${contract}/${id}`} target="_blank" rel='noopener noreferrer'>
                                            <Label center className={styles.link__label}>View on OpenSea</Label>
                                        </a>
                                        </>
                                    ): (
                                        <a className={styles.link__container} href={`https://objkt.com/asset/${contract}/${id}`} target="_blank" rel='noopener noreferrer'>
                                            <Label center className={styles.link__label}>View on Objkt</Label>
                                        </a>
                                    )}
                                </div>
                                <div className={styles.link} style={{ borderLeft: '1px solid var(--f40)' }}>
                                    {network === "ethereum" ? (
                                        <a className={styles.link__container} href={`https://etherscan.io/nft/${contract}/${id}`} target="_blank" rel="noopener noreferrer">
                                            <Label center className={styles.link__label}>View on Etherscan</Label>
                                        </a>
                                    ): (
                                        <a className={styles.link__container} href={`https://tzkt.io/${contract}/tokens/${id}`} target="_blank" rel='noopener noreferrer'>
                                            <Label center className={styles.link__label}>View on TZKT</Label>
                                        </a>
                                    )}
                                </div>
                                <div className={styles.link} style={{ borderLeft: '1px solid var(--f40)' }}>
                                    {network === "ethereum" ? (
                                        <a className={styles.link__container} href={IPFS_GATEWAY + cleanHash(metadata?.metadata?.raw?.contentURI)} target="_blank" rel="noopener noreferrer">
                                            <Label center className={styles.link__label}>View on IPFS</Label>
                                        </a>
                                    ): (
                                        <a className={styles.link__container} href={IPFS_GATEWAY + cleanHash(metadata?.display_uri)} target="_blank" rel='noopener noreferrer'>
                                            <Label center className={styles.link__label}>View on IPFS</Label>
                                        </a>
                                    )}
                                </div>
                            </div>

                            <div className={styles.auction__history}>
                                <Label left className={styles.history__item}>Auction History</Label>

                                {network === "ethereum" ? (
                                    history.bidEvents?.length === 0 ? (
                                        <Label left className={styles.history__item}>No Bids</Label>  
                                    ) : (
                                        history.bidEvents?.map((bid: any, index: number) => (
                                            <a className={styles.history__item} style={{ textDecoration: 'none' }} href={`https://etherscan.io/tx/${bid.transactionHash}`} target="_blank" rel="noopener noreferrer" key={index}>
                                                <div className={styles.history__item__inner}>
                                                    <h3 style={{ color: 'var(--f90)' }}>
                                                        {findEthArtistName(bid.sender) + " bid " + (bid.value / 1e18).toFixed(3) + " ETH"}
                                                    </h3>

                                                    <Label className={styles.timestamp}>
                                                        {getDateTime(bid.blockTimestamp)}
                                                    </Label>
                                                </div>
                                            </a>
                                        ))
                                    )
                                ) : (
                                    history.bids?.length === 0 ? (
                                        <Label left className={styles.history__item}>No Bids</Label>
                                    ) : (
                                        history.bids?.map((bid: any, index: number) => (
                                            <a className={styles.history__item} style={{ textDecoration: 'none' }} href={"https://tzkt.io/" + bid.ophash} target="_blank" rel='noopener noreferrer' key={index}>
                                                <div className={styles.history__item__inner}>
                                                    <h3 style={{ color: 'var(--f90)' }}>
                                                        {findXTZArtistName(bid.bidder_address) + " bid " + (bid.amount / 1e6).toFixed(3) + " XTZ"}
                                                    </h3>

                                                    <Label className={styles.timestamp}>
                                                        {getDateTime(bid.timestamp)}
                                                    </Label>
                                                </div>
                                            </a>
                                        )
                                    ))
                                )}
                            </div>
                        </div>
                    </>
                    ):(
                    <>
                        No auction history
                    </>
                    )}
                </>
                )
            } 
            
            {/* If token is a swap/sale */}
            { saleType == "swap" &&
                (
                <>
                    {swapped.length != 0 ? (
                    <>
                      {swapped && (
                      <>
                        {swapped.map((swap) => (
                            <>
                            <div className={styles.auction__container}>
                                {metadata.royalties[0].decimals > 3 ? (
                                <TokenSaleInfoBox
                                    network="tezos"
                                    supply={swap.token.supply}
                                    balance={swap.amount_left}
                                    price={swap.price}
                                    royalties={metadata.royalties[0].amount/100}
                                />   
                                ):( 
                                    <TokenSaleInfoBox
                                        network="tezos"
                                        supply={swap.token.supply}
                                        balance={swap.amount_left}
                                        price={swap.price}
                                        royalties={metadata.royalties[0].amount/10}
                                    />   
                                )}
                                <div>
                                    <div className={styles.bid__container}>                           
                                    <Button
                                        style={{ width: "100%" }}
                                        className={styles.swap__submit}
                                        onClick={() => {
                                            // set params required by OBJKT API 
                                            
                                            setCollectId(swap.bigmap_key || parseInt("0"))
                                            setCollectPrice(swap.price || "")
                                            handleSubmitCollect()
                                        }}
                                    >
                                        Collect for {swap.price/1000000}&nbsp;XTZ
                                    </Button>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <TokenExternalLinks
                                network="tezos"
                                contract={contract}
                                id={id}
                                ipfsImage={metadata?.display_uri}
                                balance={swap.amount_left}
                                collection={saleCollection}
                            />
                        </>
                        ))}
                      </>
                      )}
                    </>
                    ):(
                    <>
                        <TokenExternalLinks
                        network="tezos"
                        contract={contract}
                        id={id}
                        ipfsImage={metadata?.display_uri}
                        balance={0}
                        collection={saleCollection}
                        />
                        <br/>
                        
                        <p className={styles.sold_out}>Sold out</p>
                    </>
                    )}
                    </>
                )
            }

            {/** If token is a VCA Fixed Sale (ETH) */}
            { saleType == "vca-fixed" && (
              metadata.nft.drop.balance > 0 ? (
                <>
                <div className={styles.auction__container}>
                  <TokenSaleInfoBox
                    network="ethereum"
                    supply={metadata.nft.drop.supply}
                    balance={metadata.nft.drop.balance}
                    price={metadata.nft.drop.price}
                    royalties={metadata.nft.drop.royalties/100}
                  />                      
                  <div>
                    <div className={styles.bid__container}>                           
                      <Button 
                        style={{ width: "100%" }}
                        className={styles.swap__submit}
                        onClick={() => handleSubmitMint(contract, id, metadata.nft.drop.price)}
                      >
                      Mint for {metadata.nft.drop.price/1e18}&nbsp;ETH
                      </Button>
                    </div>
                  </div>
                </div>
                <br/>
                  <TokenExternalLinks
                    network="ethereum"
                    contract={contract}
                    id={id}
                    ipfsImage={metadata?.metadata?.raw?.contentURI}
                    balance={metadata.nft.drop.balance}
                    collection={saleCollection}
                  />
                </>
            ):(
              <>
                <TokenExternalLinks
                  network="ethereum"
                  contract={contract}
                  id={id}
                  ipfsImage={metadata?.metadata?.raw?.contentURI}
                  balance={metadata.nft.drop.balance}
                  collection={saleCollection}
                />
                <br/>
                <p className={styles.sold_out}>Sold out</p>
              </> 
            ))}

            {/** If token is a VCA Fixed Sale (ETH) */}
            { saleType == "vca-custom-fp" && (
              metadata.nft.drop.balance > 0 ? (
                <>
                <div className={styles.auction__container}>
                  <TokenSaleInfoBox
                    network="ethereum"
                    supply={metadata.nft.drop.supply}
                    balance={metadata.nft.drop.balance}
                    price={metadata.nft.drop.price}
                    royalties={metadata.nft.drop.royalties/100}
                  />            
                  <div>
                    <div className={styles.bid__container}>                           
                      <Button 
                        style={{ width: "100%" }}
                        className={styles.swap__submit}
                        onClick={() => handleSubmitMint(contract, id, metadata.nft.drop.price)}
                      >
                      Mint for {metadata.nft.drop.price/1e18}&nbsp;ETH
                      </Button>
                    </div>
                  </div>
                </div>
                <br/>
                  <TokenExternalLinks
                    network="ethereum"
                    contract={contract}
                    id={id}
                    ipfsImage={metadata?.metadata?.raw?.contentURI}
                    balance={metadata.nft.drop.balance}
                    collection={saleCollection}
                  />
                </>
            ):(
              <>
                <TokenExternalLinks
                  network="ethereum"
                  contract={contract}
                  id={id}
                  ipfsImage={metadata?.metadata?.raw?.contentURI}
                  balance={metadata.nft.drop.balance}
                  collection={saleCollection}
                />
                <br/>
                <p className={styles.sold_out}>Sold out</p>
              </> 
            ))}
            </>
            )}
            </div>
          </div>
        </Container>
        )
    )
    }
}