import { useRef, useState } from 'react'
import { useLocation } from "react-router"
import { CreatorRouter } from "../../routers/creator/creator-router"
import { Dropdown } from "../../components/design-system"
import { notificationStore } from "../../state/global/notificationStore"
import { useDetectClickOutside } from "../../components/design-system"
import { getActivePage } from '../admin'

import styles from './styles.module.scss'

export const Creator = () => {
    const notifications = notificationStore()

    const location = useLocation()

    const dropdownRef = useRef<HTMLDivElement>(null)
    const [isOpen, setIsOpen] = useState(false)
    
    useDetectClickOutside(dropdownRef, () => setIsOpen(false))

    return (
        <div>
            <div className={styles.title__container}>
                <h1 className={styles.title}>
                    Creator Panel
                </h1>
                
                <Dropdown className={styles.dropdown__container}>
                    <Dropdown.Trigger setActive={setIsOpen} onClick={() => setIsOpen(!isOpen)} className={styles.dropdown__trigger}>
                        <div>
                            {getActivePage(location.pathname)}
                        </div>

                        <svg className={styles.dropdown__icon} xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 0 24 24" width="36px" fill="#000000"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"/></svg>
                    </Dropdown.Trigger>

                    <Dropdown.Body isOpen={isOpen} className={styles.dropdown__body}>
                        <Dropdown.Items>
                            <Dropdown.ItemLink to="dashboard">
                                Dashboard
                            </Dropdown.ItemLink>
                            <Dropdown.ItemLink to="mint">
                                Mint Tokens
                            </Dropdown.ItemLink>
                            <Dropdown.ItemLink to="auction">
                                Auction Tokens
                            </Dropdown.ItemLink>
                            <Dropdown.ItemLink to="collection">
                                Create Collection
                            </Dropdown.ItemLink>
                            <Dropdown.ItemLink to="project-shells">
                                Manage Project Shells
                            </Dropdown.ItemLink>
                        </Dropdown.Items>
                    </Dropdown.Body>
                </Dropdown>

            </div>

            <CreatorRouter />
        </div>
    )
}