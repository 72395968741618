import { useState } from 'react'

import { DropdownInput, Input } from "../design-system"

import styles from './styles.module.scss'

export const Search = ({
    options,
    placeholder,
    allData,
    setNewData,
    resetPage
}:{
    options: string[];
    placeholder: string,
    allData: any[] | undefined,
    setNewData: any
    resetPage: any
}) => {

    const [selectedOption, setSelectedOption] = useState<string>(options[0]);
    const [searchText, setSearchText] = useState("");

    const handleInputChange = (newOption: string, newSearch: string) => {
        let filteredResults
        setSearchText(newSearch)
        setSelectedOption(newOption)

        if (newOption == 'name' || newOption == 'fa2_address' ) {
            filteredResults = allData?.filter(data => {
            return data[newOption].toLowerCase().includes(newSearch.toLowerCase());
            })
        } else {
            filteredResults = allData?.filter(data => {
                return data[newOption].toString().includes(newSearch); 
            })
        }

        setNewData(filteredResults)
        resetPage(1)
    }

    return (
        <div className={styles.search__container}>                
            <DropdownInput
                className={styles.filter__dropdown}
                list={options}
                value={selectedOption}
                onChange={(option) => handleInputChange(option, searchText)} />
            <Input 
                placeholder={placeholder}
                value={searchText}
                onChange={(e) => handleInputChange(selectedOption, e.target.value)} />   

            <input type="submit" value="Submit" hidden />
        </div>
    )
}