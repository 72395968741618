import { ConnectWallet } from "../../components/connect-wallet"
import { motion } from "framer-motion"

export const Wallet = () => {
    return (
        // <motion.div
        //     initial={{ opacity: 0 }}
        //     animate={{ opacity: 1 }}
        //     exit={{ opacity: 0 }}
        // >
            <ConnectWallet />
        // </motion.div>
    )
}