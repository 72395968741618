import React, { useEffect } from 'react'
import { GlobalRouter } from './routers/global-router'
import { Header } from './components/header'

import { Notifications } from './components/design-system/notification'
import { Button } from './components/design-system'

import { notificationStore } from './state/global/notificationStore'
import { tezosStore } from './state/crypto/tezosStore'
import { ethereumStore } from './state/crypto/ethereumStore'
import { getAccountId } from './state/global/accountStore'

import './styles/index.scss'

function App() {
  const tezosState = tezosStore()
  const ethereumState = ethereumStore()

  const notifications = notificationStore()

  useEffect(() => {
    getAccountId({
      ethereumAddress: ethereumState.address,
      tezosAddress: tezosState.address
    })
  }, [, ethereumState.address, tezosState.address])

  return (<>
      <Notifications />
      <Header />
      <div style={{ marginTop: '100px' }}>
        <GlobalRouter />
      </div>
  </>);
}

export default App;
