import { useState, useRef } from "react"
import { useLocation } from "react-router"

import { Dropdown, useDetectClickOutside } from "../../components/design-system"
import { AdminRouter } from "../../routers/admin/admin-router"

import { notificationStore } from "../../state/global/notificationStore"

import styles from './styles.module.scss'

export const getActivePage = (pathname: string) => {
    const path = pathname.split('/')
    return path[2]
}

export const Admin = () => {
    const notifications = notificationStore()

    const location = useLocation()

    const dropdownRef = useRef<HTMLDivElement>(null)
    const [isOpen, setIsOpen] = useState(false)
    
    useDetectClickOutside(dropdownRef, () => setIsOpen(false))
    
    return (
        <div>
            <div className={styles.title__container}>
                <h1 className={styles.title}>
                    Admin Panel
                </h1>
                
                <Dropdown className={styles.dropdown__container}>
                    <Dropdown.Trigger setActive={setIsOpen} onClick={() => setIsOpen(!isOpen)} className={styles.dropdown__trigger}>
                        <div>
                            {getActivePage(location.pathname)}
                        </div>

                        <svg className={styles.dropdown__icon} xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 0 24 24" width="36px" fill="#000000"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"/></svg>
                    </Dropdown.Trigger>

                    <Dropdown.Body isOpen={isOpen} className={styles.dropdown__body}>
                        <Dropdown.Items>
                            <Dropdown.ItemLink to="dashboard">
                                Dashboard
                            </Dropdown.ItemLink>
                            <Dropdown.ItemLink to="manage-users">
                                Manage Users
                            </Dropdown.ItemLink>
                            <Dropdown.ItemLink to="manage-tokens">
                                Manage Tokens
                            </Dropdown.ItemLink>
                            <Dropdown.ItemLink to="manage-swaps">
                                Manage Swaps
                            </Dropdown.ItemLink>
                            <Dropdown.ItemLink to="manage-events">
                                Manage Events
                            </Dropdown.ItemLink>
                            <Dropdown.ItemLink to="manage-event-categories">
                                Manage Categories
                            </Dropdown.ItemLink>
                            <Dropdown.ItemLink to="create-project-shell">
                                Create Project Shell
                            </Dropdown.ItemLink>
                        </Dropdown.Items>
                    </Dropdown.Body>
                </Dropdown>

            </div>
            <AdminRouter />
        </div>
    );
}// TODO: Replace Page