import { useState, useEffect } from 'react'
import Masonry from '@mui/lab/Masonry'

import { IPFS_GATEWAY, DEFAULT_CURATOR_ID, DEFAULT_CURATOR_TZ_ADDRESS } from '../../../constants'
import { fetchGraphcms, gql } from "../../../libs/graphcms"

import { FileInput, Input, Label, Modal, Button, TimeInput, DateInput, CheckBoxInput, Container, Dropdown } from '../../../components/design-system'
import styles from './styles.module.scss'
import { notificationStore } from '../../../state/global/notificationStore'
import { upload } from '../../../libs/pinata'
import { LazyLoad } from '../../../components/design-system/lazy/lazy'
import { DropdownInput, NumberInput } from '../../../components/design-system/inputs/inputs'
import { accounts } from '../../../libs/graphcms/users'
import { IAccount } from '../../admin/manage-users'
import { shorten } from '../../../utils/crypto'

export interface IEvent {
    id?: string;
    name: string;
    tagline: string;
    cover?: string;
    slug: string;
    eventUrl?: string;
    startDate: string;
    endDate: string;
    curatorFee: number;
    account?: any;
    curatorId: string;
    categoryId?: string;
    eventCategory?: any;
    tokens?: any[];
    eventType: string;
    subevents?: any[];
    displayCover: boolean;
    description?: string;
}

export interface ICategory {
    id?: string;
    name: string;
    cover?: string;
    slug: string;
}

export const getEvents = async (set: any) => {
    await fetchGraphcms({
        key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
        query: gql.query.queryLatestEvents,
        variables:{
            network: `mainnet`
        }
    }).then((response) => {
        const events = response.events

        set(events)
    }).catch((error) => {
        console.log(error)
    })
}

export const getUnpublishedEvents = async (set: any) => {
    await fetchGraphcms({
        key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
        query: gql.query.queryUnpublishedEvents
    }).then((response) => {
        const events = response.events

        set(events)
    }).catch((error) => {
        console.log(error)
    })
}

const getCategories = async (set: any) => {
    await fetchGraphcms({
        key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
        query: gql.query.queryEventCategories
    }).then((response) => {
        const categories = response.eventCategories

        set(categories)
    }).catch((error) => {
        console.log(error)
    })
}

const getCurators = async (set: any) => {
    await await fetchGraphcms({
        key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
        query: gql.query.queryAccounts
    }).then((response) => {
        let curators = response.accounts

        // Displays only accounts with roles 'curators' and their 
        curators = curators.filter((c: IAccount) => c.roles?.includes("curator"))

        set(curators)
    }).catch((error) => {
        console.log(error)
    })
}

const addEvent = async ({ event, type, disconnect }: { event: IEvent, type: "add" | "edit", disconnect: boolean }) => {
    return new Promise<string>(async (resolve, reject) => {
        let query = type === "add" ? (
            event.categoryId ? gql.mutation.addEvent.categorized : gql.mutation.addEvent.uncategorized
        ) : (
            event.categoryId ? gql.mutation.editEvent.categorized : gql.mutation.editEvent.uncategorized
        )

        if (disconnect) {
            query = type === "add" ? gql.mutation.addEvent.disconnect : gql.mutation.editEvent.disconnect
        }

        try {
            const data = await fetchGraphcms({
                key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
                query: query,
                variables: {
                    id: event.id,
                    name: event.name,
                    tagline: event.tagline,
                    slug: event.slug,
                    cover: event.cover,
                    startDate: event.startDate + ".000Z",
                    endDate: event.endDate + ".000Z",
                    curatorFee: event.curatorFee,
                    curatorId: event.curatorId,
                    categoryId: event.categoryId,
                }
            })

            const id: string = type === 'add' ? data.createEvent.id : event.id
            resolve(id)
        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

const publishEvent = async (id: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await fetchGraphcms({
                key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
                query: gql.mutation.publishEvent,
                variables: {
                    id
                }
            })

            resolve(res)
        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

const unpublishEvent = async (id: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await fetchGraphcms({
                key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
                query: gql.mutation.unpublishEvent,
                variables: {
                    id
                }
            })

            resolve(res)
        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

const removeEvent = async (id: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await fetchGraphcms({
                key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
                query: gql.mutation.removeEvent,
                variables: {
                    id
                }
            })

            resolve(res)
        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

export const ManageEvents = () => {
    const notifications = notificationStore()

    const [categories, setCategories] = useState<ICategory[]>([])
    const [category, setCategory] = useState('No Category')

    const [events, setEvents] = useState<IEvent[]>([])
    const [publishedEvents, setPublishedEvents] = useState<IEvent[]>([])

    const [openAddEvent, setOpenAddEvent] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState<IEvent>()

    const [name, setName] = useState('')
    const [tagline, setTagline] = useState('')
    const [cover, setCover] = useState('')
    const [slug, setSlug] = useState('')
    const [startDate, setStartDate] = useState(new Date().toISOString().split('.')[0])
    const [endDate, setEndDate] = useState(new Date().toISOString().split('.')[0])

    const [curatorFee, setCuratorFee] = useState<number | 10>(10)
    
    const [curators, setCurators] = useState<IAccount[]>([])
    const [curator, setCurator] = useState('Default Curator')
    const [curatorTezosAddress, setCuratorTezosAddress] = useState(DEFAULT_CURATOR_TZ_ADDRESS)

    const [publishEventVAR, setPublishEventVAR] = useState(false)

    const [ready, setReady] = useState(false)
    const [timeReady, setTimeReady] = useState(false)

    useEffect(() => {
        getUnpublishedEvents(setEvents)
        getCategories(setCategories)
        getCurators(setCurators)
        getEvents(setPublishedEvents)
    }, [])

    useEffect(() => {
       // remove special characters in event name and then replace all spaces with dash
       const truncatedSlug = name.replace(/[^a-zA-Z0-9 ]/g, '');
       setSlug(truncatedSlug.replace(/\s/g, '-').toLowerCase())
    }, [name])

    useEffect(() => {
        if (new Date(startDate) < new Date(endDate)) {
            setTimeReady(true)
        } else {
            setTimeReady(false)
        }
    }, [startDate, endDate])

    useEffect(() => {
        if (cover && name && slug && timeReady && curatorFee && curator) {
            setReady(true)
        } else {
            setReady(false)
        }
    }, [
        name,
        cover,
        slug,
        timeReady,
        curatorFee,
        curator
    ])

    const handleCheckboxChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setPublishEventVAR(e.target.checked)
    }

    const handleUploadCover = async (files: FileList | null) => {
        if (!files || !files.length) return

        const file = files[0]

        const notifID = notifications.addNotification({
            message: 'Uploading Cover',
            status: 'pending',
        })

        try {
            const cid: any = await upload({ file })
            notifications.setNotificationMessage({
                id: notifID,
                message: 'Cover Uploaded',
            })
            notifications.resolve(notifID)

            setCover('ipfs://' + cid.IpfsHash)
        } catch (error) {
            console.log(error)
            notifications.reject(notifID)
        }
    }

    const handleAddEvent = async (type: "add" | "edit") => {
        if (!name || !slug) return

        const notifID = notifications.addNotification({
            message: type === 'add' ? 'Adding Event' : 'Editing Event',
            status: 'pending',
        })

        try {
            const res = await addEvent({
                event: {
                    id: selectedEvent?.id,
                    name,
                    tagline,
                    slug,
                    cover,
                    startDate,
                    endDate,
                    curatorFee,
                    curatorId: curators.find((c: IAccount) => c.tezos === curatorTezosAddress)?.id || DEFAULT_CURATOR_ID,
                    categoryId: categories.find((c: ICategory) => c.name === category)?.id,
                    eventType: "",
                    displayCover: false,
                    description: '',

                },
                type,
                disconnect: type === 'edit' && (events.find((e: IEvent) => e.id === selectedEvent?.id)?.eventCategory && category === 'No Category')
            })

            notifications.setNotificationMessage({
                id: notifID,
                message: 'Adding Event',
            })

            if (publishEventVAR) {
                await publishEvent(res)

                notifications.setNotificationMessage({
                    id: notifID,
                    message: 'Published Event',
                })
            } 
            
            if (type == "edit" && !publishEventVAR)
            {
                await unpublishEvent(res)

                notifications.setNotificationMessage({
                    id: notifID,
                    message: 'Unpublished Event',
                })
            }

            notifications.resolve(notifID)

            getUnpublishedEvents(setEvents).then(() => {
                setCover('')
                setName('')
                setTagline('')
                setSlug('')
                setCuratorFee(10)
                setCurator(curators[0].username || 'Default Curator')
                setCuratorTezosAddress(DEFAULT_CURATOR_TZ_ADDRESS)
                setCategory('No Category')

                setStartDate(new Date().toISOString().split('.')[0])
                setEndDate(new Date().toISOString().split('.')[0])

                setOpenAddEvent(false)
                setSelectedEvent(undefined)
                setPublishEventVAR(false)
            })
        } catch (error) {
            console.log(error)
            notifications.reject(notifID)
        }
    }

    // Function that handles Curator dropdown input change
    // Require to set curator address to handle conflicts around similar names
    const handleCuratorChange = (curator: string) => {
        let curatorName = curator.split(' (')[0]
        const trunAddressStr = curator.split('(')[1].slice(0, -1) // retrieve address tez addr in xxxxx...xxxxx
        const tzAddress =  curators.find(curator => (curator.tezos?.slice(0,5) == trunAddressStr.split('...')[0] &&
                                                            curator.tezos?.slice(-5) == trunAddressStr.split('...')[1]))?.tezos
        setCuratorTezosAddress(tzAddress || 'DEFAULT_CURATOR_TZ_ADDRESS');
        setCurator(curatorName)
    }

    return (
        <Container>
            <Masonry columns={{ xs: 1, sm: 2, lg: 4 }}>
                {events.map((event: IEvent) => (
                    <div className={styles.event} key={event.id}>
                        <div className={styles.edit__container}>
                            <svg onClick={() => {  
                                setCover(event?.cover || '')
                                setName(event.name || '')
                                setTagline(event.tagline || '')
                                setSlug(event.slug || '')
                                setCuratorFee(event.curatorFee)
                                setCurator(event.account?.username || 'Default Curator')
                                setCuratorTezosAddress(event.account?.tezos || DEFAULT_CURATOR_TZ_ADDRESS)
                                setCategory(event?.eventCategory?.name || 'No Category')
                                setPublishEventVAR(publishedEvents.find((e) => e.id === event.id) ? true : false);

                                setStartDate(new Date(event.startDate).toISOString().split('.')[0] || '')
                                setEndDate(new Date(event.endDate).toISOString().split('.')[0] || '')

                                setSelectedEvent(event)
                            }} className={styles.edit__icon} xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                                <path d="M5 19H6.4L16.45 8.975L15.75 8.25L15.025 7.55L5 17.6ZM3 21V16.75L16.45 3.325Q17.025 2.75 17.863 2.75Q18.7 2.75 19.275 3.325L20.675 4.75Q21.25 5.325 21.25 6.15Q21.25 6.975 20.675 7.55L7.25 21ZM19.25 6.15 17.85 4.75ZM16.45 8.975 15.75 8.25 15.025 7.55 16.45 8.975Z"/>
                            </svg>

                            <LazyLoad
                                className={styles.event__cover}

                                src={IPFS_GATEWAY + (event?.cover || 'ipfs://QmT7h1TJbHeUGKtGNJE6EYgNVcTTpxXuyeXqNxsyoT8cde').split('ipfs://')[1]}
                                placeholderSrc={IPFS_GATEWAY + (event?.cover || 'ipfs://QmT7h1TJbHeUGKtGNJE6EYgNVcTTpxXuyeXqNxsyoT8cde').split('ipfs://')[1] + '?img-quality=5'}
                                alt="event Cover"
                            />
                        </div>

                        <div className={styles.event__title}>{event.name}</div>
                        <div className={styles.label__container}>
                            <Label>Start: {new Date(event.startDate).toLocaleDateString()} at {new Date(event.startDate).toLocaleTimeString()}</Label>
                            <Label>End: {new Date(event.endDate).toLocaleDateString()} at {new Date(event.endDate).toLocaleTimeString()}</Label>

                            <div className={styles.bottom__container} style={{ marginTop: "0.5rem" }}>
                                <Label>/{ event.slug.length > 25 ? shorten(event.slug, 10) : event.slug }</Label>
                                <div className={styles.remove__container} onClick={async () => {
                                    const notifID = notifications.addNotification({
                                        message: 'Removing event',
                                        status: 'pending',
                                    })

                                    try {
                                        await removeEvent(event.id as string)
                                        
                                        notifications.setNotificationMessage({
                                            id: notifID,
                                            message: 'Event Removed',
                                        })

                                        getUnpublishedEvents(setEvents)

                                        notifications.resolve(notifID)
                                    } catch (error) {
                                        console.log(error)
                                        notifications.reject(notifID)
                                    }
                                }}>
                                    <Label className={styles.remove} right>Remove</Label>
                                </div>

                            </div>
                        </div>
                    </div>
                ))}

                <div className={`${styles.event} ${styles.add__container}`} onClick={() => setOpenAddEvent(true)}>
                    <div className={styles.edit__container} style={{ margin: '0 auto', padding: '2rem 0', width: 'fit-content' }}>
                        <svg className={styles.add__event} xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                            <path d="M22.5 38V25.5H10V22.5H22.5V10H25.5V22.5H38V25.5H25.5V38Z"/>
                        </svg>
                    </div>

                    <div className={styles.event__title}>Add event</div>
                </div>
            </Masonry>

            <Modal
                open={openAddEvent || !!selectedEvent}
                onClose={() => {
                    setCover('')
                    setSlug('')
                    setName('')
                    setTagline('')
                    setCuratorFee(10)
                    setCurator('Default Curator')
                    setCuratorTezosAddress(DEFAULT_CURATOR_TZ_ADDRESS)
                    setCategory('No Category')

                    setStartDate(new Date().toISOString().split('.')[0])
                    setEndDate(new Date().toISOString().split('.')[0])

                    setOpenAddEvent(false)
                    setSelectedEvent(undefined)
                    setPublishEventVAR(false)
                }}
                title={selectedEvent ? 'Edit event' : 'Add event'}
            >
                <Modal.Wrapper key="name">
                    <Label>Event Name</Label>
                    <Input
                        value={name}
                        onChange={(e) => setName(e.target.value)}

                        placeholder="Enter an Event Name"
                    />
                </Modal.Wrapper>

                <Modal.Wrapper key="tagline">
                    <Label>Event Tagline</Label>
                    <Input
                        value={tagline}
                        onChange={(e) => setTagline(e.target.value)}

                        placeholder="Enter an Event Tagline (optional)"
                    />
                </Modal.Wrapper>

                <Modal.Wrapper key="image">
                    <Label>Cover Image</Label>
                    <FileInput
                        onChange={(e) => handleUploadCover(e.target.files)}
                        accept="image/*"
                    />

                    <div className={styles.cover__container}>
                        {cover && (
                            <a href={IPFS_GATEWAY + cover.split('ipfs://')[1]} target="_blank" rel='noreferrer'>
                                <Label className={styles.underline}>View </Label>
                            </a>
                        )}
                        <Label right>.png .jpeg .jpg</Label>
                    </div>
                </Modal.Wrapper>

                <Modal.Spacer key="spacer-0"/>

                <Modal.Wrapper key="start">
                    <Label>Start Time (UTC)</Label>
                    <div className={styles.date__container}>
                        <DateInput
                            value={startDate.split('T')[0]}
                            onChange={(e) => {
                                setStartDate(e.target.value + 'T' + startDate.split('T')[1])
                            }}
                            placeholder="Select Start Date"
                            className={styles.picker__input}
                        />

                        <TimeInput
                            value={startDate.split('T')[1].split('+')[0]}
                            onChange={(e) => {
                                setStartDate(startDate.split('T')[0] + 'T' + e.target.value + ':00')
                            }}
                            className={styles.picker__input}
                        />
                    </div>
                </Modal.Wrapper>

                <Modal.Wrapper key="end">
                    <Label>End Time (UTC)</Label>
                    <div className={styles.date__container}>
                        <DateInput
                            value={endDate.split('T')[0]}
                            onChange={(e) => {
                                setEndDate(e.target.value + 'T' + endDate.split('T')[1])
                            }}
                            placeholder="Select Start Date"
                            className={styles.picker__input}
                        />

                        <TimeInput
                            value={endDate.split('T')[1].split('+')[0]}
                            onChange={(e) => {
                                setEndDate(endDate.split('T')[0] + 'T' + e.target.value + ':00')
                            }}
                            className={styles.picker__input}
                        />
                    </div>
                </Modal.Wrapper>

                <Modal.Spacer key="spacer-1"/>

                <Modal.Wrapper key="curator">
                    <Label>Curator</Label>

                    <DropdownInput
                        // Display corresponding tz address to prevent same name confusion
                        list={(curators.filter((curator: IAccount) => curator.tezos)                            )
                               .map((curator: IAccount) => { return curator.username + ` (${curator.tezos?.slice(0, 5)}...${curator.tezos?.slice(-5)})` || 'Default Curator' })
                              }
                        value={curator}
                        onChange={handleCuratorChange}
                    >
                    </DropdownInput>
                </Modal.Wrapper>

                <Modal.Wrapper key="curatorfee">
                    <Label>Curator Fee (%)</Label>
                    <NumberInput
                        value={curatorFee}
                        onChange={(e) => setCuratorFee(Number(e.target.value))}
                        placeholder="Enter a Curator Fee"
                    />
                </Modal.Wrapper>

                <Modal.Wrapper key="category">
                    <Label>Category</Label>

                    <DropdownInput
                        list={["No Category"].concat(categories.map((category: ICategory) => { return category.name }))}
                        value={category}
                        onChange={(category) => {
                            setCategory(category)
                        }}
                    >
                    </DropdownInput>
                </Modal.Wrapper>

                <Modal.Wrapper key="publish">
                    <div className={styles.publish__wrapper}>
                        <Label>Publish event?</Label>
                        <CheckBoxInput
                            onChange={handleCheckboxChange}
                            checked={publishEventVAR}
                        >
                        </CheckBoxInput>
                    </div>
                </Modal.Wrapper>

                <Modal.Wrapper key="submit">
                    <Button
                        disabled={!ready}
                        onClick={async () => {
                            await handleAddEvent(selectedEvent ? 'edit' : 'add')
                        }}
                    >
                        {ready ? (
                            selectedEvent ? 'Update' : 'Add'
                        ) : (
                            !timeReady ? 'Start time must be smaller than end time' : 'Please fill out all fields'
                        )}
                    </Button>
                </Modal.Wrapper>
            </Modal>
        </Container>
    )
}