/* eslint-disable @typescript-eslint/no-explicit-any */

import { tezosStore } from "../../state/crypto/tezosStore"
import { BeaconWallet } from "@taquito/beacon-wallet"
import { NetworkType } from '@airgap/beacon-sdk'
import { TezosToolkit } from '@taquito/taquito'
import { TEZOS_NETWORK } from "../../constants"

export const checkProvider = async () => {
    const tezosState = tezosStore.getState()

    if (!tezosState.provider || !tezosState.beacon) {
        await initProvider()
    }
}

const initProvider = async () => {
    const tezosState = tezosStore.getState()

    const tezos = new TezosToolkit('https://mainnet.smartpy.io')
    const wallet = new BeaconWallet({
        name: 'Verticalcrypto Art Gallery Platform',
        preferredNetwork: "mainnet" as NetworkType,
    })

    tezos.setWalletProvider(wallet)

    return tezosStore.setState({
        ...tezosState,

        beacon: wallet,
        provider: tezos,
    })
}

const storeAccount = async (signer: any) => {
    const tezosState = tezosStore.getState()

    if (!tezosState.provider) return

    const address = signer.address
    const balance = (await (tezosState.provider).tz.getBalance(address)).toNumber() / 1e6

    tezosStore.setState({
        ...tezosState,

        address,
        balance,

        signer
    })
}

export const connectBeacon = async () => {
    checkProvider()

    const tezosState = tezosStore.getState()
    const beacon = tezosState.beacon

    const network = {
        type: "mainnet" as NetworkType,
        name: "mainnet",
        rpcUrl: 'https://mainnet.smartpy.io', // TODO: Change dynamic
    }

    await beacon.requestPermissions({ network })
    const signer = await beacon.client.getActiveAccount()

    if (!signer) return

    await storeAccount(signer)
}

export const connectBeaconBackground = async () => {
    checkProvider()

    const tezosState = tezosStore.getState()
    const beacon = tezosState.beacon
    const signer = await beacon?.client.getActiveAccount()

    if (!beacon || !signer) return

    storeAccount(signer)
}

export const disconnectBeacon = async () => {
    const tezosState = tezosStore.getState()
    const beacon = tezosState?.beacon

    if (!beacon || !tezosState.provider) return

    await beacon.client.clearActiveAccount()

    tezosState.clear()
}

export const changeBeaconAccount = async () => {
    await disconnectBeacon()
    await connectBeacon()
}