import classNames from 'classnames'

import styles from './styles.module.scss'

export const Card = ({
    children,
    className,
    ...props
}: {
    children: React.ReactNode,
    className?: string,
    [key: string]: any,
}) => {
    const classes = classNames(styles.card, className)

    return (
        <div className={classes} {...props}>
            {children}
        </div>
    )
}
