import create from "zustand"
import { devtools, persist } from "zustand/middleware"

import { ETHEREUM_NETWORK } from "../../constants"
import type { EthereumType, EthereumNetworkEnum } from "../../types/crypto-types"

export const ethereumStore = create<EthereumType>(
    persist((set, get) => ({
        network: ETHEREUM_NETWORK,
        address: "",
        balance: 0,
        
        provider: undefined,
        signer: undefined,

        connected: true,

        setNetwork: (network: EthereumNetworkEnum) => set((state: EthereumType) => ({ ...state, network })),
        setAddress: (address: string) => set((state: EthereumType) => ({ ...state, address })),
        setBalance: (balance: number) => set((state: EthereumType) => ({ ...state, balance })),

        setProvider: (provider: any) => set((state: EthereumType) => ({ ...state, provider })),
        setSigner: (signer: any) => set((state: EthereumType) => ({ ...state, signer })),

        setConnected: (connected: boolean) => set((state: EthereumType) => ({ ...state, connected })),
        
        clear: () => set(() => ({
            network: ETHEREUM_NETWORK,
            address: "",
            balance: 0,
            
            provider: null,
            signer: null,

            connected: true
        })),
    }),
    {
        name: "ethereumStore",
        getStorage: () => localStorage
    })
)

let provider: any = undefined;
let signer: any = undefined;

export const setProvider = (prov: any) => {
    provider = prov
}

export const getProvider = () => {
    return provider
}

export const setSigner = (sign: any) => {
    signer = sign
}

export const getSigner = () => {
    return signer
}