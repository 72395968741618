import { EventAll } from "../../pages/event/all"
import { EventNetwork } from "../../pages/event/network"
import type { IRoutes } from "../../types/routes"
import { Navigate } from "../../utils/redirect/redirect"

export const routes: IRoutes = {
    routerId: 'eventRouter',
    depth: 3,
    pages: [
        {
            path: ":id/all",
            element: EventAll,
            animate: false,
        },
        {
            path: ':id/eth',
            element: EventNetwork,
            animate: false,
        },
        {
            path: ':id/xtz',
            element: EventNetwork,
            animate: false,
        }
    ],
}