import { useEffect, useState, useRef } from 'react'

import { Link } from 'react-router-dom'
import { IPFS_GATEWAY } from '../../constants'
import { Button, ButtonLink, Countdown, Label, LazyLoad } from '../design-system'
import { shorten, getMetadata, cleanHash, getHistory } from '../../utils/crypto'
import styles from './styles.module.scss'
import { Media, MediaTypeEnum } from './media'

import { motion } from 'framer-motion'

export const CleanToken = ({
    network,
    contract,
    id,
    children,
    auction = false,
    to = "",
    ...props
}: {
    network: "ethereum" | "tezos";
    contract: string;
    id: number;
    children?: React.ReactNode;
    auction?: boolean;
    to?: string;
    [key: string]: any;
}) => {
    const [loading, setLoading] = useState(true)
    const [metadata, setMetadata] = useState<any>({})
    const [altMetadata, setAltMetadata] = useState<any>({})

    useEffect(() => {
        const fetchMetadata = async () => {
            const metadata:any = await getMetadata({
                network,
                contract,
                id,
            })

            if(network == 'ethereum' && metadata?.nft?.metadataURI) {
                const data = await fetch(IPFS_GATEWAY + metadata?.nft?.metadataURI)
                const alternateMetadata = await data.json()
                setAltMetadata(alternateMetadata)
            }

            setMetadata(metadata)            
            setLoading(false)
        }

        try {
            fetchMetadata()
        } catch (error) {
            console.error(error)
        }
    }, [])

    if (loading) {
        return (
            <div className={`${styles.token} ${styles.placeholder}`}>
                <div className={styles.edit__container}>
                    {!auction && (
                        <svg onClick={props?.onClick} className={styles.edit__icon} xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                            <path d="M5 19H6.4L16.45 8.975L15.75 8.25L15.025 7.55L5 17.6ZM3 21V16.75L16.45 3.325Q17.025 2.75 17.863 2.75Q18.7 2.75 19.275 3.325L20.675 4.75Q21.25 5.325 21.25 6.15Q21.25 6.975 20.675 7.55L7.25 21ZM19.25 6.15 17.85 4.75ZM16.45 8.975 15.75 8.25 15.025 7.55 16.45 8.975Z"/>
                        </svg>
                    )}

                    <div className={styles.broken}>
                        Metadata Loading
                        <Label center>If this persists, check<br /> token info</Label>
                    </div>
                </div>

                {children}
            </div>
        )
    }

    return (
        <div className={styles.token} {...props} onClick={() => null}>
            <div className={styles.edit__container}>
                {!auction && (
                    <svg onClick={props?.onClick} className={styles.edit__icon} xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                        <path d="M5 19H6.4L16.45 8.975L15.75 8.25L15.025 7.55L5 17.6ZM3 21V16.75L16.45 3.325Q17.025 2.75 17.863 2.75Q18.7 2.75 19.275 3.325L20.675 4.75Q21.25 5.325 21.25 6.15Q21.25 6.975 20.675 7.55L7.25 21ZM19.25 6.15 17.85 4.75ZM16.45 8.975 15.75 8.25 15.025 7.55 16.45 8.975Z"/>
                    </svg>                
                )}

                {metadata && Object.keys(metadata).length !== 0 ? (
                    network === "tezos" ? (
                        to ? (
                            <Link to={to}>
                                <Media
                                    mediaType={metadata?.mime?.split('/')[0]}
                                    cid={cleanHash(metadata?.display_uri) || ''}
                                    placeholderCid={cleanHash(metadata?.display_uri) || ''}
                                    // displayCid={(metadata?.formats[0]?.mimeType?.split('/')[0] === 'audio') ? cleanHash(metadata?.displayUri) : ''}
                                    className={styles.event__cover}
                                    alt="Token Cover"
                                />
                            </Link>
                        ) : (
                            <Media
                                mediaType={(metadata?.formats)? metadata?.mime?.split('/')[0] : "application"}
                                cid={cleanHash(metadata?.display_uri) || ''}
                                placeholderCid={cleanHash(metadata?.display_uri) || ''}
                                // displayCid={(metadata?.formats[0]?.mimeType?.split('/')[0] === 'audio') ? cleanHash(metadata?.displayUri) : ''}
                                className={styles.event__cover}
                                alt="Token Cover"
                            />
                        )
                    ) : (
                        to ? (
                            <Link to={to}>
                                <Media
                                    mediaType={metadata?.metadata?.raw?.contentType?.split('/')[0] || altMetadata?.contentType?.split('/')[0] }
                                    cid={cleanHash(metadata?.metadata?.raw?.contentURI) || cleanHash(altMetadata?.previewURI) || '' }
                                    placeholderCid={cleanHash(metadata?.metadata?.raw?.contentURI) || cleanHash(altMetadata?.contentURI) || ''}
                                    // displayCid={metadata?.metadata?.raw?.contentType?.split('/')[0] === 'audio' ? cleanHash(metadata?.media?.image?.uri) : ''}
                                    className={styles.event__cover}
                                    alt="Token Cover"
                                />
                            </Link>
                        ) : (
                            <Media
                                mediaType={metadata?.metadata?.raw?.contentType?.split('/')[0] || altMetadata?.contentType?.split('/')[0]}
                                cid={cleanHash(metadata?.metadata?.raw?.contentURI) || cleanHash(altMetadata?.previewURI) || ''}
                                placeholderCid={cleanHash(metadata?.metadata?.raw?.contentURI) || cleanHash(altMetadata?.contentURI) || ''}
                                // displayCid={metadata?.metadata?.raw?.contentType?.split('/')[0] === 'audio' ? cleanHash(metadata?.media?.image?.uri) : ''}
                                className={styles.event__cover}
                                alt="Token Cover"
                            />
                        )
                    )
                ) : (
                    <div className={styles.broken}>
                        Error Loading Metadata
                        <Label>Check Token Info</Label>
                    </div>
                )}
            </div>

            <div className={styles.label__container}>
                <Label>{shorten(contract, 4)} | { id > 99999 ? `#${shorten(id.toString(), 3)}` : `#${id}`}</Label>
                <Label>{network}</Label>
            </div>

            <div className={styles.event__title}>                
                {network === "tezos" && (
                    metadata?.creators?.[0] ? shorten(metadata?.creators?.[0].creator_address, 4) : "No Creator Found"
                )}

                {network === "ethereum" && (
                    metadata?.nft?.minted?.address ? shorten(metadata?.nft?.minted?.address, 4) : "No Creator Found"
                )}
            </div>
            
            <Label>
                {network === "tezos" && (
                   <div className={styles.label__truncated}>{metadata?.name || 'No Name Found'}</div> 
                )} 
                
                {network === "ethereum" && (
                    <div className={styles.label__truncated}>{metadata?.metadata?.name || altMetadata?.name || 'No Name Found'}</div> 
                )}
            </Label>
            {children}
        </div>
    )
}