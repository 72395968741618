import { GRAPHCMS_NETWORK as NETWORK } from "../../constants"

const query: any = {}
const mutation: any = {}


query.getBundle = `
    query getBundle($contract: String!, $cryptoNetwork: CryptoNetworks!, $bundleId: Int!) {
        bundles(
            where: { AND: {contract: $contract, cryptoNetwork: $cryptoNetwork, bundleId: $bundleId}}
          ) {
            contract
            id
            bundleId
            name
            description
            saleStart
            coverImage
          }
    }
`

export const bundles = {
    query,
    mutation,
}